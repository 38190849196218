import * as React from "react";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Box, CssBaseline } from "utils/MuiWrapper/components";

import { setMiniSidenav, useFortuneTellerUIContext } from "./context";

import routes, { projectSideNavRoutes } from "./routes";
import { MUIThemeProvider } from "@development-nl/components-library";
import { TopSidenav, ProjectSidenav } from "components/Sidenav";
import { SignInPage } from "components/SignInPage";
import { AlertSnackbar } from "components/AlertSnackbar/AlertSnackbar";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";
import Protected from "components/Protected";

const drawerWidth = 260;

export default function App() {
  const [cookies] = useCookies();
  const [isAuthorized, setAuthorized] = useState(!!cookies.token);
  const [context, dispatch] = useFortuneTellerUIContext();
  const { miniSidenav, menuPinned } = context;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      !menuPinned && setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        const element = route.roles ? <Protected conditional={user && route.roles.includes(user._embedded.role.name)}>{route.component}</Protected> : route.component;
        return <Route path={route.route} element={element} key={route.key} />;
      }

      return null;
    });

  if (!isAuthorized) {
    return (
      <MUIThemeProvider>
        <>
          <CssBaseline />
          <SignInPage authorize={() => setAuthorized(true)} />
          <AlertSnackbar />
        </>
      </MUIThemeProvider>
    );
  }

  return (
    <MUIThemeProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Routes>
          {projectSideNavRoutes.map((route) => (
            <Route
              path={route.route}
              key={route.key}
              element={
                <ProjectSidenav
                  width={drawerWidth}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              }
            />
          ))}

          <Route
            path="*"
            element={
              <TopSidenav width={drawerWidth} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
            }
          />
        </Routes>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <AlertSnackbar />
        </Box>
      </Box>
    </MUIThemeProvider>
  );
}
