import { useEffect } from "react";
import { Box, Stack } from "utils/MuiWrapper/components";
import DetailPane from "./DetailPane";
import { useDetailPaneStateStore } from "./DetailPaneState";
import TemplateProjectTabView from "./TemplateProjectTabView";
import { CARD_BG_COLOR } from "utils/constants";

const HomePage = () => {
  const updateDetailId = useDetailPaneStateStore((state) => state.updateId);

  useEffect(() => {
    updateDetailId("");
  }, []);

  return (
    <>
      <h1 style={{ marginLeft: "2%" }}>Library</h1>
      <Box borderRadius={3} mx={2} mt={2} sx={{ width: "98%", bgcolor: CARD_BG_COLOR, padding: "10px" }}>
        <Stack direction="row">
          <Box sx={{ width: "50%" }}>
            <TemplateProjectTabView />
          </Box>
          <Box sx={{ width: "2px", bgcolor: "#13172c;" }}></Box>
          <Box mt={10} sx={{ width: "50%" }}>
            <DetailPane />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default HomePage;
