import { BOM_ITEM_STATUS_OPTIONS } from "utils/constants";
import { StandardDate } from "utils/dates";
import { DateEditor } from "./DateEditor";
import { Checkbox } from "utils/MuiWrapper/components";

const Percentage = (colAPI) => {
  if (colAPI.value === undefined) return "";
  return `${colAPI.value}%`;
};

const NumberFormat = (colAPI) => {
  if (colAPI.value === undefined) return "";
  return colAPI.value.toLocaleString();
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const PriceRenderer = (colAPI) => {
  if (colAPI.value === undefined) return "";
  return formatter.format(colAPI.value);
};

export const StatusColDef = (isBOMEditable) => {
  return [
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      editable: isBOMEditable,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: BOM_ITEM_STATUS_OPTIONS,
      },
    },
  ];
};

export const InitialColumnDefs = (isBOMEditable) => {
  return [
    // Keeping this around for testing
    // {
    //   headerName: "",
    //   minWidth: 55,
    //   valueGetter: "node.id",
    //   cellRenderer: (props) => {
    //     if (props?.value?.includes("t")) return "";
    //     if (props.value !== undefined) {
    //       return parseInt(props.value) + 1;
    //     } else {
    //       return "";
    //     }
    //   },
    // }
    {
      headerName: "Quantity",
      field: "qty",
      cellRenderer: NumberFormat,
      editable: isBOMEditable,
    },
    { field: "supplier", headerName: "Supplier" },
    { field: "supplier_part_number", headerName: "Supplier Part No" },
    { field: "manufacturer", headerName: "Manufacturer" },
    { field: "manufacturer_part_number", headerName: "Manufacturer Part No" },
    { field: "description", headerName: "Description" },
    { field: "price", headerName: "Price", cellRenderer: PriceRenderer },
    { field: "discount", headerName: "Discount", cellRenderer: Percentage },
    { field: "net_cost", headerName: "Net Cost", cellRenderer: PriceRenderer },
    {
      field: "total_cost",
      headerName: "Total Cost",
      cellRenderer: PriceRenderer,
    },
    {
      field: "total_monthly",
      headerName: "Total Monthly",
      cellRenderer: PriceRenderer,
    },
  ];
};

export const EditEndColDefs = (isBOMEditable: boolean) => {
  return [
    {
      headerName: "Quote Ref",
      field: "quote_ref",
      editable: isBOMEditable,
    },
    {
      headerName: "Date Required",
      field: "date_required",
      editable: isBOMEditable,
      cellEditor: DateEditor,
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
    {
      headerName: "RFP Number",
      field: "rfp_number",
      editable: isBOMEditable,
    },
    {
      headerName: "PO Number",
      field: "po_number",
      editable: isBOMEditable,
    },
    {
      headerName: "PO Date",
      field: "po_date",
      cellEditor: DateEditor,
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
    {
      headerName: "Location",
      field: "location",
      editable: isBOMEditable,
    },
    {
      headerName: "File Upload",
      field: "file_upload_location",
      editable: isBOMEditable,
    },
  ];
};

export const CheckboxHeader = ({ onSelectAll, isSelectAllChecked }) => {
  return <Checkbox checked={isSelectAllChecked} onChange={onSelectAll} sx={{ marginLeft: -2.6 }} />;
};
