export const enum Action {
  View = 1,
  Create = 2,
  Edit = 4,
  Delete = 8,
}

export const enum Roles {
  Admin = "admin",
  Architect = "architect",
  Manager = "manager",
  Purchaser = "purchaser",
  User = "user",
  Reader = "reader",
}