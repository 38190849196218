import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { Typography } from "utils/MuiWrapper/components";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "components/Receiving/ReceivingBOMGrid.css";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { CheckboxHeader, InitialColumnDefs } from "./ColumnDefinitions";
import { useReceivingPageState } from ".";

export const ReceivingBOMGrid = ({ gridRef, dataSource, totalResults }) => {
  const {
    searchString: poNumber,
    setSelectedRowIDs,
    isSelectAllChecked,
    setIsSelectAllChecked,
    setSelectedRowsData,
  } = useReceivingPageState((state) => state);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "70vh" }), []);

  useEffect(() => {
    if (isSelectAllChecked) setIsSelectAllChecked(false);
  }, []);

  useEffect(() => {
    gridRef?.current?.api?.setDatasource(dataSource);
  }, [poNumber]);

  const onSelectAll = (event) => {
    setIsSelectAllChecked(event.target.checked);

    gridRef.current.api.forEachNode(function (node) {
      // Setting 3rd arg to true so that UI won't lock up when many rows displayed
      node.setSelected(event.target.checked, false, true);
    });
    // Triggering selection changed after looping through each node so UI won't lock up
    handleSelectionChanged(gridRef.current);
  };

  const getCheckBoxColumn = () => {
    return {
      checkboxSelection: true,
      minWidth: 55,
      headerComponent: CheckboxHeader,
      headerComponentParams: {
        onSelectAll,
        isSelectAllChecked,
      },
    };
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const onGridReady = useCallback(
    (params) => {
      params.api.setDatasource(dataSource);
    },
    [poNumber, totalResults]
  );

  const handleSelectionChanged = (gridAPI) => {
    const selectedIDs = gridAPI?.api?.getSelectedRows().map((row) => row.id);
    setSelectedRowIDs(selectedIDs);

    const selectedRows = gridAPI?.api?.getSelectedRows().map((row) => row);
    setSelectedRowsData(selectedRows);
  };

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine-dark ag-theme-custom">
        <AgGridReact
          ref={gridRef}
          columnDefs={[getCheckBoxColumn(), ...InitialColumnDefs()]}
          defaultColDef={defaultColDef}
          onSelectionChanged={handleSelectionChanged}
          rowBuffer={0}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          rowModelType={"infinite"}
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={totalResults}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
        ></AgGridReact>
        <Typography variant="body2" align="right">
          Total results: {totalResults}
        </Typography>
      </div>
    </div>
  );
};
