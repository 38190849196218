import { Checkbox } from "utils/MuiWrapper/components";
import { StandardDate } from "utils/dates";

const NumberFormat = (colAPI) => {
  if (colAPI.value === undefined) return "";
  return colAPI.value.toLocaleString();
};

export const InitialColumnDefs = () => {
  return [
    // Keeping this around for testing
    // {
    //   headerName: "",
    //   minWidth: 55,
    //   valueGetter: "node.id",
    //   cellRenderer: (props) => {
    //     if (props?.value?.includes("t")) return "";
    //     if (props.value !== undefined) {
    //       return parseInt(props.value) + 1;
    //     } else {
    //       return "";
    //     }
    //   },
    // }
    {
      field: "received",
      headerName: "Received",
      valueFormatter: params => params.data?.received !== undefined && params.data.received !== null && params.data.received !== "" ? params.data.received : "0",
      editable: true
    },
    { field: "remaining", headerName: "Remaining", cellRenderer: NumberFormat },
    { field: "qty", headerName: "PO QTY", cellRenderer: NumberFormat },
    { field: "qty_rx", headerName: "QTY Received", cellRenderer: NumberFormat },
    { field: "project_id", headerName: "Project", cellRenderer: NumberFormat },
    { field: "supplier", headerName: "Supplier" },
    { field: "manufacturer", headerName: "Manufacturer" },
    {
      field: "manufacturer_part_number",
      headerName: "Manufacturer Part Number",
    },
    { field: "description", headerName: "Description" },
    {
      headerName: "Last Received",
      field: "last_received",
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
    {
      field: "user",
      headerName: "User",
    },
    {
      field: "rfp_number",
      headerName: "RFP Number",
      cellRenderer: NumberFormat,
    },
    {
      field: "comment",
      headerName: "Comment",
    },
  ];
};

export const CheckboxHeader = ({ onSelectAll, isSelectAllChecked }) => {
  return <Checkbox checked={isSelectAllChecked} onChange={onSelectAll} sx={{ marginLeft: -2.6 }} />;
};
