import { getRequest, createRequest, updateRequest, deleteRequest } from "http/axiosClient";
import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";
import { Rule } from "types/Rule";

// Fetch rules by template ID
export type TemplateRuleFetchParams = Record<string, string>;

// TODO: increasing limit to 10,000 here is a temporary fix.
// Long term solution to be implemented as a part of FORT-640.
export async function fetchRulesByTemplateId(templateId: string, params: TemplateRuleFetchParams = {}) {
  try {
    const res = await getRequest(`/v1/api/rules/template/${templateId}?limit=10000`, params);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

async function sortRulesByTemplateId(templateId, params) {
  const data: any = await fetchRulesByTemplateId(templateId, params);
  return data?.results?.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0));
}

export const useFetchRulesByTemplateId = (templateId, params) => {
  return useQuery({
    queryKey: ["template-rules", templateId, params.value],
    queryFn: () => sortRulesByTemplateId(templateId, params),
  });
};

// Fetch rule by rule ID
export async function fetchRuleById(ruleId: string) {
  try {
    const res = await getRequest(`/v1/api/rules/${ruleId}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Create rule
export type TemplateRuleData = Record<string, string>;
export async function createRule(templateId: string, data: any) {
  try {
    const res = await createRequest(`/v1/api/rules/template/${templateId}`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Create rule conditions
export type TemplateRuleConditionData = Record<string, string>;
export async function createRuleConditions(ruleId: string, data: any) {
  try {
    const res = await createRequest(`/v1/api/rules/${ruleId}/conditions`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Create rule action
export type TemplateRuleActionData = {
  ordinal?: number;
  value?: string;
  component_id?: string;
  variable_id?: any;
};
export async function createRuleAction(ruleId: string, data: TemplateRuleActionData) {
  try {
    const res = await createRequest(`/v1/api/rules/${ruleId}/actions`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Update rule action by action ID
export async function updateRuleAction(ruleId: string, actionId: string, data: TemplateRuleActionData) {
  try {
    const res = await updateRequest(`/v1/api/rules/${ruleId}/actions/${actionId}`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Updates a rule action.
 */
export const useUpdateRuleAction = (
  args: UseMutationOptions<unknown, Error, { ruleId: string; actionId: string; data: TemplateRuleActionData }>
) => {
  return useMutation({
    mutationFn: ({ ruleId, actionId, data }) => updateRuleAction(ruleId, actionId, data),
    ...args,
  });
};

// Update rule by rule ID
export async function updateRuleById(ruleId: string, data: Partial<Rule>) {
  try {
    const res = await updateRequest(`/v1/api/rules/${ruleId}`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Update rule condition by rule ID, conditions ID and rule condition ID
export async function updateRuleConditionById(ruleId, conditionsId, ruleConditionId, data) {
  try {
    const res = await updateRequest(
      `/v1/api/rules/${ruleId}/conditions/${conditionsId}/condition/${ruleConditionId}`,
      data
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Delete rule by ID
export async function deleteRuleById(ruleId: string) {
  try {
    await deleteRequest(`/v1/api/rules/${ruleId}`);
  } catch (error) {
    console.log(error);
  }
}

// Delete rule action by rule action ID
export async function deleteRuleActionById(ruleId: string, ruleActionId: string) {
  try {
    await deleteRequest(`/v1/api/rules/${ruleId}/actions/${ruleActionId}`);
  } catch (error) {
    console.log(error);
  }
}

// Delete rule conditions by rule conditions ID
export async function deleteRuleConditionsById(ruleId: string, ruleConditionsId: string) {
  try {
    await deleteRequest(`/v1/api/rules/${ruleId}/conditions/${ruleConditionsId}`);
  } catch (error) {
    console.log(error);
  }
}
