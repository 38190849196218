import { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, Autocomplete, TextField, Button, Stack } from "utils/MuiWrapper/components";
import { fetchPONumbers } from "api/bom";
import { useDebounce } from "utils/helpers";
import { useReceivingPageState } from ".";

export default function TopCard({ handleLocationSave }) {
  const { searchString, setSearchString } = useReceivingPageState();

  const [location, setLocation] = useState<string | undefined>();

  // search value
  const [searchValue, setSearchValue] = useState<string>("");

  // search results
  const [options, setOptions] = useState<string[]>([]);

  // searching status
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(
    () => {
      // endpoint expects at least 3 characters for search criteria
      if(debouncedSearchValue.length >= 3) {
        setIsSearching(true);
        fetchPONumbers(debouncedSearchValue).then((data) => {
          setOptions(data);
          setIsSearching(false);
        }, () => {
          setOptions([]);
          setIsSearching(false);
        });
      } else {
        setOptions([]);
        setIsSearching(false);
      }
    },
    [debouncedSearchValue]
  );

  const handleSelectedNumber = (_, value: string | null) => {
    if(!value) return;
  
    setSearchString(value);
  }

  const updateSearchValue = (_, value: string) => {
    setSearchValue(value); 
  };

  const handleLocationChange = (e) => setLocation(e.target.value);

  const onLocationClick = () => {
    handleLocationSave(location);
    setLocation("");
  }

  return (
    <Card style={{ margin: "1% 0 1% 0" }}>
      <CardContent>
        <Grid container>
          <Grid item md={6}>
            <Stack direction="row" spacing={3} alignItems="center">
              <Typography variant="body1">Filter by PO Number:</Typography>
              <Autocomplete
                options={options}
                noOptionsText="No results match"
                filterOptions={(x) => x}
                loading={isSearching}
                value={searchString}
                onChange={handleSelectedNumber}
                inputValue={searchValue}
                onInputChange={updateSearchValue}
                renderInput={(params) => <TextField {...params} sx={{ width: 300 }} />}
                sx={{ input: { height: 8 } }}
              />
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-end">
              <Typography variant="body1">Location:</Typography>
              <TextField sx={{ width: 300 }} name="location" value={location} onChange={handleLocationChange} />
              <Button onClick={onLocationClick}>
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
