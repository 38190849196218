import React from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Link, Typography } from "utils/MuiWrapper/components";
import { Link as RouterLink } from "react-router-dom";
import { ProjectInfo } from "./ProjectInfo";
import { Templates } from "./Templates";
import { ProjectBOMs } from "./ProjectBOMs";
import { ApprovedProjectModal } from "./ApprovedProjectModal";
import { useProject } from "api/project";
import { APPROVED_PROJECT, CARD_BG_COLOR } from "utils/constants";
import { TeamMember } from "types/Project";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { Action, Roles } from "utils/enums";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";

const FillerBox = ({ text }) => {
  return (
    <Box
      p={4}
      bgcolor={CARD_BG_COLOR}
      borderRadius={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="20vh"
      fontSize={20}
    >
      {text}
    </Box>
  );
};

export const ProjectDetailViewPage = () => {
  const userId = useAuthStore((state) => state.userId);
  const { projectId } = useParams();
  const { data: project } = useProject(projectId);
  const { data: user } = useGetUser(userId);
  const { isProjectManager, isProjectMember } = useProjectTeamManagement(project, userId);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={2} mr={1}>
        <Typography variant="h1">{project?.name}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
            minHeight: project?.status === APPROVED_PROJECT ? "7vh" : "none",
          }}
        >
          {(isProjectManager || isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.scaler, Action.View) && (
            <Link
              to={`/projects/${projectId}/template-scaler`}
              component={RouterLink}
              variant="h3"
              sx={{ marginRight: 5 }}
            >
              Template Scaler
            </Link>
          )}
          {(isProjectManager || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.project, Action.Edit) && (
            <ApprovedProjectModal projectId={projectId} />
          )}
        </Box>
      </Grid>
      <Grid container spacing={4}>
        <Grid container item lg={7} md={12} spacing={4}>
          <Grid item xs={12} md={12}>
            <ProjectInfo />
          </Grid>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={6} md={6}>
              <FillerBox text="Stats" />
            </Grid>
            <Grid item xs={6} md={6}>
              <FillerBox text="Total Budget" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {project?.status !== APPROVED_PROJECT && <Templates projectId={projectId} />}
          </Grid>
        </Grid>
        <Grid item lg={5} xs={12}>
          <ProjectBOMs />
        </Grid>
      </Grid>
    </>
  );
};
