import { Select, styled } from "utils/MuiWrapper/components";
import { ACTIVE_BORDER, TEXTFIELD_BORDER_RADIUS, WARNING_COLOR } from "utils/constants";

export const CustomSelect = styled(Select)(() => ({
  ".MuiSelect-select.MuiSelect-outlined": {
    padding: "5px 0 0 5px",
    fontSize: "15px",
    backgroundColor: "rgba(0, 0, 0, 0.06)",
  },
}));

export const getFormInputStyles = (columnId: string, field, value: string | undefined, errorId: string) => {
  if (!value || field.id === errorId) {
    return { border: `1px solid ${WARNING_COLOR}`, borderRadius: TEXTFIELD_BORDER_RADIUS };
  } else if (columnId === field.id) {
    return {
      border: field.id !== errorId ? `2px solid ${ACTIVE_BORDER}` : "inherit",
      borderRadius: TEXTFIELD_BORDER_RADIUS,
    };
  } else {
    return {};
  }
};
