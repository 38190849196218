import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../http/componentClient";
import { QueryParamsType } from "types/Queries";

export async function getComponents(params?: QueryParamsType) {
  try {
    const res = await getRequest("/v1/api/components", params);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error(`Could not fetch components.`));
  }
}

export const useGetComponents = (params?: QueryParamsType) => {
  return useQuery({
    queryKey: ["get_components", params],
    queryFn: () => getComponents(params),
  });
};

export async function fetchComponentAttributes() {
  try {
    const res = await getRequest(`/v1/api/components/attributes`);
    console.debug("Get Component Attributes", res);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
