import axios, { AxiosRequestConfig } from "axios";
import { handleTokenError } from "./axiosClient";

export const getBearerToken = () => {
  const cookie = document.cookie;
  let token = "Bearer ";

  if (!cookie) return;

  if (cookie.includes("; ")) {
    token += cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];
  } else {
    token += cookie.split("=")[1];
  }
  return token;
};

// Set config defaults when creating the instance
const templateSvcClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 2000,
});

// Alter defaults after instance has been created
templateSvcClient.interceptors.request.use(function (config: AxiosRequestConfig) {
  if (config.headers) config.headers.Authorization = getBearerToken() || "";
  return config;
});

templateSvcClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return handleTokenError(error);
  }
);

const rulesProcessorClient = axios.create({
  baseURL: process.env.REACT_APP_RULES_PROCESSOR_ENDPOINT,
  timeout: 2000,
});

rulesProcessorClient.interceptors.request.use(function (config) {
  if (config.headers) config.headers.Authorization = getBearerToken() || "";
  return config;
});

rulesProcessorClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return handleTokenError(error);
  }
);

export function generateBOM(payload) {
  return rulesProcessorClient.post("/v1/api/processor", payload);
}

export function getBaseUrl() {
  return process.env.REACT_APP_API_ENDPOINT;
}

export function getRequest(url) {
  return templateSvcClient.get(`${url}`);
}

export function postRequest(url, payload) {
  return templateSvcClient.post(`${url}`, payload);
}

export function patchRequest(url, payload) {
  return templateSvcClient.patch(`${url}`, payload);
}

export function deleteRequest(url) {
  return templateSvcClient.delete(`${url}`);
}
