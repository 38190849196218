import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../shared/SearchBar";
import ProjectsTable from "./ProjectsTable";
import { createProject, favoriteProjectById, unfavoriteProjectById, useProjects } from "api/project";
import { Close } from "utils/MuiWrapper/icons";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from "utils/MuiWrapper/components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { QueryParamsType } from "types/Queries";

export default function ProjectsPage() {
  const queryClient = useQueryClient();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [params, setParams] = useState<QueryParamsType>({
    order_by: "updated_at",
    order_direction: "desc",
    limit: 10,
    offset: 0,
  });
  const [projectName, setProjectName] = useState("");
  const [showAddProjectDetailsModal, setShowAddProjectDetailsModal] = useState(false);
  const navigate = useNavigate();

  const { data: projects } = useProjects(params);

  const handleShow = () => {
    setShowAddProjectDetailsModal(true);
  };

  const handleClose = () => {
    setShowAddProjectDetailsModal(false);
  };

  const refreshTable = () => {
    queryClient.invalidateQueries(["projects", params]);
  };

  const searchProjects = (searchString) => {
    const newParams = {
      order_by: "updated_at",
      order_direction: "desc",
      limit: 10,
      offset: 0,
    };
    if (searchString === "") {
      setParams(newParams);
    } else {
      setParams({ ...newParams, quick_search: searchString });
    }
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleCreateProject = async () => {
    if (projectName.trim() === "") {
      setAlert({ type: "warning", message: "Please enter a Project Name" });
    }
    const data = { name: projectName };
    const project = await createProject(data);

    if (project) {
      queryClient.invalidateQueries(["projects", params]);

      handleClose();
      navigate(`/projects/${project.id}`);
    }
  };

  const handleFavorites = (project) => {
    project.is_favorited ? unfavoriteProject(project.id) : favoriteProject(project.id);
  };

  const { mutate: favoriteProject } = useMutation({
    mutationFn: (projectId) => favoriteProjectById(projectId),
    onSuccess: () => {
      queryClient.invalidateQueries(["projects", params]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  const { mutate: unfavoriteProject } = useMutation({
    mutationFn: (projectId) => unfavoriteProjectById(projectId),
    onSuccess: () => {
      queryClient.invalidateQueries(["projects", params]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setParams({ ...params, limit: value });
  };

  return (
    <>
      <SearchBar delegateSearch={searchProjects} handleShow={handleShow} typography={"Projects"} />
      <Dialog open={showAddProjectDetailsModal} onClose={handleClose}>
        <DialogContent dividers>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogTitle align="center" sx={{ pt: 0 }}>
            Add Project Details
          </DialogTitle>
        </DialogContent>
        <DialogContent>
          <Box>
            <Box>
              <TextField
                autoFocus
                fullWidth
                label="Project Name"
                autoComplete="off"
                onChange={handleProjectNameChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>

          <Button sx={{ ml: "3%" }} color="primary" onClick={handleCreateProject}>
            Create Project
          </Button>
        </DialogActions>
      </Dialog>
      {projects && (
        <ProjectsTable
          projects={projects.results}
          orderBy="last_modified"
          limit={params.limit}
          offset={params.offset}
          params={params}
          setParams={setParams}
          totalResults={projects.total_results}
          rowsPerPageOptions={[10, 50, 100]}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFavorites={handleFavorites}
          refreshTable={refreshTable}
        />
      )}
    </>
  );
}
