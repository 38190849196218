import React, { useMemo } from "react";
import { Paper, Table, TableBody, TablePagination, Box } from "utils/MuiWrapper/components";
import { useDetailPaneStateStore } from "../Library/DetailPaneState";
import { Star, StarBorder } from "utils/MuiWrapper/icons";

import { yellow } from "@mui/material/colors";
import { EnhancedTableHead, getComparator, StyledTableCell, StyledTableRow } from "./tableDependencies";
import { IconButton } from "@mui/material";
import { formatDistance } from "date-fns";
import { ProjectMenuOptions } from "components/ProjectsPage/ProjectMenuOptions";
import { CARD_BG_COLOR, ORDER } from "utils/constants";

export default function ProjectsTable(props) {
  const {
    projects,
    orderBy,
    limit,
    offset,
    params,
    setParams,
    totalResults,
    rowsPerPageOptions,
    handleFavorites,
    refreshTable,
    handleChangeRowsPerPage,
  } = props;

  const updateDetailType = useDetailPaneStateStore((state) => state.updateType);
  const updateDetailId = useDetailPaneStateStore((state) => state.updateId);

  const sortedProjects = useMemo(() => {
    return projects?.slice()?.sort(getComparator(ORDER, orderBy));
  }, [projects, ORDER, orderBy]);

  const handleChangePage = (event, newPage) => {
    setParams({ ...params, offset: newPage * params.limit });
    updateDetailId("");
    updateDetailType("Project");
  };

  const handleClick = (projectId) => {
    updateDetailId(projectId);
    updateDetailType("Project");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mb: "25px" }}>
      <Box sx={{ maxHeight: "74vh", overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={ORDER} orderBy={orderBy} />
          <TableBody data-testid="projects-table">
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.sort(getComparator(order, orderBy)).slice() */}
            {sortedProjects &&
              sortedProjects.map((project, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    sx={{ cursor: "pointer" }}
                    hover
                    role="checkbox"
                    aria-checked={project.is_favorited}
                    tabIndex={-1}
                    key={project.id}
                    selected={project.is_favorited}
                    onClick={() => handleClick(project.id)}
                  >
                    <StyledTableCell>
                      <IconButton onClick={() => handleFavorites(project)}>
                        {project.is_favorited ? <Star sx={{ color: yellow[700] }} /> : <StarBorder />}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                      {project.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatDistance(Date.parse(project.last_modified), new Date(), { addSuffix: true })}
                    </StyledTableCell>
                    <StyledTableCell id={labelId} align={"center"}>
                      <ProjectMenuOptions project={project} refreshTable={refreshTable} />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        sx={{ backgroundColor: CARD_BG_COLOR }}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalResults}
        rowsPerPage={limit}
        page={offset / limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
