export type ApiError = {
  response: {
    data: {
      detail: string;
    };
  };
};

export const isApiError = (error: unknown): error is ApiError => {
  return typeof error === "object" && !!error && "response" in error;
};
