import { Add as AddIcon } from "utils/MuiWrapper/icons";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "utils/MuiWrapper/components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FieldCollection from "./FieldCollection";
import GlobalVariables from "./GlobalVariables";
import { getMaxOrdinal } from "utils/Ordinalutil";
import {
  fetchFieldCollections,
  fetchTemplate,
  createFieldCollection,
  updateTemplate,
  publishTemplate,
  testTemplate,
} from "api/template";
import {
  fetchTemplateVariables,
  createTemplateVariable,
  createGlobalTemplateVariable,
  updateTemplateVariableById,
  deleteTemplateVariableById,
  fetchSelectedTemplateVarsById,
  deleteGlobalVariableById,
} from "api/template";

import { ConfirmDialog } from "../shared/dialogs";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { DATACENTER_TEMPLATE, PUBLISHED_TEMPLATE, WARNING_COLOR } from "utils/constants";
import { TabPanel, a11yProps } from "components/shared/TabPanel";
import IterationTwo from "./Iterations/IterationTwo";
import IterationThree from "./Iterations/IterationThree";
import { DataCenterConfirm } from "./DataCenter/DataCenterConfirm";
import { Template, TemplateBuilderTemplate } from "types/Template";
import { DataCenterVariables } from "./DataCenter/DataCenterVariables";
import { DataCenterSection } from "./DataCenter/DataCenterSection";
import ConfirmationDialog from "components/shared/ConfirmationDialog";

const TemplateBuilder = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [isDatacenterConfirmOpen, setIsDatacenterConfirmOpen] = useState(false);
  const queryClient = useQueryClient();
  const [fieldCollections, setFieldCollections] = useState<any>([]);
  const { templateId } = useParams();
  const [template, setTemplate] = useState<TemplateBuilderTemplate>({
    status: "",
    name: "",
    id: "",
    type: "",
    created_at: "",
    updated_at: "",
    description: "",
  });
  const [maxOrdinal, setMaxOrdinal] = useState(0);
  const [variables, setVariables] = useState<any>([]);
  const [templateVariables, setTemplateVariables] = useState<any>([]);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const [selectedGlobalVariable, setSelectedGlobalVariable] = useState(undefined);
  const [showConfirmDeleteGlobalVariableDialog, setShowConfirmDeleteGlobalVariableDialog] = React.useState(false);

  const [isPublishTemplateModalOpen, setIsPublishTemplateModalOpen] = React.useState(false);

  useEffect(() => {
    if (template?.description?.length >= 256) {
      setAlert({ type: "warning", message: "Please enter a description that is less than 256 characters." });
    }
  }, [template]);

  const getVariables = async () => {
    const data = await fetchTemplateVariables();
    if (data) {
      setVariables(data.results);
    }
  };

  const getTemplateVariables = async (templateId) => {
    const data = await fetchSelectedTemplateVarsById(templateId, { type: "standard" });
    if (data) {
      setTemplateVariables(data.results);
    }
  };

  const templateIsPublishedMessage = "Template is published. Changes cannot be made.";

  useEffect(() => {
    getFieldCollections(templateId);
    getTemplate(templateId);
    getVariables();
    getTemplateVariables(templateId);
  }, []);

  const handleFieldChange = (value: string) => {
    setTemplate({ ...template, name: value });
  };

  const getFieldCollections = async (templateId) => {
    const data = await fetchFieldCollections(templateId);
    const filteredCollections = data.results.filter((collection) => {
      return collection.type === "collection";
    });
    setFieldCollections(filteredCollections);
    const maxOrdinal = getMaxOrdinal(filteredCollections);
    setMaxOrdinal(maxOrdinal);
  };

  const getTemplate = async (templateId?: string) => {
    const template = await fetchTemplate(templateId);
    setTemplate(template);
    if (template.type === DATACENTER_TEMPLATE) setCurrentTab(0);
  };

  const { mutate: publishTemplateMutation } = useMutation({
    mutationFn: (id: string) => publishTemplate(id),
    onSuccess: () => {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        status: "published",
      }));
    },
    onError: (error: JSX.Element) => {
      setAlert({
        type: "error",
        message: error,
      });
    },
  });

  const handlePublishTemplate = () => {
    publishTemplateMutation(template.id);
    setIsPublishTemplateModalOpen(false);
  };
  
  const handleConfirmPublishTemplate = () => {
    setIsPublishTemplateModalOpen(true);
  }

  const _createFieldCollection = async () => {
    const data = {
      name: "New Field Collection",
      ordinal: maxOrdinal + 1,
      clonable: false,
      multipliable: false,
      type: "collection",
    };
    const fieldCollection = await createFieldCollection(templateId, data);
    setFieldCollections([...fieldCollections, fieldCollection]);
    setMaxOrdinal((prev) => prev + 1);
    return fieldCollection;
  };

  const { mutate: _updateTemplate } = useMutation({
    mutationFn: (data: Template) => updateTemplate(templateId || "", data),
    onSuccess: (data) => {
      setTemplate(data);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  const createGlobalVariable = async (name) => {
    const data = await createGlobalTemplateVariable(name);
    if (data) {
      const newList = variables;
      newList.push(data);
      setVariables([...newList]);
      queryClient.invalidateQueries(["all-template-variables"]);
    } else {
      setAlert({
        type: "error",
        message: "Failed to create new template variable.",
      });
    }
  };

  const assignTemplateVariable = async (templateId, variableId: string) => {
    const data = await createTemplateVariable(templateId, { variable_id: variableId, type: "standard" });
    if (data) {
      const newList = templateVariables;
      newList.push(data);
      setTemplateVariables([...newList]);
      queryClient.invalidateQueries(["selected-template-variables", templateId]);
    } else {
      setAlert({
        type: "error",
        message: "Could not assign template variable to template.",
      });
    }
  };

  const { mutate: unassignTemplateVariable } = useMutation({
    mutationFn: async (variableId: string) => await deleteTemplateVariableById(templateId ?? "", variableId),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
    onSuccess: (data, variableId) => {
      const newList = templateVariables.filter((item: any) => item.id !== variableId);
      setTemplateVariables([...newList]);
      queryClient.invalidateQueries(["selected-template-variables", templateId]);
    },
  });

  // Update specific globalVar in variables state.
  const _updateVariableInVariablesArray = (id, updatedObject) => {
    setVariables(variables.map((x) => (x.id === id ? updatedObject : x)));
  };

  const updateGlobalVariable = async (id, name) => {
    // Updates data on the back-end and front-end
    const res = await updateTemplateVariableById(id, name);
    if (res) {
      _updateVariableInVariablesArray(id, res);
      queryClient.invalidateQueries(["all-template-variables"]);
      return res;
    } else {
      setAlert({
        type: "error",
        message:
          "Could not update template variable because it appears to be in use by another template or the name is already taken.",
      });
    }
  };

  const getSelectedTemplateVariableName = (variableId) => {
    const results = variables.filter((v) => v.id === variableId);
    if (results.length) {
      return results[0].name;
    }
  };

  const _deleteGlobalVariable = async (variable) => {
    try {
      const res: any = await deleteGlobalVariableById(variable.id);
      if (res?.response?.status >= 400) {
        setAlert({
          type: "error",
          message: res?.response?.data?.message || "Cannot delete template variable.",
        });
      } else {
        const newList = variables.filter((v: any) => v.id !== variable.id);
        setVariables([...newList]);
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Template variable is currently in use by another template.",
      });
    }
  };

  const deleteGlobalVariable = (variable) => {
    setSelectedGlobalVariable(variable);
    setShowConfirmDeleteGlobalVariableDialog(true);
  };

  const handleConfirmDeleteGlobalVariableDialogClose = () => setShowConfirmDeleteGlobalVariableDialog(false);

  const handleConfirmDeleteGlobalVariable = async () => {
    await _deleteGlobalVariable(selectedGlobalVariable);
    setShowConfirmDeleteGlobalVariableDialog(false);
    setSelectedGlobalVariable(undefined);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleDatacenterClick = () => {
    if (template?.type !== DATACENTER_TEMPLATE) setIsDatacenterConfirmOpen(true);
  };

  const handleTestTemplate = () => {
    testTemplate(template.id).then(
      (retVal) => {
        window.open(
          `/user-view/project/${retVal.project_id}/template/${retVal.template_id}/project-template/${retVal.project_template_id}`,
          "_blank",
          "noreferrer"
        );
      },
      (error: JSX.Element) => {
        console.log(error);
        setAlert({
          type: "error",
          message: error,
        });
      }
    );
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} width="100%" display="flex" justifyContent="center" alignItems="center">
        <Box
          width="98%"
          style={{
            marginBottom: "auto",
          }}
        >
          <Box p={2} display="flex" alignItems="center">
            <Box marginRight="1%">Template Name</Box>
            <Tooltip title={template.status === "published" ? templateIsPublishedMessage : null}>
              <TextField
                id="filled-basic"
                hiddenLabel
                size="small"
                autoComplete="off"
                disabled={template.status === "published"}
                value={template?.name}
                onChange={(e) => {
                  handleFieldChange(e.target.value);
                }}
                onBlur={(e) => {
                  _updateTemplate({ name: e.target.value });
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            </Tooltip>
            <Box marginRight="1%" marginLeft={4}>
              Description
            </Box>
            <Tooltip title={template.status === "published" ? templateIsPublishedMessage : null}>
              <TextField
                disabled={template.status === "published"}
                value={template?.description}
                sx={{ width: 200 }}
                onChange={(e) => {
                  setTemplate({ ...template, description: e.target.value });
                }}
                onBlur={(e) => {
                  _updateTemplate({ description: e.target.value });
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            </Tooltip>
            <ConfirmDialog
              title="Confirm"
              text="Are you sure you want to delete this global variable?"
              showConfirmDialog={showConfirmDeleteGlobalVariableDialog}
              handleConfirmDialogClose={handleConfirmDeleteGlobalVariableDialogClose}
              handleConfirmDelete={handleConfirmDeleteGlobalVariable}
            />
            {template.status === "published" && (
              <Typography variant="h1" sx={{ ml: "10%" }}>
                {templateIsPublishedMessage}
              </Typography>
            )}

            <span style={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                disabled={!(template.status === "published" || template.status === "in_progress")}
                color="success"
                onClick={handleTestTemplate}
                style={{ padding: " 10px 15px" }}
              >
                Test Template
              </Button>
            </span>

            <Tooltip title={template.status === "published" ? templateIsPublishedMessage : null}>
              <span style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  disabled={template.status === "published"}
                  color="success"
                  onClick={handleConfirmPublishTemplate}
                  style={{ padding: " 10px 15px" }}
                >
                  {template.status === "published" ? "Published" : "Publish Template"}
                </Button>
                <ConfirmationDialog
                title="Are you sure you want to publish this template?"
                isOpen={isPublishTemplateModalOpen}
                setIsOpen={setIsPublishTemplateModalOpen}
                displayText={{ confirm: "Yes", cancel: "No" }}
                confirmAction={handlePublishTemplate}
                />
              </span>
            </Tooltip>
            <Button variant="contained" color="error" href="/" style={{ marginLeft: "1em" }}>
              Close Editor
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1, paddingTop: 0 }}>
            <GlobalVariables
              templateId={templateId}
              templateIsPublished={template.status === "published"}
              variables={variables}
              createGlobalVariable={createGlobalVariable}
              updateGlobalVariable={updateGlobalVariable}
              deleteGlobalVariable={deleteGlobalVariable}
              templateVariables={templateVariables}
              assignTemplateVariable={assignTemplateVariable}
              unAssignTemplateVariable={unassignTemplateVariable}
            />
          </Box>

          <Box sx={{ flexGrow: 1 }} paddingTop="3em">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={currentTab} onChange={handleChangeTab} aria-label="template builder tabs">
                <Tab label="Data Center" {...a11yProps(0)} onClick={handleDatacenterClick} />
                <Tab label="Iteration 1" {...a11yProps(1)} />
                <Tab label="Iteration 2" {...a11yProps(2)} />
                <Tab label="Iteration 3" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              {template.status !== PUBLISHED_TEMPLATE && (
                <DataCenterConfirm
                  isOpen={isDatacenterConfirmOpen}
                  setIsOpen={setIsDatacenterConfirmOpen}
                  getTemplate={getTemplate}
                />
              )}
              {template?.type === DATACENTER_TEMPLATE && (
                <>
                  <DataCenterVariables variables={variables} template={template} />
                  <DataCenterSection templateStatus={template?.status} />
                </>
              )}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Box>
                {fieldCollections.map((fieldCollection, i) => {
                  return (
                    <FieldCollection
                      key={i}
                      count={i}
                      fieldCollection={fieldCollection}
                      templateId={templateId}
                      templateIsPublished={template.status === "published"}
                      getFieldCollections={getFieldCollections}
                      variables={variables}
                      templateVariables={templateVariables}
                      getSelectedTemplateVariableName={getSelectedTemplateVariableName}
                    />
                  );
                })}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingBottom="2%"
                  style={{ marginBottom: "2%", marginTop: "2%" }}
                >
                  <FormControl>
                    <Button
                      startIcon={<AddIcon />}
                      disabled={template.status === "published"}
                      onClick={_createFieldCollection}
                      sx={fieldCollections?.length === 0 ? { border: `1px solid ${WARNING_COLOR}` } : {}}
                    >
                      New Field Collection
                    </Button>
                    {fieldCollections?.length === 0 && <FormHelperText>Create a field collection.</FormHelperText>}
                  </FormControl>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <IterationTwo templateId={templateId} templateIsPublished={template.status === "published"} />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <IterationThree templateId={templateId} templateIsPublished={template.status === "published"} />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TemplateBuilder;
