import create from "zustand";
import { Alert, Snackbar } from "utils/MuiWrapper/components";
import { ALERT_BACKGROUND_COLOR } from "utils/constants";

export function AlertSnackbar() {
  const { alert, setAlert } = useAlertSnackbarState((state) => state);

  if (!alert?.type) return null;

  const handleClose = () =>
    setAlert({
      type: "",
      message: "",
      timeout: 5,
    });

  return (
    <Snackbar
      open={!!alert?.message}
      autoHideDuration={(alert.timeout || 5) * 1000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={alert?.type}
        sx={{
          width: "100%",
          backgroundColor: ALERT_BACKGROUND_COLOR,
          color: "#fff",
          display: "flex",
          alignItems: "center",
        }}
      >
        {alert?.message}
      </Alert>
    </Snackbar>
  );
}

type AlertType = {
  type: "error" | "info" | "success" | "warning" | "";
  message: string | JSX.Element;
  timeout?: number;
};

export type AlertSnackBarType = {
  alert: AlertType;
  setAlert: (x: AlertType) => void;
};

export const useAlertSnackbarState = create<AlertSnackBarType>((set) => ({
  alert: { type: "", message: "", timeout: 5 },
  setAlert: (alert) => set({ alert }),
}));
