import { getRequest, createRequest, updateRequest, deleteRequest } from "../http/axiosClient";
import { useQuery } from "@tanstack/react-query";
import { isApiError } from "types/ApiError";
import { QueryParamsType } from "types/Queries";
import { Template } from "types/Template";
import { ProjectTemplateSectionField } from "types/ProjectTemplateSectionField";

// Fetch projects
export async function fetchProjects(queryParams?: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/projects`, queryParams);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`Could not get projects. ${error?.response?.data?.detail || ""}`));
  }
}

export const useProjects = (params: QueryParamsType) => {
  return useQuery(["projects", params], () => fetchProjects(params));
};

// Fetch favorite projects
export async function fetchFavoriteProjects(queryParams?: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/projects/favorite`, queryParams);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`Could not get favorite projects. ${error?.response?.data?.detail || ""}`));
  }
}

export const useFavoriteProjects = (params: QueryParamsType) => {
  return useQuery(["favorite-projects", params], () => fetchFavoriteProjects(params));
};

// Fetch project by ID
export async function fetchProjectById(projectId: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(new Error(`Could not get project data. ${error?.response?.data?.detail || ""}`));
  }
}

// Update project by ID
export async function updateProjectById(projectId, data) {
  try {
    const res = await updateRequest(`/v1/api/projects/${projectId}`, data);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(error?.response?.data?.message || error?.response?.data?.detail || "Your changes could not be saved.")
    );
  }
}

// Update project team
export async function updateProjectTeam(projectId, data) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/team`, data);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(error?.response?.data?.message || error?.response?.data?.detail || "Your changes could not be saved.")
    );
  }
}

// Delete project team member
export async function deleteProjectTeamMember(projectId, userId) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/team/${userId}`);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(`The team member could not be removed from the project. ${error?.response?.data?.message || ""}`)
    );
  }
}

// Favorite project
export async function favoriteProjectById(projectId) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/favorite`, {});
    return res.data;
  } catch (error: any) {
    return Promise.reject(
      new Error(`The project could not be added to favorites. ${error?.response?.data?.message || ""}`)
    );
  }
}

// Unfavorite project
export async function unfavoriteProjectById(projectId) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/favorite`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(
      new Error(`The project could not be removed from favorites. ${error?.response?.data?.message || ""}`)
    );
  }
}

export type ProjectData = Record<string, string>;

// Create project
export async function createProject(data: ProjectData) {
  try {
    const res = await createRequest(`/v1/api/projects`, data);
    return res.data;
  } catch (error) {
    if (isApiError(error) && error.response.data.detail === "id: This project already exists") {
      alert("Project name already exists. Use a different project name.");
    }
  }
}

// Fetch project template
export async function fetchProjectTemplate(projectId: string, projectTemplateId: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Add template to a project
export async function addTemplateToProject(projectId: string, templateId: string, data: Template) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/templates/${templateId}`, data);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(
        error?.response?.data?.detail ||
          error?.response?.data?.message ||
          "The template could not be added to the project."
      )
    );
  }
}

// Delete template from a project
export async function deleteProjectTemplate(projectId: string, projectTemplateId: string) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Fetch project template sections
export type ProjectTemplateFetchPatams = Record<string, string>;

export async function fetchProjectTemplateSections(
  projectId: string,
  projectTemplateId: string,
  params: ProjectTemplateFetchPatams
) {
  try {
    const res = await getRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections?limit=10000`,
      params
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export type ProjectTemplateSectionData = Record<string, string>;

// Update project template section
export async function updateProjectTemplateSection(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  data: ProjectTemplateSectionData
) {
  try {
    const res = await updateRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}`,
      data
    );
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`The section could not be updated. ${error?.response?.data?.detail || ""}`));
  }
}

// Delete project template section
export async function deleteProjectTemplateSection(projectId, projectTemplateId, projectSectionId) {
  try {
    const res = await deleteRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}`
    );
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`The section could not be deleted. ${error?.response?.data?.detail || ""}`));
  }
}

// Update project template
export type ProjectTemplateData = Record<string, string>;

export async function updateProjectTemplate(projectId: string, projectTemplateId: string, data: ProjectTemplateData) {
  try {
    const res = await updateRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function createProjectTemplateSectionField(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  data: ProjectTemplateSectionField
) {
  try {
    const res = await createRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/fields`,
      data
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

// Delete project template section field
export async function deleteProjectTemplateSectionField(
  projectId,
  projectTemplateId,
  projectSectionId,
  projectTemplateSectionFieldId
) {
  try {
    const res = await deleteRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/fields/${projectTemplateSectionFieldId}`
    );
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`The field could not be deleted. ${error?.response?.data?.detail || ""}`));
  }
}

// Update project template section field
export async function updateProjectTemplateSectionField(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  projectSectionFieldId: string,
  data: ProjectTemplateSectionField
) {
  try {
    const res = await updateRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/fields/${projectSectionFieldId}`,
      data
    );
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(error?.response?.data?.detail || error?.response?.data?.message || "The section could not be updated")
    );
  }
}

/**
 * Returns a project
 */
export const useProject: any = (projectId?: string) => {
  return useQuery(["project", projectId], () => fetchProjectById(projectId || ""));
};

export const useProjectForDetail: any = (enabled: boolean, projectId?: string) => {
  return useQuery(["projectDetailPane", projectId], () => fetchProjectById(projectId || ""), { enabled: enabled });
};

// Clone project template section
export async function cloneProjectTemplateSection(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  data?: ProjectTemplateSectionData
) {
  try {
    const res = await createRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/clone`,
      data
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function cloneTemplateToAnotherProject(
  projectId: string,
  projectTemplateId: string,
  name: string,
  targetProjectId: string
) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}/clone`, {
      name: name,
      target_project_id: targetProjectId,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(new Error(`Could Not Clone Template`));
  }
}
