import { useQuery } from "@tanstack/react-query";
import { getRequest, createRequest, updateRequest, deleteRequest } from "../http/axiosClient";
import { ScalerMapping } from "types/TemplateScaler";
import { patchRequest } from "http/client";

export async function addLocationToProject(projectId?: string, location?: string) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/locations`, {
      name: location,
    });
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(error?.response?.data?.message || "Could not add location."));
  }
}

export async function getLocationsForProject(projectId?: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}/locations
    `);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(
      new Error(error?.response?.data?.detail || error?.response?.data?.message || "Could not get locations.")
    );
  }
}

export const useProjectLocations = (projectId?: string) => {
  return useQuery({
    queryKey: ["project-locations", projectId],
    queryFn: () => getLocationsForProject(projectId),
    retry: false,
  });
};

export async function deleteLocationFromProject(projectId?: string, locationId?: string) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/locations/${locationId}`);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(error?.response?.data?.message || "Could not delete location."));
  }
}

export async function updateLocationInProject(projectId?: string, locationId?: string, name?: string) {
  try {
    const res = await updateRequest(
      `/v1/api/projects/${projectId}/locations/${locationId}
    `,
      { name }
    );
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(error?.response?.data?.message || "Could not delete location."));
  }
}

export async function getScalersForProjectDC(projectId: string, datacenterId: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}/datacenters/${datacenterId}/scalers
    `);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(
        error?.response?.data?.detail ||
          error?.response?.data?.message ||
          "Could not get scaler values for the datacenter."
      )
    );
  }
}

export const useGetScalersForProjectDC = (projectId: string, datacenterId: string) => {
  return useQuery({
    queryKey: ["project-dc-scalers", projectId, datacenterId],
    queryFn: () => getScalersForProjectDC(projectId, datacenterId),
    retry: false,
  });
};

export async function createScalerMapping(projectId: string, data: ScalerMapping) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/scalers`, data);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(error?.response?.data?.message || error?.response?.data?.detail || "Could not create scaler mapping.")
    );
  }
}

export async function patchScalerMapping(projectId: string, scalerId: string, data: ScalerMapping) {
  try {
    const res = await patchRequest(`/v1/api/projects/${projectId}/scalers/${scalerId}`, data);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(error?.response?.data?.message || error?.response?.data?.detail || "Could not edit scaler.")
    );
  }
}

export async function deleteScalerMapping(projectId: string, scalerId: string) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/scalers/${scalerId}`);
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(
      new Error(error?.response?.data?.message || error?.response?.data?.detail || "Could not delete scaler.")
    );
  }
}
