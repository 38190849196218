import React, { useEffect, useState } from "react";
import { fetchFieldCollections, createFieldCollection } from "api/template";
import { fetchComponentAttributes } from "api/component";
import Iteration from "./iteration";

const IterationThree = ({ templateId, templateIsPublished }) => {
  const [iterationId, setIterationId] = useState("");
  const [componentAttributes, setComponentAttributes] = useState([]);

  useEffect(() => {
    if (templateId) {
      getIteration(templateId);
      getComponentAttributes();
    }
  }, []);

  const getIteration = async (templateId) => {
    const data = await fetchFieldCollections(templateId);
    const iteration = data.results.find((item) => item.type === "iteration" && item.ordinal === 3);
    if (iteration) {
      setIterationId(iteration.id);
    } else {
      createIteration();
    }
  };

  const createIteration = async () => {
    const data = {
      name: "Iteration 3",
      ordinal: 3,
      clonable: false,
      multipliable: false,
      type: "iteration",
    };
    const fieldCollection = await createFieldCollection(templateId, data);
    setIterationId(fieldCollection.id);
  };

  const getComponentAttributes = async () => {
    const data = await fetchComponentAttributes();
    setComponentAttributes(
      data?.map((value) => {
        return {
          id: value.id,
          name: value.speed ? `Network Port - ${value.media_type} ${value["sfp_type"]} ${value["speed"]}` : value.name,
        };
      })
    );
  };

  return (
    <Iteration
      templateId={templateId}
      templateIsPublished={templateIsPublished}
      iterationId={iterationId}
      selectOptions={componentAttributes}
      sequenceId={3}
    />
  );
};

export default IterationThree;
