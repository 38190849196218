import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  Tooltip,
} from "utils/MuiWrapper/components";
import { Add as AddIcon, RemoveCircleOutline as RemoveCircleOutlineIcon } from "utils/MuiWrapper/icons";
import React, { useEffect, useRef, useState } from "react";
import { getMaxOrdinal } from "utils/Ordinalutil";
import { fetchFieldOptions, createField, updateField, deleteField, deleteFieldOption } from "api/template";
import { CustomSelect } from "../../../shared/controls";
import { ACTIVE_BORDER, NULL_ADDRESS, TEXTFIELD_BORDER_RADIUS, WARNING_COLOR } from "utils/constants";
import { fetchRulesByTemplateId, updateRuleById, updateRuleConditionById } from "api/rule";
import { TemplateSectionField } from "types/TemplateSectionField";
import { Error } from "@mui/icons-material";

const ColumnOne = ({
  columnOneSelected,
  columnOneFieldId,
  templateId,
  templateIsPublished,
  fieldCollectionId,
  clearColumnOne,
  deleteRules,
  calculateHeight,
  sectionFields,
  getFields,
  selectOptions,
  type,
  collectionCount,
}: {
  columnOneSelected: (x: TemplateSectionField, value: boolean) => void;
  columnOneFieldId: string;
  templateId: string;
  templateIsPublished: boolean;
  fieldCollectionId: string;
  clearColumnOne: () => void;
  deleteRules: (templateId: string, fieldCollectionId: string, fieldId: string, sectionFieldId: string) => void;
  calculateHeight: (col: number, len: number) => void;
  sectionFields: TemplateSectionField[];
  getFields: (templateId: string, fieldCollectionId: string) => void;
  selectOptions: Array<any>;
  type: "collection" | "iteration";
  collectionCount: number;
}) => {
  const [fields, setFields] = useState<TemplateSectionField[]>([]);
  const [maxOrdinal, setMaxOrdinal] = useState(0);
  const parentNode = useRef<Element>();

  useEffect(() => {
    setFields(sectionFields);
    const maxOrdinal = getMaxOrdinal(sectionFields);
    setMaxOrdinal(maxOrdinal);
    calculateHeight(1, sectionFields.length);
  }, [sectionFields]);

  const handleFieldChange = (value, id) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        field.name = value;
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleVariableChange = async (name, value, id) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        field.variable_id = value;
      }
      return field;
    });
    setFields(updatedFields);
    updateFieldData({ variable_id: value, name: name }, id);
    const data = await fetchRulesByTemplateId(templateId);
    const filteredRules = data.results.filter((rule) => {
      return rule.template_section_field_options.col1 === columnOneFieldId;
    });
    for (const rule of filteredRules) {
      // Update variable id of rule
      const ruleData = {
        template_section_field_options: {
          col1: rule.template_section_field_options.col1,
          col2: rule.template_section_field_options.col2,
          variable_id: value,
        },
      };
      await updateRuleById(rule.id, ruleData);
      // Update variable id of rule conditions
      const conditionsId = rule.rule_conditions[0].id;
      const ruleConditions = rule.rule_conditions[0].rule_conditions;
      for (const ruleCondition of ruleConditions) {
        const ruleConditionData = { variable_id: value };
        await updateRuleConditionById(rule.id, conditionsId, ruleCondition.id, ruleConditionData);
      }
    }
  };

  const getFieldOptions = async (templateId, fieldCollectionId, columnOneFieldId) => {
    const data = await fetchFieldOptions(templateId, fieldCollectionId, columnOneFieldId);
    return data;
  };

  const clearAndCreate = () => {
    clearColumnOne();
    createNewField();
  };

  const createNewField = async () => {
    const data = {
      name: "New Field",
      description: "Description",
      ordinal: maxOrdinal + 1,
      fixed: type === "iteration",
      variable_id: NULL_ADDRESS,
    };

    const field = await createField(templateId, fieldCollectionId, data);
    getFields(templateId, fieldCollectionId);
    return field;
  };

  const fieldSelected = (field: TemplateSectionField) => {
    columnOneSelected(field, true);
  };
  const updateFieldData = async (data: Partial<TemplateSectionField>, fieldId: string) => {
    if ("name" in data) {
      if (data.name) {
        const updatedField = await updateField(templateId, fieldCollectionId, fieldId, data);
        swapStateField(updatedField);
      }
    } else {
      const updatedField = await updateField(templateId, fieldCollectionId, fieldId, data);
      swapStateField(updatedField);
    }
  };

  const swapStateField = (field: TemplateSectionField) => {
    setFields(
      fields.map((item) => {
        if (item.id === field.id) {
          return field;
        }
        return item;
      })
    );
  };

  const _deleteField = async (fieldId: string) => {
    const data = await getFieldOptions(templateId, fieldCollectionId, fieldId);
    for (const field of data.results) {
      await deleteFieldOption(templateId, fieldCollectionId, fieldId, field.id);
      deleteRules(templateId, fieldCollectionId, fieldId, field.id);
    }
    await deleteField(templateId, fieldCollectionId, fieldId);
    getFields(templateId, fieldCollectionId);
    clearColumnOne();
  };

  const getStyles = (field, value: string) => {
    if (!value || value === NULL_ADDRESS) {
      return { border: `1px solid ${WARNING_COLOR}`, borderRadius: TEXTFIELD_BORDER_RADIUS };
    } else if (columnOneFieldId == field.id) {
      return { border: `2px solid ${ACTIVE_BORDER}`, borderRadius: TEXTFIELD_BORDER_RADIUS };
    } else {
      return {};
    }
  };

  function handleArrowKey({ key, isFocusPresent, availableElements, activeElement }) {
    const currentIndex = Array.from(availableElements).findIndex(
      (availableElement) => availableElement === activeElement
    );

    if (key === "ArrowDown") {
      availableElements[currentIndex + 1]?.focus();
    }

    if (key === "ArrowUp") {
      availableElements[currentIndex - 1]?.focus();
    }
  }

  function handleArrowKeyEvent({ e }) {
    const key = e.key;
    const selectors = "input#filled-basic";
    const activeElement = document.activeElement;
    const availableElements = parentNode.current?.querySelectorAll(selectors);

    if (!availableElements?.length) return;
    const isFocusPresent = parentNode.current?.contains(activeElement);

    handleArrowKey({ key, isFocusPresent, activeElement, availableElements });
  }

  if (type === "collection") {
    return (
      <Box flex={1} ref={parentNode}>
        {fields?.map((field, i) => {
          let name = field.name;
          if (name === "New Field") {
            name = "";
          }
          return (
            <Box key={i} marginBottom="0.5em" height={25} paddingRight={3} display="flex" alignItems="center">
              <Checkbox
                sx={{ marginRight: "1em" }}
                defaultChecked={field.fixed}
                disabled={templateIsPublished}
                onChange={(e) => {
                  updateFieldData({ fixed: e.target.checked }, field.id);
                }}
              />
              {!name && (
                <Tooltip title="Enter option name.">
                  <IconButton aria-label="info" disabled={templateIsPublished}>
                    <Error />
                  </IconButton>
                </Tooltip>
              )}
              <FormControl fullWidth>
                <TextField
                  autoFocus
                  id="filled-basic"
                  hiddenLabel
                  size="small"
                  fullWidth
                  inputProps={{ tabIndex: collectionCount * 5000 + (1 + 6 * i) }}
                  autoComplete="off"
                  sx={getStyles(field, name)}
                  placeholder={!name ? "New Field" : ""}
                  value={name}
                  disabled={templateIsPublished}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, field.id);
                  }}
                  onFocus={() => {
                    fieldSelected(field);
                  }}
                  onBlur={(e) => {
                    updateFieldData({ name: e.target.value }, field.id);
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (e.key === "Enter") {
                      (e.target as HTMLImageElement).blur();
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                      handleArrowKeyEvent({ e });
                    }
                  }}
                />
              </FormControl>
              <IconButton
                aria-label="delete"
                disabled={templateIsPublished}
                onClick={() => {
                  _deleteField(field.id);
                }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          );
        })}
        <Box display="flex" marginTop="4%" justifyContent="center">
          <FormControl>
            <Button
              startIcon={<AddIcon />}
              disabled={templateIsPublished}
              fullWidth
              onClick={clearAndCreate}
              sx={fields?.length === 0 ? { border: `1px solid ${WARNING_COLOR}` } : {}}
            >
              New Field
            </Button>
            {fields?.length === 0 && <FormHelperText>Add a field.</FormHelperText>}
          </FormControl>
        </Box>
      </Box>
    );
  }
  if (type === "iteration") {
    return (
      <Box flex={1}>
        {fields?.map((field, i) => {
          const variable_id = field.variable_id === undefined ? "" : field.variable_id;
          return (
            <Box key={i} marginBottom="0.5em" height={25} paddingRight={3} display="flex" alignItems="center">
              {variable_id === NULL_ADDRESS && (
                <Tooltip title="Select a variable.">
                  <IconButton aria-label="info" disabled={templateIsPublished}>
                    <Error />
                  </IconButton>
                </Tooltip>
              )}
              <FormControl
                disabled={templateIsPublished}
                onFocus={() => {
                  fieldSelected(field);
                }}
                fullWidth
              >
                <CustomSelect
                  size="small"
                  fullWidth
                  sx={getStyles(field, variable_id)}
                  value={variable_id}
                  onChange={(e) => {
                    handleVariableChange(
                      selectOptions.find((item) => item.id === e.target.value).name || "default",
                      e.target.value,
                      field.id
                    );
                  }}
                >
                  <MenuItem value={NULL_ADDRESS} disabled>
                    <em>Select Option</em>
                  </MenuItem>
                  {selectOptions?.map((variable) => {
                    return (
                      <MenuItem key={variable.id} value={variable.id}>
                        {variable.name}
                      </MenuItem>
                    );
                  })}
                </CustomSelect>
              </FormControl>
              <IconButton
                aria-label="delete"
                disabled={templateIsPublished}
                onClick={() => {
                  _deleteField(field.id);
                }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          );
        })}

        <Box display="flex" marginTop="1em" justifyContent="center">
          <Button startIcon={<AddIcon />} disabled={templateIsPublished} onClick={clearAndCreate}>
            New Field
          </Button>
        </Box>
      </Box>
    );
  }
  return null;
};

export default ColumnOne;
