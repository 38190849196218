import { Box, TextField } from "utils/MuiWrapper/components";
import React, { useEffect, useState, useRef } from "react";
import { updateField } from "api/template";
import { TemplateSectionField } from "types/TemplateSectionField";
import { ACTIVE_BORDER } from "utils/constants";

const ColumnFour = ({
  templateId,
  templateIsPublished,
  fieldCollectionId,
  columnOneFieldId,
  sectionFields,
  columnOneSelected,
  collectionCount,
}: {
  templateId: string;
  templateIsPublished: boolean;
  fieldCollectionId: string;
  columnOneFieldId: string;
  sectionFields: TemplateSectionField[];
  columnOneSelected: (field: TemplateSectionField, value: boolean) => void;
  collectionCount: number;
}) => {
  const [fields, setFields] = useState<TemplateSectionField[]>([]);
  const parentNode = useRef<Element>();

  useEffect(() => {
    setFields(sectionFields);
  }, [sectionFields]);

  const handleFieldChange = (value: string, id: string) => {
    const updatedFields = fields.map((field) => {
      if (field.id == id) {
        field.description = value;
      }
      return field;
    });
    setFields(updatedFields);
  };

  const fieldSelected = (field: TemplateSectionField) => {
    columnOneSelected(field, true);
  };

  const updateDescription = async (data: Partial<TemplateSectionField>, fieldId: string) => {
    if ("description" in data) {
      if (data.description) {
        const updatedField = await updateField(templateId, fieldCollectionId, fieldId, data);
        swapStateField(updatedField);
      }
    } else {
      const updatedField = await updateField(templateId, fieldCollectionId, fieldId, data);
      swapStateField(updatedField);
    }
  };

  const swapStateField = (field: TemplateSectionField) => {
    setFields(
      fields.map((item) => {
        if (item.id === field.id) {
          return field;
        }
        return item;
      })
    );
  };

  function handleArrowKey({ key, isFocusPresent, availableElements, activeElement }) {
    const currentIndex = Array.from(availableElements).findIndex(
      (availableElement) => availableElement === activeElement
    );

    if (key === "ArrowDown") {
      availableElements[currentIndex + 1]?.focus();
    }

    if (key === "ArrowUp") {
      availableElements[currentIndex - 1]?.focus();
    }
  }

  function handleArrowKeyEvent({ e }) {
    const key = e.key;
    const selectors = "input#filled-basic";

    const activeElement = document.activeElement;
    const availableElements = parentNode.current?.querySelectorAll(selectors);
    if (!availableElements?.length) return;
    const isFocusPresent = parentNode.current?.contains(activeElement);

    handleArrowKey({ key, isFocusPresent, activeElement, availableElements });
  }

  return (
    <Box flex={1} ref={parentNode}>
      {fields?.map((field, i) => {
        let description = field.description;
        if (description === "Description") {
          description = "";
        }
        return (
          <Box key={i} marginBottom="0.5em" height={25} display="flex" alignItems="center">
            <TextField
              id="filled-basic"
              hiddenLabel
              size="small"
              fullWidth
              inputProps={{ tabIndex: collectionCount * 5000 + (6 + 6 * i) }}
              autoComplete="off"
              sx={columnOneFieldId == field.id ? { border: `2px solid ${ACTIVE_BORDER}`, borderRadius: "5px" } : {}}
              placeholder={!description ? "Description" : ""}
              value={description}
              disabled={templateIsPublished}
              onChange={(e) => {
                handleFieldChange(e.target.value, field.id);
              }}
              onFocus={() => {
                fieldSelected(field);
              }}
              onBlur={(e) => {
                updateDescription({ description: e.target.value }, field.id);
              }}
              onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                if (e.key === "Enter") {
                  (e.target as HTMLImageElement).blur();
                }
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) => {
                if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                  handleArrowKeyEvent({ e });
                }
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ColumnFour;
