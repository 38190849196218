import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Button, FormControl, FormHelperText, IconButton, TextField, Tooltip } from "utils/MuiWrapper/components";
import { Add as AddIcon, RemoveCircleOutline as RemoveCircleOutlineIcon } from "utils/MuiWrapper/icons";
import { getMaxOrdinal } from "utils/Ordinalutil";
import { createFieldOption, updateFieldOption, deleteFieldOption, useFieldOptions } from "api/template";
import { useAlertSnackbarState } from "../../../AlertSnackbar/AlertSnackbar";
import { ACTIVE_BORDER, TEXTFIELD_BORDER_RADIUS, WARNING_COLOR } from "utils/constants";
import { getFormInputStyles } from "components/shared/controls";
import { Error } from "@mui/icons-material";

const ColumnTwo = ({
  columnTwoSelected,
  templateId,
  templateIsPublished,
  fieldCollectionId,
  columnOneFieldId,
  columnTwoFieldId,
  deleteRules,
  createDefaultRule,
  calculateHeight,
  collectionCount,
}: {
  columnTwoSelected: (id: string, value: boolean) => void;
  templateId: string;
  templateIsPublished: boolean;
  fieldCollectionId: string;
  columnOneFieldId: string;
  columnTwoFieldId: string;
  deleteRules: (
    templateId: string,
    fieldCollectionId: string,
    columnOneFieldId: string,
    fieldId: string
  ) => Promise<void>;
  createDefaultRule: (columnOneFieldId: string, fieldId: string, fieldCollectionId: string) => void;
  calculateHeight: (col: number, len: number) => void;
  collectionCount: number;
}) => {
  const [editedFields, setEditedFields] = useState({});
  const [errorId, setErrorId] = useState("");
  const [maxOrdinal, setMaxOrdinal] = useState(0);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const queryClient = useQueryClient();
  const { data: fieldOptionsData, error: fieldOptionsError } = useFieldOptions(
    templateId,
    fieldCollectionId,
    columnOneFieldId
  );
  const parentNode = useRef<Element>();

  useEffect(() => {
    if (fieldOptionsData) {
      const maxOrdinal = getMaxOrdinal(fieldOptionsData.results);
      setMaxOrdinal(maxOrdinal);
      calculateHeight(2, fieldOptionsData.results.length);
    }
  }, [fieldOptionsData]);

  useEffect(() => {
    if (fieldOptionsError) setAlert({ type: "error", message: "Field options could not be fetched." });
  }, [fieldOptionsError]);

  const invalidateFields = () =>
    queryClient.invalidateQueries(["field-options", templateId, fieldCollectionId, columnOneFieldId]);

  const handleFieldChange = (value: string, id: string) => {
    setEditedFields({ ...editedFields, [id]: value });
  };

  const clearAndCreate = () => {
    fieldSelected("", false);
    createField();
  };

  const createField = async () => {
    const data = {
      field_column: 2,
      ordinal: maxOrdinal + 1,
      field_option: "Field Option",
    };
    const field = await createFieldOption(templateId, fieldCollectionId, columnOneFieldId, data);
    createDefaultRule(columnOneFieldId, field.id, fieldCollectionId);
    invalidateFields();
    setMaxOrdinal((prev) => prev + 1);
    calculateHeight(2, parseInt(fieldOptionsData?.results?.length) + 1);
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const updateField = async (data, fieldId) => {
    if (data.field_option === "") {
      setErrorId(fieldId);
    } else if (data.field_option) {
      if (fieldId === errorId) setErrorId("");
      await updateFieldOption(templateId, fieldCollectionId, columnOneFieldId, fieldId, data);
      invalidateFields();
    }
  };

  const fieldSelected = (fieldId: string, selected: boolean) => {
    columnTwoSelected(fieldId, selected);
  };

  const deleteField = async (fieldId: string) => {
    await deleteFieldOption(templateId, fieldCollectionId, columnOneFieldId, fieldId);
    await deleteRules(templateId, fieldCollectionId, columnOneFieldId, fieldId);
    invalidateFields();
    fieldSelected("", false);
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const getStyles = (field, value) => {
    if (columnTwoFieldId === field.id) {
      return {
        border: field.id !== errorId ? `2px solid ${ACTIVE_BORDER}` : "inherit",
        borderRadius: TEXTFIELD_BORDER_RADIUS,
      };
    } else if (!value || field.id === errorId) {
      return { border: `1px solid ${WARNING_COLOR}`, borderRadius: TEXTFIELD_BORDER_RADIUS };
    } else {
      return {};
    }
  };

  function handleArrowKey({ key, isFocusPresent, availableElements, activeElement }) {
    const currentIndex = Array.from(availableElements).findIndex(
      (availableElement) => availableElement === activeElement
    );

    if (key === "ArrowDown") {
      availableElements[currentIndex + 1]?.focus();
    }

    if (key === "ArrowUp") {
      availableElements[currentIndex - 1]?.focus();
    }
  }

  function handleArrowKeyEvent({ e }) {
    const key = e.key;
    const selectors = "input#filled-basic";

    const activeElement = document.activeElement;
    const availableElements = parentNode.current?.querySelectorAll(selectors);

    if (!availableElements?.length) return;
    const isFocusPresent = parentNode.current?.contains(activeElement);

    handleArrowKey({ key, isFocusPresent, activeElement, availableElements });
  }

  return (
    <Box sx={{ width: "100%" }} ref={parentNode}>
      {fieldOptionsData?.results?.map((field, i) => {
        let option = field.field_option;
        if (option === "Field Option") {
          option = "";
        }
        return (
          <Box
            key={i}
            marginBottom="0.5em"
            height={25}
            paddingRight={3}
            display="flex"
            alignItems="center"
            alignSelf="flex-start"
          >
            {(!option || editedFields[field.id] === "") && (
              <Tooltip title="Enter option name.">
                <IconButton aria-label="info" disabled={templateIsPublished}>
                  <Error />
                </IconButton>
              </Tooltip>
            )}
            <FormControl fullWidth>
              <TextField
                autoFocus
                id="filled-basic"
                hiddenLabel
                size="small"
                fullWidth={true}
                inputProps={{ tabIndex: collectionCount * 5000 + (2 + 6 * i) }}
                autoComplete="off"
                sx={getStyles(field, option)}
                placeholder={!option ? "Field Option" : ""}
                value={editedFields[field.id] ?? option}
                disabled={templateIsPublished}
                onChange={(e) => handleFieldChange(e.target.value, field.id)}
                onFocus={() => {
                  fieldSelected(field.id, true);
                }}
                onBlur={(e) => {
                  updateField({ field_option: e.target.value }, field.id);
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                    handleArrowKeyEvent({ e });
                  }
                }}
              />
            </FormControl>
            <IconButton
              aria-label="delete"
              disabled={templateIsPublished}
              onClick={() => {
                deleteField(field.id);
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        );
      })}
      <Box display="flex" marginTop="1em" justifyContent="center">
        <FormControl>
          <Button
            disabled={templateIsPublished}
            startIcon={<AddIcon />}
            onClick={clearAndCreate}
            fullWidth={true}
            sx={fieldOptionsData?.results?.length === 0 ? { border: `1px solid ${WARNING_COLOR}` } : {}}
          >
            New Field
          </Button>
          {fieldOptionsData?.results?.length === 0 && <FormHelperText>Create a field option.</FormHelperText>}
        </FormControl>
      </Box>
    </Box>
  );
};

export default ColumnTwo;
