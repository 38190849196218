import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTemplateVariable, deleteTemplateVariableById, useSelectedTemplateVars } from "api/template";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ScalarVariable } from "types/TemplateVariable";
import { Box, Button, ButtonGroup, Divider, Drawer, IconButton, Typography } from "utils/MuiWrapper/components";
import { ChevronRight } from "utils/MuiWrapper/icons";
import { DATACENTER_TEMPLATE, PUBLISHED_TEMPLATE } from "utils/constants";

export const DataCenterVariables = ({ variables, template }) => {
  const { templateId } = useParams();
  const queryClient = useQueryClient();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [isOpen, setIsOpen] = useState(false);
  const [prefixedVars, setPrefixedVars] = useState([]);
  const [selectedVarLookup, setSelectedVarLookup] = useState({});
  const { data: selectedVars } = useSelectedTemplateVars(templateId || "", {
    type: "dc",
  });

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (variables) {
      const prefixed = variables.map((variable: ScalarVariable) => {
        return { ...variable, name: `SV: ${variable.name}` };
      });
      setPrefixedVars(prefixed);
    }
  }, [variables]);

  useEffect(() => {
    if (selectedVars) {
      setSelectedVarLookup(selectedVars.results?.reduce((acc, cur) => ({ ...acc, [cur.variable_id]: true }), {}));
    }
  }, [selectedVars]);

  const { mutate: selectVariable } = useMutation({
    mutationFn: (variable_id: string) =>
      createTemplateVariable(templateId || "", { variable_id, type: DATACENTER_TEMPLATE }),
    onSuccess: () => {
      queryClient.invalidateQueries(["selected-template-variables", templateId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const { mutate: deselectVariable } = useMutation({
    mutationFn: (id: string) => deleteTemplateVariableById(templateId || "", id, { type: DATACENTER_TEMPLATE }),
    onSuccess: () => {
      queryClient.invalidateQueries(["selected-template-variables", templateId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const handleVariableClick = (variable: ScalarVariable) => {
    if (selectedVarLookup[variable.id]) {
      const selectedVar = selectedVars?.results.find((result) => result.variable_id === variable.id);
      deselectVariable(selectedVar.id);
    } else {
      selectVariable(variable.id);
    }
  };

  return (
    <>
      <Box>
        <Button onClick={handleOpen}>Select Variables</Button>
      </Box>
      <Drawer
        open={isOpen}
        anchor="right"
        PaperProps={{ sx: { width: "30%" } }}
        ModalProps={{ onBackdropClick: handleClose }}
      >
        <IconButton onClick={handleClose}>
          <ChevronRight fontSize="medium" />
        </IconButton>
        <Divider />
        <Box sx={{ marginLeft: 3, marginTop: 2 }}>
          <Typography variant="h2" sx={{ marginBottom: 3 }}>
            Scaler Variables
          </Typography>
        </Box>

        {prefixedVars?.map((variable: ScalarVariable) => (
          <ButtonGroup
            key={variable.id}
            disabled={template?.status === PUBLISHED_TEMPLATE}
            color="primary"
            sx={{ margin: "5px", width: "fit-content" }}
            variant={selectedVarLookup[variable.id] ? "contained" : "outlined"}
          >
            <Button onClick={() => handleVariableClick(variable)}>{variable.name}</Button>
          </ButtonGroup>
        ))}
      </Drawer>
    </>
  );
};
