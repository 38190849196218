import { useState } from "react";
import {
  Card,
  CardContent,
  Table,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  IconButton,
  Grid,
  Tooltip,
  Chip,
  Paper,
} from "utils/MuiWrapper/components";
import { Edit } from "utils/MuiWrapper/icons";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProjectById, useProject, updateProjectTeam, deleteProjectTeamMember } from "api/project";
import { useGetUsers } from "api/auth";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { EditTable } from "./EditTable";
import { StandardDate } from "utils/dates";
import { CARD_BG_COLOR } from "utils/constants";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useProjectInfoState } from "./ProjectInfoState";
import { User } from "types/User";
import { TeamMember } from "types/Project";
import { useAuthStore } from "utils/GlobalStores/authStore";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    padding: "0 0 5px 10px"
  },
}));

export const ProjectInfo = () => {
  const userId = useAuthStore((state) => state.userId);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { projectId } = useParams();
  const { data: project, isSuccess, isLoading } = useProject(projectId);
  const queryClient = useQueryClient();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { projectInfo } = useProjectInfoState((state) => state);
  const { data: users } = useGetUsers();

  const handleClickEdit = () => {
    setIsConfirmOpen(true);
  };

  const { mutate: editProjectInfo } = useMutation({
    mutationFn: () => updateProjectById(projectId, projectInfo),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["project", projectId]);
      setIsConfirmOpen(false);
    },
  });

  const { mutate: editProjectTeam } = useMutation({
    mutationFn: (data: any) => updateProjectTeam(project.id, data),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
    onSuccess: (data, variables, _) => {
      queryClient.invalidateQueries(["project", projectId]);
      setAlert({ type: "success", message: `${variables.role === "project_manager" ? "project manager" : "project member"} added` });
    },
  });

  const { mutate: removeProjectTeamMember } = useMutation({
    mutationFn: (data: TeamMember) => deleteProjectTeamMember(project.id, data.user_id),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
    onSuccess: (data, variables, _) => {
      queryClient.invalidateQueries(["project", projectId]);
      setAlert({ type: "success", message: `${variables.role === "project_manager" ? "project manager" : "project member"} removed` });
    },
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess) {
    return <Typography>Error</Typography>;
  }

  const usersMap = new Map<string, User>();
  users?.results?.map((user) => usersMap.set(user.id, user));

  const projectManagerIds: Array<string> = [];
  const projectMemberIds: Array<string> = [];
  project?.project_teams?.map((teamMember: TeamMember) =>
    teamMember.role === "project_manager" ? projectManagerIds.push(teamMember.user_id) : projectMemberIds.push(teamMember.user_id));

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  return (
    <>
      <Card
        variant="elevation"
        sx={{ height: "29vh", overflow: "auto", backgroundColor: CARD_BG_COLOR, borderRadius: "16px" }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h1" gutterBottom>
                Project Info
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {(userId !== undefined && projectManagerIds.includes(userId)) &&
                <Tooltip title="Edit project info">
                  <IconButton onClick={handleClickEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              }
              <ConfirmationDialog
                isOpen={isConfirmOpen}
                title={"Edit Project Info"}
                setIsOpen={setIsConfirmOpen}
                confirmAction={editProjectInfo}
              >
                <EditTable project={project} editProjectTeam={editProjectTeam} deleteProjectTeamMember={removeProjectTeamMember} />
              </ConfirmationDialog>
            </Grid>
          </Grid>
          <Table size="small">
            <TableBody>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>{project?.name}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>SF ID</StyledTableCell>
                <StyledTableCell>{project?.sf_id}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Start Date</StyledTableCell>
                <StyledTableCell>{StandardDate(project?.start_date)}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>End Date</StyledTableCell>
                <StyledTableCell>{StandardDate(project?.end_date)}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Capex Code</StyledTableCell>
                <StyledTableCell>{project?.capex_code}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Region</StyledTableCell>
                <StyledTableCell>{project?.region}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Project Manager</StyledTableCell>
                <StyledTableCell>
                  <Paper
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      p: 0.5,
                      m: 0,
                      backgroundColor: CARD_BG_COLOR,
                    }}
                    component="ul"
                  >
                    {projectManagerIds?.map((teamMemberId: string) => {
                      return (
                        <ListItem key={teamMemberId}>
                          <Chip
                            label={usersMap.get(teamMemberId)?.name}
                          />
                        </ListItem>
                      );
                    })}
                  </Paper>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Project Team</StyledTableCell>
                <StyledTableCell>
                  <Paper
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      p: 0.5,
                      m: 0,
                      backgroundColor: CARD_BG_COLOR,
                    }}
                    component="ul"
                  >
                    {projectMemberIds?.map((teamMemberId: string) => {
                      return (
                        <ListItem key={teamMemberId}>
                          <Chip
                            label={usersMap.get(teamMemberId)?.name}
                          />
                        </ListItem>
                      );
                    })}
                  </Paper>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>{project?.status}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};
