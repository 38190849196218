import { useState } from "react";
import { Box, Button, Typography } from "utils/MuiWrapper/components";
import { ProjectLocations } from "./ProjectLocations";
import { DataCenters } from "./DataCenters";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useParams } from "react-router-dom";
import { useProject } from "api/project";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { useGetUser } from "api/auth";
import { Action, Roles } from "utils/enums";

export const TemplateScalerPage = () => {
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const { projectId } = useParams();
  const { data: project } = useProject(projectId);
  const { isProjectMember } = useProjectTeamManagement(project, userId);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <Box sx={{ margin: 7 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h1">Template Scaler</Typography>
        {(isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.scaler, Action.Edit) && (
          <Button size="large" onClick={handleOpen}>
            Manage Locations
          </Button>
        )}
      </Box>
      <ProjectLocations open={open} setOpen={setOpen} />
      <DataCenters />
    </Box>
  );
};
