import React, { useState, useEffect } from "react";
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  TextField,
  Typography,
  styled,
  FormHelperText,
  FormControl,
} from "utils/MuiWrapper/components";
import TemplateUserViewField from "./TemplateUserViewField";
import { CopyAllTwoTone, DeleteTwoTone } from "utils/MuiWrapper/icons";
import { CustomSelect } from "../shared/controls";
import {
  createProjectTemplateSectionField,
  updateProjectTemplateSection,
  cloneProjectTemplateSection,
  deleteProjectTemplateSection,
  ProjectTemplateSectionData,
} from "api/project";
import { DATACENTER_TEMPLATE, HELPER_TEXT, NEW_CATEGORY } from "utils/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGenerateBOMState } from "./GenerateBOM";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { ProjectTemplateSectionField } from "types/ProjectTemplateSectionField";

const MultiplierSelect = styled(Select)(() => ({
  ".MuiSelect-select.MuiSelect-outlined": {
    padding: "4px 5px",
    fontSize: "13px",
  },
}));

const multiplierList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function TemplateUserViewSection({
  projectId,
  projectTemplateId,
  projectSection,
  canDeleteCollections,
  iteration,
  canCreatePermission,
  canEditPermission,
  canDeletePermission,
}) {
  const fixedFields = projectSection.project_template_section_fields.fixed_fields;
  const nonFixedFields = projectSection.project_template_section_fields.non_fixed_fields;
  const [multiplierVal, setMultiplierVal] = useState(projectSection.multiplier);
  const [numCollectionSections, setNumCollectionSections] = useState(0);
  const queryClient = useQueryClient();
  const [altName, setAltName] = useState(projectSection.name || "");
  const { hasError } = useGenerateBOMState((state) => state);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  useEffect(() => {
    if (projectSection.type === "collection") {
      setNumCollectionSections(
        parseInt(projectSection?.project_template_section_fields?.fixed_fields?.length) +
          parseInt(projectSection?.project_template_section_fields?.non_fixed_fields?.length)
      );
    }
  }, [projectSection]);

  useEffect(() => {
    let ordinal = (fixedFields.length as number) + 1;
    for (const field of projectSection.template_section_fields) {
      if (field.fixed && !projectSection.fixed_field_ids.includes(field.id)) {
        const data = {
          template_section_field_id: field.id,
          ordinal: ordinal,
          fixed: true,
        };
        createProjectTemplateSectionField(projectId, projectTemplateId, projectSection.id, data);
        queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
        ordinal += 1;
      }
    }
  }, [projectSection.project_template_section_fields.non_fixed_fields]);

  useEffect(() => {
    // TODO: figure out why this query is stale and needs to be invalidated to show fixed fields
    queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
  }, []);

  const { mutate: updateSection } = useMutation({
    mutationFn: (data: ProjectTemplateSectionData) =>
      updateProjectTemplateSection(projectId, projectTemplateId, projectSection.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const updateMultiplier = (event) => {
    const multiplier = event.target.value;
    updateSection({ multiplier: multiplier });
    setMultiplierVal(multiplier);
  };

  const getTemplateUserViewField = (field) => {
    return (
      <TemplateUserViewField
        projectId={projectId}
        projectTemplateId={projectTemplateId}
        projectTemplateSectionId={projectSection.id}
        projectTemplateSectionFieldId={field.id}
        projectTemplateSectionField={field}
        templateSectionFields={projectSection.template_section_fields}
        sectionType={projectSection?.type}
        canEditPermission={canEditPermission}
        canDeletePermission={canDeletePermission}
      />
    );
  };

  const handleNewCategory = async (event) => {
    const newFieldId = event.target.value;
    if (newFieldId !== NEW_CATEGORY) {
      const data: ProjectTemplateSectionField = {
        template_section_field_id: newFieldId,
        ordinal: (nonFixedFields.length as number) + 1,
        fixed: false,
      };
      if (projectSection?.type === DATACENTER_TEMPLATE) data.use_scaler_auto_value = true;
      await createProjectTemplateSectionField(projectId, projectTemplateId, projectSection.id, data);
      queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
    }
  };

  const handleCloneSection = async () => {
    await cloneProjectTemplateSection(projectId, projectTemplateId, projectSection.id);
    queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
  };

  const handleAltNameChange = (event) => setAltName(event.target.value);

  const handleSaveAltName = () => {
    if ((projectSection.name || "") !== altName) updateSection({ name: altName });
  };

  const { mutate: deleteSection } = useMutation({
    mutationFn: () => deleteProjectTemplateSection(projectId, projectTemplateId, projectSection.id),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const hasCategoryError = () => {
    return (
      hasError &&
      numCollectionSections === 0 &&
      projectSection?.type === "collection" &&
      projectSection?.template_section?.ordinal === 1
    );
  };

  return (
    <>
      <Paper
        key={projectSection.id}
        elevation={1}
        sx={{
          width: "100%",
          my: "5px",
          border: "1px solid #a9a9a9",
          padding: "15px 17px",
        }}
      >
        <Grid container sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <Grid item xs={projectSection.template_section.clonable ? 2 : 3}>
            <Typography variant="h6" sx={{ fontSize: "13px" }}>
              {projectSection.template_section.name}
            </Typography>
          </Grid>
          {projectSection.template_section.clonable && (
            <Grid item xs={3}>
              <TextField
                value={altName}
                onChange={handleAltNameChange}
                onBlur={handleSaveAltName}
                placeholder="Alt. Name"
                disabled={!canEditPermission}
              ></TextField>
            </Grid>
          )}
          <Grid item xs={projectSection.template_section.clonable ? 7 : 9}>
            <Grid container justifyContent="right">
              {projectSection.template_section.multipliable && (
                <>
                  <Grid item xs={1} sx={{ ml: "auto" }}>
                    <MultiplierSelect onChange={updateMultiplier} fullWidth value={multiplierVal} disabled={!canEditPermission}>
                      {multiplierList.map((multiplier, i) => (
                        <MenuItem key={i} sx={{ fontSize: "13px" }} value={multiplier}>
                          {multiplier}
                        </MenuItem>
                      ))}
                    </MultiplierSelect>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontSize: "13px", ml: "10px" }}>Multiplier</Typography>
                  </Grid>
                </>
              )}
              {projectSection.template_section.clonable && (
                <>
                  <Grid item sx={{ ml: "30px" }}>
                    <IconButton disabled={!canDeleteCollections && canDeletePermission} onClick={() => deleteSection()}>
                      <Tooltip title="Delete Section">
                        <DeleteTwoTone />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  <Grid item sx={{ ml: "10px" }}>
                    <IconButton onClick={handleCloneSection}>
                      <Tooltip title="Clone Section">
                        <CopyAllTwoTone />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            width: "100%",
            borderTop: "2px solid #63666A",
            marginBottom: "15px",
          }}
        ></Divider>
        {fixedFields.map((field) => (
          <Grid key={field.id} container columnSpacing={3}>
            {getTemplateUserViewField(field)}
          </Grid>
        ))}
        {nonFixedFields.map((field) => (
          <Grid key={field.id} container columnSpacing={3}>
            {getTemplateUserViewField(field)}
          </Grid>
        ))}
        {canCreatePermission && (
          <Grid container columnSpacing={3} sx={{ marginTop: "35px" }}>
            <Grid item xs={3}>
              <FormControl fullWidth error={hasCategoryError()}>
                <CustomSelect onChange={handleNewCategory} value={NEW_CATEGORY}>
                  <MenuItem key="new-category" sx={{ fontSize: "13px" }} value="new-category">
                    New Category {projectSection?.template_section?.ordinal === 1 ? "*" : ""}
                  </MenuItem>
                  {projectSection.template_section_fields.map((field) => (
                    <MenuItem key={field.id} sx={{ fontSize: "13px" }} value={field.id}>
                      {field.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {hasCategoryError() && <FormHelperText>{HELPER_TEXT.CATEGORY}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}
