import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import MUIDataTable from "mui-datatables";

const columns = [
  {
    name: "id",
    label: "id",
    options: {
      display: false,
      viewColumns: false,
    },
  },
  {
    name: "partNumber",
    label: "Part No.",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "partDescription",
    label: "Part Description",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "manufacturer",
    label: "Manufacturer",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "price",
    label: "Price",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        const pf = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD", // TODO: Update this, when pricing is returned by region
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return pf.format(value);
      },
    },
  },
  {
    name: "discount",
    label: "Discount",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        const df = new Intl.NumberFormat("en-US", {
          style: "percent",
          minimumFractionDigits: 2,
        });

        return df.format(Number(value / 100));
      },
    },
  },
  {
    name: "acConnections",
    label: "AC Connections",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "powerUsage",
    label: "Power Usage",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "rackUnits",
    label: "Rack Units",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export type ComponentTableData = {
  options: object;
  data: [];
};

type ComponentPartListTypes = {
  isOpen: boolean;
  handleClose: () => void;
  tableData: ComponentTableData;
  showQuantity?: boolean;
  quantity?: number | "";
  setQuantity?: React.Dispatch<React.SetStateAction<number | "">>;
};

export const ComponentPartList = ({
  isOpen,
  handleClose,
  tableData,
  showQuantity,
  quantity,
  setQuantity,
}: ComponentPartListTypes) => {
  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? "" : parseInt(event.target.value);
    if (setQuantity) {
      setQuantity(value);
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" PaperProps={{ sx: { overflowY: "hidden" } }}>
      <DialogContent dividers sx={{ overflowY: "hidden" }}>
        <DialogTitle>Select Component</DialogTitle>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogContent>
      <DialogContent>
        {showQuantity && (
          <TextField required label="Quantity" type="number" value={quantity} onChange={handleQuantityChange} />
        )}
        <MUIDataTable data={tableData.data} columns={columns} options={tableData.options} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
