import * as React from "react";
import { useState } from "react";
import { alpha, Button, styled, InputBase, Stack } from "utils/MuiWrapper/components";
import { Add as AddIcon, Search as SearchIcon } from "utils/MuiWrapper/icons";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchBar = ({ delegateSearch, handleShow, typography, noCreate = false }) => {
  return (
    <>
      <Stack
        direction="row"
        sx={{ flexGrow: 1, flexDirection: "row-reverse", borderWidth: "10px", borderColor: "white" }}
        marginBottom={2}
      >
        {noCreate ? null : (
          <Button sx={{ marginLeft: 2 }} variant="contained" endIcon={<AddIcon />} onClick={handleShow}>
            Create
          </Button>
        )}
        <SearchInput delegateSearch={delegateSearch} />
      </Stack>
    </>
  );
};

export const SearchInput = ({ delegateSearch }) => {
  const [searchText, setSearchText] = useState("");

  const handleKeyPress = (e) => {
    const key = e.key;
    if (key === "Enter") {
      delegateSearch(searchText);
      return;
    }
  };

  const handleChange = (e) => setSearchText(e?.target?.value);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        sx={{ width: "15rem" }}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
      />
    </Search>
  );
};

export default SearchBar;
