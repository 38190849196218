import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  Paper,
  styled,
  alpha,
  Toolbar,
  checkboxClasses,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  visuallyHidden,
} from "utils/MuiWrapper/components";
import { Delete as DeleteIcon, FilterList as FilterListIcon } from "utils/MuiWrapper/icons";
import { useParams } from "react-router-dom";
import { Contact } from "types/Contact";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)({
  color: "white",

  "&.MuiTableSortLabel-root": {
    color: "white",
  },
  "&.MuiTableSortLabel-root:hover": {
    color: "white",
  },
  "&.Mui-active": {
    color: "white",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type ComparisonResult = -1 | 0 | 1;

function descendingComparator<T extends object>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T extends object>(order: "desc" | "asc", orderBy: any) {
  return order === "desc"
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy) as ComparisonResult;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T extends object>(array: T[], comparator: (a: T, b: T) => -1 | 0 | 1) {
  const stabilizedThis: Array<[T, number]> = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "address_line1",
    right: false,
    disablePadding: true,
    label: "Address Line 1",
  },
  {
    id: "address_line2",
    right: false,
    disablePadding: false,
    label: "Address Line 2",
  },
  {
    id: "city",
    right: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "state_region",
    right: false,
    disablePadding: false,
    label: "State Region",
  },
  {
    id: "country",
    right: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "postcode",
    right: false,
    disablePadding: false,
    label: "Postal Code",
  },
  {
    id: "phone_number",
    right: false,
    disablePadding: false,
    label: "Phone Number",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  }: {
    onSelectAllClick: () => void;
    order: "asc" | "desc";
    orderBy: string;
    numSelected: number;
    rowCount: number;
    onRequestSort: (event: React.MouseEvent, property: string) => void;
  } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent) => {
    return onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: "white",
              },
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(["asc", "desc"]).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

const EnhancedTableToolbar = (props) => {
  const { numSelected }: { numSelected: number } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: "1 1 100%" }} variant="body1" id="tableTitle" component="div">
          Contact addresses
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={props.deleteSelectedContactAddresses}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export default function CreateContact() {
  const [cookies] = useCookies();
  const { contactId } = useParams();
  const [contact, setContact] = React.useState<Contact | undefined>(undefined);
  const [displayAddAddressForm, setDisplayAddAddressForm] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const toggleAddAddressForm = () => {
    if (displayAddAddressForm) {
      setDisplayAddAddressForm(false);
      setErrorMessage("");
    } else {
      setDisplayAddAddressForm(true);
    }
  };

  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (_event, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && contact) {
      const newSelected = contact.address.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (contact?.address?.length || 0)) : 0;

  const [errorMessage, setErrorMessage] = React.useState("");

  const _getContact = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/api/contacts/${contactId}`, config);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getContact = async () => {
    const _contact = await _getContact();
    setContact(_contact);
    setFirstName(_contact.first_name);
    setLastName(_contact.last_name);

    return _contact;
  };

  // Update contact
  const _updateContact = async (payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/api/contacts/${contactId}`,
        payload,
        config
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateContact = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
    };
    const updatedContactAddress = await _updateContact(payload);
    setContact(updatedContactAddress);
    setFirstName(updatedContactAddress.first_name);
    setLastName(updatedContactAddress.last_name);
    return updatedContactAddress;
  };

  // Create contact address
  const _createContactAddress = async (payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/api/contacts/${contactId}/addresses`,
        payload,
        config
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createContactAddress = async (payload) => {
    const contactAddress = await _createContactAddress(payload);
    return contactAddress;
  };

  const addAddress = async (payload) => {
    let validFormData = true;
    /* Basic form validations. */
    for (const [key, value] of Object.entries(payload)) {
      if (typeof value === "string" && !value.length) {
        console.log(`${key} cannot be empty!`);
        validFormData = false;
        setErrorMessage(`${key} cannot be empty!`);
      }
    }
    if (validFormData) {
      const response = await createContactAddress(payload);
      if (response) {
        toggleAddAddressForm();
        setContact(response);
      }
    }
  };

  const _addAddress = (event) => {
    event.preventDefault();
    setErrorMessage("");
    const data = new FormData(event.currentTarget);
    const payload = {
      address_line1: data.get("addressLine1"),
      address_line2: data.get("addressLine2"),
      city: data.get("city"),
      state_region: data.get("stateRegion"),
      country: data.get("country"),
      postcode: data.get("postCode"),
      phone_number: data.get("phoneNumber"),
    };
    addAddress(payload);
  };

  const changeFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const changeLastName = (event) => {
    setLastName(event.target.value);
  };

  const _deleteContactAddress = async (contactAddressId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/api/contacts/${contactId}/addresses/${contactAddressId}`,
        config
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteContactAddress = async (contactAddressId) => {
    const _contact = await _deleteContactAddress(contactAddressId);
    return _contact;
  };

  const deleteSelectedContactAddresses = async () => {
    if (selected.length) {
      for (let i = 0; i < selected.length; i++) {
        const _contactAddressId = selected[i];
        await deleteContactAddress(_contactAddressId);
      }
    }
    getContact();
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, mt: 2, padding: 2 }}>
        <Box component="form" noValidate onSubmit={_addAddress} sx={{}}>
          <Typography variant="h6" gutterBottom>
            Edit Contact
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="firstName"
                label="First name"
                fullWidth
                value={firstName}
                autoComplete="off"
                onChange={changeFirstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="lastName"
                label="Last name"
                fullWidth
                value={lastName}
                autoComplete="off"
                onChange={changeLastName}
              />
            </Grid>

            {displayAddAddressForm && (
              <React.Fragment>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="addressLine1"
                    id="addressLine1"
                    label="Address Line 1"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="addressLine2"
                    id="addressLine2"
                    label="Address Line 2"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField required name="city" id="city" label="City" fullWidth autoComplete="off" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="stateRegion"
                    id="stateRegion"
                    label="State / Region"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField required name="country" id="country" label="Country" fullWidth autoComplete="off" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField required name="postCode" id="postCode" label="Postal Code" fullWidth autoComplete="off" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone Number"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12}>
              {displayAddAddressForm ? (
                <React.Fragment>
                  <Button variant="contained" color="error" onClick={toggleAddAddressForm}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="success" sx={{ ml: displayAddAddressForm ? 2 : 0 }}>
                    Save Address
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={toggleAddAddressForm}
                  sx={{ ml: displayAddAddressForm ? 2 : 0 }}
                >
                  Add Address
                </Button>
              )}
            </Grid>

            {contact && contact.address.length > 0 && (
              <Grid item xs={12}>
                <EnhancedTableToolbar
                  numSelected={selected.length}
                  deleteSelectedContactAddresses={deleteSelectedContactAddresses}
                />
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={contact.address.length}
                    />
                    <TableBody>
                      {stableSort(contact.address, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <StyledTableRow
                              hover
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <StyledTableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.address_line1}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.address_line2}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.city}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.state_region}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.country}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.postcode}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                {row.phone_number}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <StyledTableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <StyledTableCell colSpan={6} />
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={contact.address.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            )}
            {errorMessage && (
              <Grid item xs={12}>
                <Typography color="red" variant="subtitle1" component="div">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="contained" onClick={updateContact}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
