import create from "zustand";

export const useDetailPaneStateStore = create<DetailPaneState>((set) => ({
    type: "Project",
    id: "",
    updateType: (type) => set(() => ({ type: type })),
    updateId: (id) => set(() => ({ id: id })),
}));

interface DetailPaneState {
    type: string,
    id: string,
    updateType: (type: string) => void,
    updateId: (id: string) => void
}