import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
} from "utils/MuiWrapper/components";
import { useScalerStore } from "./ScalerState";
import { useCallback, useEffect, useState } from "react";
import { BOM } from "types/BOM";
import { TEXT_FONT_SIZE } from "utils/constants";
import { ScalerLocation, ScalerMapping } from "types/TemplateScaler";
import { createScalerMapping, patchScalerMapping, useProjectLocations } from "api/project-scaler";
import { useParams } from "react-router-dom";
import { Close } from "utils/MuiWrapper/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { Select } from "@mui/material";

export const AddScaler = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { isAddOpen, setIsAddOpen, standardBOMs, template, editScaler } = useScalerStore((state) => state);
  const { data: locations } = useProjectLocations(projectId);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const [selectedBOMId, setSelectedBOMId] = useState("");
  const [scalerQty, setScalerQty] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");

  useEffect(() => {
    if (editScaler) {
      setSelectedBOMId(editScaler.template_bom_id || "");
      setScalerQty(editScaler?.scaler_qty?.toString() || "");
      setSelectedLocationId(editScaler.project_location_id || "");
    }
  }, [editScaler]);

  const { mutate: saveScalerMapping } = useMutation({
    mutationFn: (data: ScalerMapping) => createScalerMapping(projectId || "", data),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-dc-scalers", projectId]);
      handleClose();
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const { mutate: editScalerMapping } = useMutation({
    mutationFn: (data: ScalerMapping) => patchScalerMapping(projectId || "", editScaler?.id || "", data),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-dc-scalers", projectId]);
      handleClose();
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const handleSave = useCallback(() => {
    if (!template || !selectedLocationId || !selectedBOMId || !scalerQty) return;
    const payload = {
      datacenter_id: template.template_id,
      project_location_id: selectedLocationId,
      template_bom_id: selectedBOMId,
      scaler_qty: parseInt(scalerQty),
      project_template_id: template.id,
    };

    editScaler?.id ? editScalerMapping(payload) : saveScalerMapping(payload);
  }, [template, selectedLocationId, selectedBOMId, scalerQty]);

  const handleClose = () => setIsAddOpen(false);

  const handleBOMChanged = (event) => {
    setSelectedBOMId(event.target.value);
  };

  const handleQtyChanged = (event) => {
    setScalerQty(event.target.value);
  };

  const handleLocationChanged = (event) => {
    setSelectedLocationId(event.target.value);
  };

  return (
    <Dialog open={isAddOpen} onClose={handleClose}>
      <DialogContent dividers>
        <DialogTitle>Link BOM</DialogTitle>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogContent>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>BOM</InputLabel>
          <Select value={selectedBOMId} onChange={handleBOMChanged}>
            {standardBOMs?.map((bom: BOM, index) => (
              <MenuItem key={index} value={bom.id} sx={{ fontSize: TEXT_FONT_SIZE }}>
                {bom.name} - {bom.version || ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Scaler Quantity"
          value={scalerQty}
          type="number"
          onChange={handleQtyChanged}
          sx={{ marginBottom: 3, marginTop: 3 }}
        />
        <FormControl fullWidth>
          <InputLabel>Location</InputLabel>
          <Select value={selectedLocationId} onChange={handleLocationChanged}>
            {locations?.results?.map((location: ScalerLocation, index: number) => (
              <MenuItem key={index} value={location.id} sx={{ fontSize: TEXT_FONT_SIZE }}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          sx={{
            marginLeft: "auto",
          }}
          color="primary"
          onClick={handleSave}
          disabled={!template || !selectedLocationId || !selectedBOMId || !scalerQty || parseInt(scalerQty) < 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
