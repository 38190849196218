import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "utils/MuiWrapper/components";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "components/BOMView/BOMGrid.css";
import { getRequest } from "http/axiosClient";
import { APPROVED_PROJECT, BOM_COMMENT_REQUIRED, BOM_SOURCES, CHANGE_COMPONENT_FIELDS } from "utils/constants";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useMutation } from "@tanstack/react-query";
import { BomItemType, updateBOMItemById } from "api/bom";
import { AddComponent } from "components/ComponentPartList/AddComponent";
import { useBOMViewState } from "./BOMView";
import { CheckboxHeader, EditEndColDefs, InitialColumnDefs, StatusColDef } from "./ColumnDefinitions";
import CommentConfirmation from "./CommentConfirmation"

export const BOMGrid = ({ projectStatus }) => {
  const { bomId, numComponentsAdded, projectTemplateId } = useParams();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { setSelectedRowIDs, isSelectAllChecked, setIsSelectAllChecked, setSelectedRowsData } = useBOMViewState(
    (state) => state
  );
  const [totalResults, setTotalResults] = useState(0);
  const [showComponents, setShowComponents] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedItemStatus, setSelectedItemStatus] = useState("");
  const [grandTotals, setGrandTotals] = useState({ total_cost: 0, total_monthly: 0 });
  const gridRef = useRef<any>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "84vh" }), []);
  const [bomSource, setBomSource] = useState(BOM_SOURCES.CACHE);
  const isBOMEditable = projectStatus === APPROVED_PROJECT && bomSource !== BOM_SOURCES.CACHE && !projectTemplateId;
  const [open, setOpen] = useState(false);
  const [stashedParams, setStashedParams] = useState<any>();

  useEffect(() => {
    if (isSelectAllChecked) setIsSelectAllChecked(false);
  }, [bomId]);

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      try {
        gridRef?.current?.api.showLoadingOverlay();
        const res = await getRequest(`/v1/api/bom/${bomId}/item`, {
          limit: 100,
          offset: params.startRow,
          order_by: "manufacturer",
        });
        const { results, total_results, totals, source } = res.data;
        setBomSource(source);
        setGrandTotals({ total_cost: totals.total_cost, total_monthly: totals.total_monthly_cost });
        setTotalResults(total_results);
        params.successCallback(results, total_results);
      } catch (error: any) {
        console.log("get data error: ", error);
        setAlert({
          type: "error",
          message: `Could not get BOM data. ${error?.response?.data?.detail || ""}`,
        });
      } finally {
        gridRef?.current?.api?.hideOverlay();
      }
    },
  };

  useEffect(() => {
    gridRef?.current?.api?.setDatasource(dataSource);
  }, [bomId, numComponentsAdded]);

  const { mutate: updateBOMItem } = useMutation({
    mutationFn: (data: BomItemType) => updateBOMItemById(data),
    onSuccess: () => {
      //Refresh data
      gridRef?.current?.api?.setDatasource(dataSource);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
      //Refresh data so erroneous changes don't remain
      gridRef?.current?.api?.setDatasource(dataSource);
    },
  });

  const onSelectAll = (event) => {
    setIsSelectAllChecked(event.target.checked);

    gridRef.current.api.forEachNode(function (node) {
      // Setting 3rd arg to true so that UI won't lock up when many rows displayed
      node.setSelected(event.target.checked, false, true);
    });
    // Triggering selection changed after looping through each node so UI won't lock up
    handleSelectionChanged(gridRef.current);
  };

  const getCheckBoxColumn = () => {
    return {
      checkboxSelection: true,
      minWidth: 55,
      headerComponent: CheckboxHeader,
      headerComponentParams: {
        onSelectAll,
        isSelectAllChecked,
      },
    };
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const onGridReady = useCallback(
    (params) => {
      params.api.setDatasource(dataSource);
    },
    [bomId, totalResults]
  );

 const onCellValueChanged = (params, comment?: string) => {
    const body = { [params?.colDef?.field]: params?.newValue };
    if ("qty" in body) body["qty"] = Number(body["qty"]); 
    
    if(comment) {
      body["comments"] = comment;
    }
    updateBOMItem({ bomId: bomId || "", bomItemId: params?.data?.id, body });
    setStashedParams(undefined);
  }

  const handleCellDblClicked = (colAPI) => {
    //IMPORTANT: Only approved bom can be edited
    if (!isBOMEditable) return;

    if (CHANGE_COMPONENT_FIELDS[colAPI.colDef.field]) {
      setSelectedItemId(colAPI?.data?.id);
      setSelectedItemStatus(colAPI?.data?.status);
      setShowComponents(true);
    }
  };

  const handleSelectionChanged = (gridAPI) => {
    const selectedIDs = gridAPI?.api?.getSelectedRows().map((row) => row.id);
    setSelectedRowIDs(selectedIDs);

    const selectedRows = gridAPI?.api?.getSelectedRows().map((row) => row);
    setSelectedRowsData(selectedRows);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);
    if (newValue && newValue.length > 0 && stashedParams) {
      onCellValueChanged(stashedParams, newValue);
    }
    else if (stashedParams) {
      setAlert({
        type: "error",
        message: `The BOM item could not be updated. A comment must be provided for these changes.`,
      });
      setStashedParams(undefined);
    }
  };

  const handleOpen = (params) => {
    if(BOM_COMMENT_REQUIRED.includes(params.data.status)) {
      setStashedParams(params);
      setOpen(true);
    }
    else {
      onCellValueChanged(params);
    }
  }

  return (
    <>
      {selectedItemId && (
        <AddComponent action="swap" itemStatus={selectedItemStatus} itemId={selectedItemId} isOpen={showComponents} setIsOpen={setShowComponents} />
      )}
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact
            ref={gridRef}
            onCellValueChanged={handleOpen}
            onCellDoubleClicked={handleCellDblClicked}
            columnDefs={
              bomSource === BOM_SOURCES.CACHE
                ? InitialColumnDefs(isBOMEditable)
                : isBOMEditable
                ? [
                    getCheckBoxColumn(),
                    ...StatusColDef(isBOMEditable),
                    ...InitialColumnDefs(isBOMEditable),
                    ...EditEndColDefs(isBOMEditable),
                  ]
                : [...InitialColumnDefs(isBOMEditable)]
            }
            defaultColDef={defaultColDef}
            onSelectionChanged={handleSelectionChanged}
            rowBuffer={0}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            rowModelType={"infinite"}
            cacheBlockSize={100}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={totalResults}
            maxBlocksInCache={10}
            pinnedTopRowData={[grandTotals]}
            onGridReady={onGridReady}
          ></AgGridReact>
          <Typography variant="body2" align="right">
            Total results: {totalResults}
          </Typography>
        </div>
      </div>
      <CommentConfirmation
       id="comment-dialog"
       keepMounted
       open={open}
       onClose={handleClose}/>
    </>
  );
};
