import axios from "axios";
import * as React from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import {
  styled,
  alpha,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  checkboxClasses,
  IconButton,
  Tooltip,
  visuallyHidden,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "utils/MuiWrapper/components";
import { Delete as DeleteIcon, FilterList as FilterListIcon } from "utils/MuiWrapper/icons";
import { Checkbox } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)({
  color: "white",

  "&.MuiTableSortLabel-root": {
    color: "white",
  },
  "&.MuiTableSortLabel-root:hover": {
    color: "white",
  },
  "&.Mui-active": {
    color: "white",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
  },
});

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const headCells = [
  {
    id: "name",
    right: false,
    disablePadding: true,
    label: "Name",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: "white",
              },
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props: any) => {
  const { numSelected, confirmDeleteSelectedManufacturers } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          Manufacturer List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={confirmDeleteSelectedManufacturers}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  confirmDeleteSelectedManufacturers: PropTypes.func.isRequired
};

function ConfirmDialog(props) {
  return (
    <Dialog open={props.showConfirmDialog} onClose={props.handleConfirmDialogClose}>
      <DialogContent dividers>
        <DialogTitle>{props.title}</DialogTitle>
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {props.text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleConfirmDialogClose}>Cancel</Button>
        <Button color="error" onClick={props.handleConfirmDelete}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ManufacturersTable({
  manufacturers,
  getManufacturers,
  orderByProperty,
  setOrderByProperty,
  limit,
  setLimit,
  offset,
  setOffset,
  totalResults,
}) {
  const [cookies] = useCookies();
  const [order, setOrder] = React.useState("asc");
  const orderBy = orderByProperty;
  const setOrderBy = setOrderByProperty;
  const page = offset;
  const setPage = setOffset;
  const rowsPerPage = limit;
  const setRowsPerPage = setLimit;

  const [selected, setSelected] = React.useState<any>([]);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const handleConfirmDialogClose = () => setShowConfirmDialog(false);
  const handleConfirmDelete = () => {
    deleteSelectedManufacturers();
  };

  const _deleteManufacturer = async (manufacturerId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/api/manufacturers/${manufacturerId}`,
        config
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteManufacturer = async (manufacturerId) => {
    const manufacturer = await _deleteManufacturer(manufacturerId);
    return manufacturer;
  };

  const confirmDeleteSelectedManufacturers = () => {
    setShowConfirmDialog(true);
  };

  const deleteSelectedManufacturers = async () => {
    if (selected.length) {
      for (let i = 0; i < selected.length; i++) {
        const _manufacturerId = selected[i];
        await deleteManufacturer(_manufacturerId);
      }
    }
    getManufacturers();
    setShowConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    getManufacturers(property, rowsPerPage, page);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = manufacturers.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(parseInt(selectedIndex) + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getManufacturers(orderBy, rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getManufacturers(orderBy, parseInt(event.target.value, 10), 0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <ConfirmDialog
        title="Confirm"
        text="Are you sure you want to delete these manufacturers and all of their associated data?"
        showConfirmDialog={showConfirmDialog}
        handleConfirmDialogClose={handleConfirmDialogClose}
        handleConfirmDelete={handleConfirmDelete}
      />

      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          confirmDeleteSelectedManufacturers={confirmDeleteSelectedManufacturers}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={manufacturers.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 contacts.slice().sort(getComparator(order, orderBy)) */}
              {manufacturers &&
                manufacturers
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                          <Link href={`/manufacturers/${row.id}`}>{row.name}</Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalResults}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
