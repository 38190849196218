import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useBOMViewState } from "components/BOMView/BOMView";
import {
  Dialog,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  Box
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import { getBOMLogs } from "api/bom";
import { StandardDate } from "utils/dates";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

export const AuditLog = ({ isOpen, setIsOpen }) => {
  const { bomId } = useParams();
  const { selectedRowIds } = useBOMViewState((state) => state);
  const { data: BOMLogData } = getBOMLogs(bomId, selectedRowIds.length > 0 ? selectedRowIds[0] : "");
  const queryClient = useQueryClient();
  const columns: ColDef[] = [
    { field: 'bom_field', headerName: 'Field', minWidth: 150, sortable: true,},
    { field: 'field_value', headerName: 'Value', minWidth: 150, sortable: true,},
    { field: 'created_by', headerName: 'User', minWidth: 150, sortable: true,},
    { field: 'created_at', headerName: 'Date', minWidth: 200, 
    valueFormatter: dateFormatter, sortable: true, sort: "desc"},
  ];
  const [gridData, setGridData] = useState<any[]>([]);

  function dateFormatter(params) {
    const dateAsString = params.data.created_at;
    const formattedString = StandardDate(dateAsString);
    return formattedString ? formattedString : "";
  }

  useEffect(() => {
    queryClient.invalidateQueries(["bom-logs", selectedRowIds[0]]);
  }, [isOpen]);

  useEffect(() => {
    if (BOMLogData && BOMLogData.length > 0) {
      const dates = BOMLogData.map(x => {
        return new Date(x.created_at);
      });
      const minDate = new Date(Math.min.apply(null, dates));
      setGridData(BOMLogData.filter(x => new Date(x.created_at).getTime() !== minDate.getTime()));
    }
  }, [BOMLogData]);

  const handleClose = () => setIsOpen(false);

  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth="md">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 18 }}>Audit Log</Typography>
          <IconButton edge="start" onClick={handleCancel}>
            <Close />
          </IconButton>
        </Toolbar>
        <Box sx={{ width: "63em", height: "60em" }} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact
            rowData={gridData}
            columnDefs={columns}
          ></AgGridReact>
        </Box>
        <Divider />
      </Dialog>
    </>
  );
};
