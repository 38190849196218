import React from "react";
import { useState, useEffect } from "react";
import SearchBar from "../../shared/SearchBar";
import ModalTable from "./ModalTable";
import { fetchProjects } from "api/project";
import { Project } from "types/Project";

export default function ModalSelect({ selectProject }) {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [projects, setProjects] = useState<Project[]>([]);
  const [totalResults, setTotalResults] = useState(0);

  const getProjects = async (_limit = null, _offset = null) => {
    const queryLimit = _limit ?? limit;
    const queryOffset = (_offset ?? offset) * queryLimit;
    const queryParams = {
      order_by: "updated_at",
      order_direction: "desc",
      limit: queryLimit,
      offset: queryOffset,
    };

    const data = await fetchProjects(queryParams);
    setProjects(data.results);
    setTotalResults(data.total_results);
  };

  useEffect(() => {
    getProjects();
  }, []);

  const searchProjects = async (searchString) => {
    let queryParams;
    if (searchString === "") {
      setLimit(10);
      queryParams = {
        order_by: "updated_at",
        order_direction: "desc",
        limit: 10,
        offset: 0,
      };
    } else {
      queryParams = {
        quick_search: searchString,
      };
    }

    const data = await fetchProjects(queryParams);

    if (data.results.length < limit) {
      setLimit(data.results.length);
      setTotalResults(data.results.length);
    } else {
      setTotalResults(data.total_results);
    }

    setProjects(data.results);
  };

  return (
    <>
      <SearchBar delegateSearch={searchProjects} handleShow={null} typography={"Projects"} noCreate />

      <ModalTable
        projects={projects}
        getProjects={getProjects}
        orderBy="last_modified"
        favorites={null}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalResults={totalResults}
        rowsPerPageOptions={[10]}
        selectProject={selectProject}
      />
    </>
  );
}
