import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  useTheme,
} from "utils/MuiWrapper/components";
import { Delete as DeleteIcon } from "utils/MuiWrapper/icons";
import React, { useEffect, useState } from "react";
import ColumnFour from "./Iterations/Columns/ColumnFour";
import ColumnOne from "./Iterations/Columns/ColumnOne";
import ColumnThree from "./Iterations/Columns/ColumnThree";
import ColumnTwo from "./Iterations/Columns/ColumnTwo";
import RuleSets from "./Iterations/Rules/RuleSets";
import { fetchFields, updateFieldCollection, deleteFieldCollection } from "api/template";
import { NULL_ADDRESS } from "utils/constants";
import {
  createRule,
  createRuleConditions,
  deleteRuleActionById,
  deleteRuleById,
  deleteRuleConditionsById,
  fetchRulesByTemplateId,
  useFetchRulesByTemplateId,
} from "api/rule";
import { useQueryClient } from "@tanstack/react-query";

const containerBaseHeight = 19.8;

const FieldCollection = ({
  templateId,
  templateIsPublished,
  fieldCollection,
  count,
  getFieldCollections,
  variables,
  templateVariables,
  getSelectedTemplateVariableName,
}) => {
  const queryClient = useQueryClient();
  const [collection, setCollection] = useState(fieldCollection);
  const [isColumnOneSelected, setIsColumnOneSelected] = useState(false);
  const [_isColumnTwoSelected, setIsColumnTwoSelected] = useState(false);
  const [isColumnThreeSelected, setIsColumnThreeSelected] = useState(false);
  const [columnOneFieldId, setColumnOneFieldId] = useState("");
  const [columnTwoFieldId, setColumnTwoFieldId] = useState("");
  const [height, setHeight] = useState(containerBaseHeight);
  const [colOneLength, setColOneLength] = useState(0);
  const [sectionFields, setSectionFields] = useState([]);

  useEffect(() => {
    getFields(templateId, fieldCollection.id);
  }, [templateId, fieldCollection]);

  const { data: rules } = useFetchRulesByTemplateId(templateId, {
    key: "template_section_id",
    value: fieldCollection.id,
  });

  const columnOneSelected = (field, isSelected) => {
    setIsColumnOneSelected(isSelected);
    setColumnOneFieldId(field.id);
    columnTwoSelected("", false);
    if (isColumnThreeSelected) {
      columnThreeSelected(false);
    }
  };

  const columnTwoSelected = (fieldId, isSelected) => {
    setIsColumnTwoSelected(isSelected);
    setColumnTwoFieldId(fieldId);
    if (isColumnThreeSelected) {
      columnThreeSelected(false);
    }
  };
  const columnThreeSelected = (isSelected) => {
    setIsColumnThreeSelected(isSelected);
  };

  const clearColumnOne = () => {
    setIsColumnOneSelected(false);
    setColumnOneFieldId("");
    columnTwoSelected("", false);
  };

  const handleFieldChange = (data) => {
    setCollection({ ...collection, ...data });
  };

  const updateCollection = async (data) => {
    const field = await updateFieldCollection(templateId, fieldCollection.id, data);
    getFieldCollections(templateId);
    return field;
  };

  const deleteCollection = async () => {
    await deleteFieldCollection(templateId, fieldCollection.id);
    getFieldCollections(templateId);
  };

  const deleteRules = async (templateId, fieldCollectionId, columnOneFieldId, columnTwoFieldId) => {
    const data = await fetchRulesByTemplateId(templateId, {
      key: "template_section_id",
      value: fieldCollectionId,
    });
    const filteredRules = data.results.filter((rule) => {
      if (!columnTwoFieldId) {
        return rule.template_section_field_options.col1 == columnOneFieldId;
      } else {
        return (
          rule.template_section_field_options.col1 == columnOneFieldId &&
          rule.template_section_field_options.col2 == columnTwoFieldId
        );
      }
    });
    for (const rule of filteredRules) {
      const rule_actions = rule.rule_actions;
      const rule_conditions = rule.rule_conditions;
      for (const rule_action of rule_actions) {
        await deleteRuleActionById(rule.id, rule_action.id);
      }
      for (const rule_condition of rule_conditions) {
        await deleteRuleConditionsById(rule.id, rule_condition.id);
      }
      await deleteRuleById(rule.id);
    }
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const createDefaultRule = async (columnOneFieldId, columnTwoFieldId, fieldCollectionId) => {
    const ruleData = {
      salience: 1,
      template_section_field_options: {
        col1: columnOneFieldId,
        col2: columnTwoFieldId,
        variable_id: NULL_ADDRESS,
      },
      rule_type: "component",
      template_section_id: fieldCollectionId,
    };
    const rule = await createRule(templateId, ruleData);
    const ruleConditionData = {
      operator: "and",
      rule_conditions: [
        {
          operator: "eq",
          template_section_field_option_id: columnOneFieldId,
          value: columnOneFieldId,
          variable_id: NULL_ADDRESS,
        },
        {
          operator: "eq",
          template_section_field_option_id: columnTwoFieldId,
          value: columnTwoFieldId,
          variable_id: NULL_ADDRESS,
        },
      ],
    };
    await createRuleConditions(rule.id, ruleConditionData);
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const calculateHeight = (column, length) => {
    const lineHeight = 2.8;
    if (column === 1) {
      setColOneLength(length);
      setHeight(containerBaseHeight + (length - 1) * lineHeight);
    } else if (column === 2) {
      const largerLength = length > colOneLength ? length : colOneLength;
      setHeight(containerBaseHeight + (largerLength - 1) * lineHeight);
    }
  };

  const getFields = async (templateId, fieldCollectionId) => {
    const data = await fetchFields(templateId, fieldCollectionId);
    setSectionFields(data.results);
  };

  const colOne = (
    <Box flex="1 0 auto">
      <ColumnOne
        columnOneSelected={columnOneSelected}
        columnOneFieldId={columnOneFieldId}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={fieldCollection.id}
        clearColumnOne={clearColumnOne}
        deleteRules={deleteRules}
        calculateHeight={calculateHeight}
        sectionFields={sectionFields}
        getFields={getFields}
        type="collection"
        selectOptions={[]}
        collectionCount={count as number}
      />
    </Box>
  );

  const colTwo = (
    <Box sx={{flexGrow: 2}}>
      <ColumnTwo
        columnTwoSelected={columnTwoSelected}
        columnOneFieldId={columnOneFieldId}
        columnTwoFieldId={columnTwoFieldId}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={fieldCollection.id}
        deleteRules={deleteRules}
        createDefaultRule={createDefaultRule}
        calculateHeight={calculateHeight}
        collectionCount={count as number}
      />
    </Box>
  );

  const colThree = (
    <Box>
      <ColumnThree
        columnOneFieldId={columnOneFieldId}
        columnOneSelected={columnOneSelected}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={fieldCollection.id}
        sectionFields={sectionFields}
        collectionCount={count as number}
      />
    </Box>
  );
  const colFour = (
    <Box flex={2} sx={{flexGrow: 3}}>
      <ColumnFour
        columnOneFieldId={columnOneFieldId}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={fieldCollection.id}
        columnOneSelected={columnOneSelected}
        sectionFields={sectionFields}
        collectionCount={count as number}
      />
    </Box>
  );

  const theme = useTheme();
  const backgroundColor = theme.palette.grey["300"];
  const rulesBackgroundColor = theme.palette.grey["600"];

  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{
        marginBlock: "2%",
        backgroundColor,
      }}
    >
      <Box sx={{width: "100%"}} p={1}>
        <Box p={2} display="flex" alignItems="center">
          <Box marginLeft="1em" marginRight="1em">Field Collection {(count as number) + 1}</Box>
          {collection?.name === "New Field Collection" ? (
              <TextField
                id="filled-basic"
                hiddenLabel
                size="small"
                autoComplete="off"
                placeholder={collection?.name}
                disabled={templateIsPublished}
                onChange={(e) => {
                  handleFieldChange({ name: e.target.value });
                }}
                onBlur={(e) => {
                  updateCollection({ name: e.target.value });
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            ) : (
              <TextField
                id="filled-basic"
                hiddenLabel
                size="small"
                autoComplete="off"
                value={collection?.name}
                disabled={templateIsPublished}
                onChange={(e) => {
                  handleFieldChange({ name: e.target.value });
                }}
                onBlur={(e) => {
                  updateCollection({ name: e.target.value });
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            )}
          <FormControl
            disabled={templateIsPublished}
            sx={{
              display: "flex",
              flexDirection: "row",
              ml: "auto",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={collection.clonable}
                  onChange={(e) => {
                    updateCollection({ clonable: e.target.checked });
                  }}
                />
              }
              label="Clonable"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={collection.multipliable}
                  onChange={(e) => {
                    updateCollection({ multipliable: e.target.checked });
                  }}
                />
              }
              label="Multiplier"
            />
          </FormControl>
          <IconButton disabled={templateIsPublished} aria-label="delete" onClick={deleteCollection}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box flex={1} paddingLeft={1} sx={{ marginTop: "3%" }}>
          Always
        </Box>
        <Box paddingLeft={1}>Display</Box>
        <Box>
          <Stack direction="row" spacing={0} justifyContent="space-between">
            {colOne}
            {isColumnOneSelected && columnOneFieldId ? <>{colTwo}</> : <Box flex={1} p={2}></Box>}
            {colThree}
            {colFour}
          </Stack>
        </Box>
      </Box>
      <Box
        width="20%"
        marginLeft="1%"
        sx={{
          overflow: "auto",
          height: `${height}em`,
          backgroundColor: rulesBackgroundColor,
        }}
      >
        {rules && (
          <RuleSets
            templateId={templateId}
            templateIsPublished={templateIsPublished}
            fieldCollectionId={fieldCollection.id}
            columnOneFieldId={columnOneFieldId}
            columnTwoFieldId={columnTwoFieldId}
            rules={rules}
            columnOneSelected={columnOneSelected}
            columnTwoSelected={columnTwoSelected}
            sequenceId={1}
          />
        )}
      </Box>
    </Stack>
  );
};

export default FieldCollection;
