import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, FormControl, Chip, Box } from "utils/MuiWrapper/components";
import { Clear } from "utils/MuiWrapper/icons";
import { CustomSelect } from "../shared/controls";
import { updateProjectTemplateSectionField, deleteProjectTemplateSectionField } from "api/project";
import { ACTIVE_BORDER, DATACENTER_TEMPLATE, HELPER_TEXT, ITERATION_TYPE, SELECT_OPTION } from "utils/constants";
import { FormHelperText, IconButton } from "@development-nl/components-library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGenerateBOMState } from "./GenerateBOM";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { Switch, Tooltip } from "@mui/material";
import { ProjectTemplateSectionField } from "types/ProjectTemplateSectionField";

export default function TemplateUserViewField({
  projectId,
  projectTemplateId,
  projectTemplateSectionId,
  projectTemplateSectionField,
  templateSectionFields,
  projectTemplateSectionFieldId,
  sectionType,
  canEditPermission,
  canDeletePermission,
}) {
  const queryClient = useQueryClient();
  const [field, setField] = useState(projectTemplateSectionField);
  const [manualScalerVal, setManualScalerVal] = useState("");
  const [col1Id, setCol1Id] = useState(projectTemplateSectionField.template_section_field_id);
  const [col2Id, setCol2Id] = useState(
    projectTemplateSectionField.template_section_field_option_id
      ? projectTemplateSectionField.template_section_field_option_id
      : SELECT_OPTION
  );
  const [col3Id, setCol3Id] = useState(
    projectTemplateSectionField.selected_value ? projectTemplateSectionField.selected_value : SELECT_OPTION
  );
  const { hasError } = useGenerateBOMState((state) => state);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  useEffect(() => {
    if (projectTemplateSectionField?.selected_value) setManualScalerVal(projectTemplateSectionField?.selected_value);
  }, [projectTemplateSectionField?.selected_value]);

  useEffect(() => {
    setField(projectTemplateSectionField);
  }, [projectTemplateSectionField]);

  const getDisabledInput = () => {
    return <TextField hiddenLabel size="small" autoComplete="off" fullWidth disabled={true} value={field.name} />;
  };

  const getCol1Select = () => {
    return (
      <CustomSelect onChange={handleCol1Change} fullWidth value={col1Id} disabled={!canEditPermission}>
        {templateSectionFields.map((field) => (
          <MenuItem key={field.id} sx={{ fontSize: "13px" }} value={field.id}>
            {field.name}
          </MenuItem>
        ))}
      </CustomSelect>
    );
  };

  const handleCol1Change = async (event) => {
    const fieldId = event.target.value;
    const data: ProjectTemplateSectionField = {
      template_section_field_id: fieldId,
      template_section_field_option_id: null,
      selected_value: null,
    };

    if (sectionType === DATACENTER_TEMPLATE) {
      data.selected_value = manualScalerVal;
      data.use_scaler_auto_value = field.use_scaler_auto_value;
    }
    const res = await updateProjectTemplateSectionField(
      projectId,
      projectTemplateId,
      projectTemplateSectionId,
      field.id,
      data
    );
    setField(res);
    setCol1Id(fieldId);
    setCol2Id(SELECT_OPTION);
  };

  const getCol2Select = () => {
    const hasCol1Error = hasError && col2Id === SELECT_OPTION;
    return (
      <FormControl required fullWidth error={hasCol1Error}>
        <CustomSelect onChange={handleCol2Change} value={col2Id} disabled={!canEditPermission}>
          <MenuItem key={SELECT_OPTION} sx={{ fontSize: "13px" }} value={SELECT_OPTION}>
            Select Option *
          </MenuItem>
          {field &&
            field.options?.map((option) => (
              <MenuItem key={option.id} sx={{ fontSize: "13px" }} value={option.id}>
                {option.option}
              </MenuItem>
            ))}
        </CustomSelect>
        {hasCol1Error && <FormHelperText>{HELPER_TEXT.SELECT}</FormHelperText>}
      </FormControl>
    );
  };

  const handleCol2Change = async (event) => {
    const optionId = event.target.value;
    let value = null;
    if (optionId !== SELECT_OPTION) {
      value = optionId;
    }
    const data: ProjectTemplateSectionField = {
      template_section_field_option_id: value,
      selected_value: col3Id ? col3Id : null,
    };

    if (sectionType === DATACENTER_TEMPLATE) {
      data.selected_value = manualScalerVal;
      data.use_scaler_auto_value = field.use_scaler_auto_value;
      data.template_section_field_id = col1Id;
    }
    const res = await updateProjectTemplateSectionField(
      projectId,
      projectTemplateId,
      projectTemplateSectionId,
      field.id,
      data
    );
    setField(res);
    setCol2Id(optionId);
  };

  const getScalerFields = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", marginTop: -2 }}>
        <Tooltip title={field.use_scaler_auto_value ? "Use manual scaler value" : "Use auto scaler value"}>
          <Switch checked={field.use_scaler_auto_value} onClick={handleToggleAutoScaler} disabled={!canEditPermission} />
        </Tooltip>
        <Tooltip title="Auto scaler value">
          <Chip
            label={field.scaler_auto_value}
            sx={{ border: field.use_scaler_auto_value && canEditPermission ? `1px solid ${ACTIVE_BORDER}` : "inherit" }}
          />
        </Tooltip>

        <TextField
          placeholder="Manual scaler value"
          disabled={field.use_scaler_auto_value || !canEditPermission}
          sx={{
            marginLeft: 3,
            border: field.use_scaler_auto_value ? "inherit" : `1px solid ${ACTIVE_BORDER}`,
            borderRadius: 1,
          }}
          value={manualScalerVal}
          onChange={handleScalerChange}
          type="number"
          onBlur={handleSaveScaler}
          onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
            if (e.key === "Enter") {
              (e.target as HTMLImageElement).blur();
            }
          }}
        />
      </Box>
    );
  };

  const handleToggleAutoScaler = () => {
    const data: ProjectTemplateSectionField = {
      use_scaler_auto_value: !field.use_scaler_auto_value,
      template_section_field_id: col1Id,
    };
    if (!field.use_scaler_auto_value) {
      data.selected_value = null;
      setManualScalerVal("");
    }
    updateField(data);
  };

  const handleScalerChange = (event) => {
    if (parseInt(event.target.value) < 0) return;
    setManualScalerVal(event.target.value);
  };

  const handleSaveScaler = (event) => {
    if (field.use_scaler_auto_value) return;
    updateField({ selected_value: event.target.value });
  };

  const getCol3Select = () => {
    return (
      <>
        {sectionType === DATACENTER_TEMPLATE || sectionType === ITERATION_TYPE ? null : (
          <CustomSelect onChange={handleCol3Change} fullWidth value={col3Id} disabled={!canEditPermission}>
            <MenuItem key={SELECT_OPTION} sx={{ fontSize: "13px" }} value={SELECT_OPTION}>
              Select Option
            </MenuItem>
            {field &&
              field.range.map((option) => (
                <MenuItem key={option} sx={{ fontSize: "13px" }} value={option}>
                  {option}
                </MenuItem>
              ))}
          </CustomSelect>
        )}
      </>
    );
  };

  const handleCol3Change = async (event) => {
    const optionId = event.target.value;
    let value = null;
    if (optionId !== SELECT_OPTION) {
      value = optionId;
    }
    const data = {
      selected_value: value,
    };
    const res = await updateProjectTemplateSectionField(
      projectId,
      projectTemplateId,
      projectTemplateSectionId,
      field.id,
      data
    );
    setField(res);
    setCol3Id(optionId);
  };

  const { mutate: deleteField } = useMutation({
    mutationFn: () =>
      deleteProjectTemplateSectionField(
        projectId,
        projectTemplateId,
        projectTemplateSectionId,
        projectTemplateSectionFieldId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message || "The field could not be deleted.",
      });
    },
  });

  const { mutate: updateField } = useMutation({
    mutationFn: (data: ProjectTemplateSectionField) =>
      updateProjectTemplateSectionField(projectId, projectTemplateId, projectTemplateSectionId, field.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  return (
    <>
      <Grid item xs={3} sx={{ marginBottom: "10px" }}>
        {field.fixed ? getDisabledInput() : getCol1Select()}
      </Grid>
      <Grid item xs={3}>
        {getCol2Select()}
      </Grid>
      <Grid item xs={sectionType === DATACENTER_TEMPLATE ? 3 : 1}>
        {getCol3Select()}
        {sectionType === DATACENTER_TEMPLATE && getScalerFields()}
      </Grid>
      <Grid item xs={sectionType === DATACENTER_TEMPLATE ? 2 : 4}>
        <Tooltip title={field.description}>
          <TextField hiddenLabel size="small" autoComplete="off" fullWidth disabled={true} value={field.description} />
        </Tooltip>
      </Grid>
      {!field.fixed && canDeletePermission && (
        <Grid item xs={1}>
          <IconButton onClick={() => deleteField()}>
            <Clear />
          </IconButton>
        </Grid>
      )}
    </>
  );
}
