import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import SearchBar from "../shared/SearchBar";
import ManufacturersTable from "./ManufacturersTable";
import { getRequest } from "http/componentClient";

const Manufacturers = () => {
  const [cookies] = useCookies();
  const [manufacturers, setManufacturers] = useState([]);

  const [orderByProperty, setOrderByProperty] = useState("name");
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0); // total amount of contacts in set

  const navigate = useNavigate();

  // Fetch manufacturers
  const fetchManufacturers = async (
    searchString = "",
    _orderByProperty = undefined,
    _limit = undefined,
    _offset = 0
  ) => {
    // Hardcoded token and Dev URL to be removed, its for testing purpose only
    let url = `/v1/api/manufacturers?quick_search=${searchString}`;
    if (_orderByProperty) {
      url += `&order_by=${_orderByProperty}`;
    }
    if (_limit) {
      _offset = _offset * limit;
      url += `&limit=${_limit}&offset=${_offset}`;
    }
    const res = await getRequest(url);
    return res.data;
  };

  const getManufacturers = async (_orderByProperty = undefined, _limit = undefined, _offset = undefined) => {
    const args: any = [""];

    args.push(_orderByProperty ?? orderByProperty);
    args.push(_limit ?? limit);
    args.push(_offset ?? offset);

    const data = await fetchManufacturers(...args);
    setManufacturers(data.results);
    setTotalResults(data.total_results);
  };

  // Create contact
  const _createManufacturer = async (payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/v1/api/manufacturers`, payload, config);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createManufacturer = async (payload) => {
    const contact = await _createManufacturer(payload);
    getManufacturers();
    return contact;
  };

  const handleShow = async () => {
    const payload = {
      name: "Name",
    };
    const manufacturer = await createManufacturer(payload);
    navigate(`/manufacturers/${manufacturer.id}`, { state: { manufacturer } });
  };

  const searchManufacturers = async (searchString) => {
    const data = await fetchManufacturers(searchString);
    setManufacturers(data.results);
  };

  useEffect(() => {
    getManufacturers();
  }, []);

  return (
    <>
      <SearchBar delegateSearch={searchManufacturers} handleShow={handleShow} typography={"Manufacturers"} />
      <ManufacturersTable
        manufacturers={manufacturers}
        getManufacturers={getManufacturers}
        orderByProperty={orderByProperty}
        setOrderByProperty={setOrderByProperty}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        totalResults={totalResults}
      />
    </>
  );
};

export default Manufacturers;
