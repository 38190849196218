import { useFetchFieldCollections, useVariablesForTemplate } from "api/template";
import { useEffect, useState } from "react";
import { TemplateTypes } from "types/Template";
import { Select, MenuItem } from "utils/MuiWrapper/components";
import { DATACENTER_TEMPLATE } from "utils/constants";

export const RuleVariableSelect = ({ templateId, fieldCollectionId, value, onSelect }) => {
  const { data: fieldCollections } = useFetchFieldCollections(templateId);
  const [params, setParams] = useState<{ type: TemplateTypes }>({ type: "standard" });
  const templateVariables = useVariablesForTemplate(templateId, params);

  useEffect(() => {
    if (fieldCollections && templateId) {
      const collectionType = fieldCollections?.results?.find((collection) => collection.id === fieldCollectionId)?.type;
      setParams({ type: collectionType === DATACENTER_TEMPLATE ? collectionType : "standard" });
    }
  }, [fieldCollections, templateId]);

  return (
    <Select labelId="rule-variable-select-label" value={value} label="Rule Variable" onChange={onSelect}>
      {templateVariables?.map((ruleVariable) => (
        <MenuItem value={ruleVariable.id} key={ruleVariable.id}>
          {ruleVariable.name}
        </MenuItem>
      ))}
    </Select>
  );
};
