import axios, { AxiosRequestConfig } from "axios";
import { handleTokenError } from "./axiosClient";
import { getBearerToken } from "./client";

const componentSvcClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 2000,
});

componentSvcClient.interceptors.request.use(function (config: AxiosRequestConfig) {
  if (config.headers) config.headers.Authorization = getBearerToken() || "";

  return config;
});

componentSvcClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return handleTokenError(error);
  }
);

export function getRequest(url, queryParams = {}) {
  return componentSvcClient.get(url, {
    params: queryParams,
  });
}
