import { Box } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useBOMViewState } from "components/BOMView/BOMView";
import { EditEndColDefs, InitialColumnDefs, StatusColDef } from "components/BOMView/ColumnDefinitions";
import { useEffect, useMemo, useState } from "react";

export const GroupEditPreview = () => {
  const { selectedRowsData, setSelectedRowIDs } = useBOMViewState((state) => state);
  const [rowData, setRowData] = useState([]);
  const getCheckBoxColumn = () => {
    return {
      checkboxSelection: true,
      minWidth: 55,
    };
  };
  const [columnDefsTest] = useState([
    getCheckBoxColumn(),
    ...StatusColDef(false),
    ...InitialColumnDefs(false),
    ...EditEndColDefs(false),
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  useEffect(() => {
    setRowData(selectedRowsData);
  }, [selectedRowsData]);

  const handleOnFirstDataRendered = (params) => {
    // Setting 3rd arg to true so that UI won't lock up when many rows displayed
    params.api.forEachNode((node) => node.setSelected(true, false, true));
  };

  const handleSelectionChanged = (gridAPI) => {
    const selectedIDs = gridAPI?.api?.getSelectedRows().map((row) => row.id);
    setSelectedRowIDs(selectedIDs);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }} className="ag-theme-alpine-dark ag-theme-custom">
      <AgGridReact
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefsTest}
        rowSelection={"multiple"}
        onFirstDataRendered={handleOnFirstDataRendered}
        onSelectionChanged={handleSelectionChanged}
      ></AgGridReact>
    </Box>
  );
};
