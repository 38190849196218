import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Link,
  List,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "utils/MuiWrapper/components";
import { StandardDateTime } from "utils/dates";
import { useBOMProject } from "api/bom";
import { APPROVED_PROJECT, CARD_BG_COLOR, IN_PROGRESS_BOM, PARENT_IN_PROGRESS_MSG, SAVED_BOM } from "utils/constants";
import { useProject } from "api/project";
import { useCallback } from "react";
import { filterChildBOMs } from "utils/FilterChildBOMs";

export function ProjectBOMs() {
  const { projectId } = useParams();
  const { data: projectBOMData } = useBOMProject(projectId);
  const { data: project } = useProject(projectId);

  const statusLabel = useCallback((bom) => {
    const legend = { color: "", label: "" };
    if (bom.approved_at) {
      // Must go first because can also have status
      legend.label = "Approved";
      legend.color = "darkmagenta";
    } else if (bom.status === IN_PROGRESS_BOM) {
      legend.label = "In Progress";
      legend.color = "darkorange";
    } else if (bom.status === SAVED_BOM) {
      legend.label = "Saved";
      legend.color = "green";
    }
    return legend;
  }, []);

  return (
    <Card
      variant="elevation"
      sx={{
        height: project?.status !== APPROVED_PROJECT ? "91vh" : "50.7vh",
        backgroundColor: CARD_BG_COLOR,
        overflow: "auto",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Typography textAlign={"center"} variant="h1" gutterBottom>
          Bill of Materials
        </Typography>
        <Divider sx={{ marginBottom: "8.4px" }} />
        <List>
          {projectBOMData?.saved_boms?.results
            ?.filter((result) => project?.status !== APPROVED_PROJECT || result?.approved_at)
            .map((parentBOM) => (
              <Box key={parentBOM.id}>
                <Tooltip title={parentBOM.status === IN_PROGRESS_BOM && PARENT_IN_PROGRESS_MSG}>
                  <Paper
                    sx={{
                      borderRadius: "16px",
                      padding: "10px 10px",
                      marginBottom: "3px",
                    }}
                  >
                    <Link
                      to={
                        parentBOM.status === IN_PROGRESS_BOM
                          ? ""
                          : `/bom-view/${parentBOM.id}/project/${parentBOM.project_id}/added/0`
                      }
                      component={RouterLink}
                      key={parentBOM.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pointerEvents: parentBOM.status === IN_PROGRESS_BOM ? "none" : "inherit",
                      }}
                    >
                      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                        <Typography variant="body2" mr={4}>
                          {parentBOM.name}
                        </Typography>
                        <Typography variant="body2">{parentBOM.version}</Typography>
                      </Box>
                      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                        <Box
                          p={1}
                          mr={4}
                          sx={{
                            backgroundColor: `${statusLabel(parentBOM).color}`,
                            borderRadius: "0.5rem",
                          }}
                        >
                          <Typography>{statusLabel(parentBOM).label}</Typography>
                        </Box>
                        <Typography variant="body2">Updated: {StandardDateTime(parentBOM.last_updated)}</Typography>
                      </Box>
                    </Link>
                  </Paper>
                </Tooltip>
                <List component="div" disablePadding>
                  {filterChildBOMs(parentBOM?.children)?.map((childBOM) => (
                    <Paper
                      key={childBOM.id}
                      sx={{
                        borderRadius: "16px",
                        padding: "10px 10px",
                        marginBottom: "3px",
                        marginLeft: "30px",
                      }}
                    >
                      <Link
                        to={`/bom-view/${childBOM.id}/project/${childBOM.project_id}/project-template/${childBOM.project_template_id}`}
                        component={RouterLink}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                          <Typography variant="body2">{childBOM.name}</Typography>
                          <Typography variant="body2" sx={{ marginLeft: 4 }}>
                            {childBOM.version}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                          <Box
                            p={1}
                            mr={4}
                            sx={{
                              backgroundColor: `${statusLabel(childBOM).color}`,
                              borderRadius: "0.5rem",
                            }}
                          >
                            <Typography>{statusLabel(childBOM).label}</Typography>
                          </Box>
                          <Typography variant="body2">Updated: {StandardDateTime(childBOM.last_updated)}</Typography>
                        </Box>
                      </Link>
                    </Paper>
                  ))}
                </List>
              </Box>
            ))}
        </List>
        {project?.status !== APPROVED_PROJECT && (
          <Stack direction="row">
            <Box mx={2} sx={{ width: "1rem", backgroundColor: "green" }}>
              &nbsp;
            </Box>
            Saved
            <Box mx={2} sx={{ width: "1rem", backgroundColor: "darkorange" }}>
              &nbsp;
            </Box>
            In Progress
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
