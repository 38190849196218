import {
  Box,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Alert,
  Snackbar,
  Tooltip,
} from "utils/MuiWrapper/components";
import React, { useState } from "react";
import { Delete, Close } from "utils/MuiWrapper/icons";
import { createRuleAction, deleteRuleById, useUpdateRuleAction } from "api/rule";
import { RuleVariableSelect } from "components/TemplateBuilder/Iterations/Rules/RuleVariableSelect";
import { ALERT_BACKGROUND_COLOR, WARNING_COLOR } from "utils/constants";

const Rule = ({
  rule,
  fieldCollectionId,
  count,
  addRuleComponent,
  updateRuleComponent,
  editRule,
  removeRule,
  disableDelete,
  templateId,
  templateIsPublished,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const handleOpenDeleteDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpen(false);
  };

  const handleCloseDeleteDialogAccept = (rule) => {
    setOpen(false);
    handleDeleteRule(rule.id);
  };

  const handleDeleteRule = async (ruleId) => {
    await deleteRuleById(ruleId);
    removeRule(ruleId);
  };

  const handleActionChange = (data) => {
    rule.rule_actions[0] = { ...rule.rule_actions[0], ...data };
    editRule(rule);
  };

  const { mutate: updateRuleAction } = useUpdateRuleAction({
    onSuccess: handleActionChange,
  });

  const isFractionValid = (value) => {
    const fractionPattern = /^(?:(?:\d+\s)*\d+\/\d+|\d+)$/;
    if (value.indexOf(" ") > -1) {
      // i.e. 1 1/2 is not allowed
      return false;
    }
    if (!value.match(fractionPattern)) {
      // i.e.: 1/2 is valid
      return false;
    }
    const numbers = value.split("/");
    if (numbers[1] === "0") {
      // i.e.: 1/0 is not allowed
      return false;
    }
    return true;
  };

  const createOrUpdateAction = (data) => {
    if (!isFractionValid(data.multiplier)) {
      setError("The entered value is not a valid fraction.");
      data.multiplier = "";
      handleActionChange(data);
    }
    const actionId = rule.rule_actions[0].id;
    if (actionId) {
      updateRuleAction({ ruleId: rule.id, actionId, data });
    } else {
      createRuleAction(rule.id, { ...data, ordinal: 1 });
    }
  };

  const handleErrorClose = (event, reason) => {
    if (reason && reason === "clickaway") {
      return;
    }
    setError("");
  };

  const handleAlertClose = () => {
    setError("");
  };
  if (rule.rule_type === "component") {
    return (
      <Box p={1} marginBottom="2%">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!error}
          onClose={handleErrorClose}
          autoHideDuration={6000}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%", backgroundColor: ALERT_BACKGROUND_COLOR }}
          >
            {error}
          </Alert>
        </Snackbar>
        <Box p={0.2}>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              Rule {(count as number) + 1} - Component
            </Box>
            {rule.rule_action_value ? (
              <Button
                sx={{
                  margin: 1,
                }}
                disabled={templateIsPublished}
                onClick={() => {
                  updateRuleComponent(rule);
                }}
              >
                Update
              </Button>
            ) : (
              <Tooltip title="Please link a component.">
                <Button
                  sx={{
                    margin: 1,
                    border: `1px solid ${WARNING_COLOR}`,
                  }}
                  disabled={templateIsPublished}
                  onClick={() => {
                    addRuleComponent(rule);
                  }}
                >
                  Link
                </Button>
              </Tooltip>
            )}
            {!disableDelete ? (
              <Box display="flex" marginLeft="auto">
                <IconButton
                  color={"primary"}
                  aria-label={"delete"}
                  component={"label"}
                  disabled={templateIsPublished}
                  onClick={handleOpenDeleteDialog}
                >
                  <Delete></Delete>
                </IconButton>
                <Dialog open={open} onClose={handleCloseDeleteDialog}>
                  <DialogContent dividers>
                    <DialogTitle>Are you sure you want to delete this rule?</DialogTitle>
                    <IconButton disabled={templateIsPublished} onClick={handleCloseDeleteDialog}>
                      <Close />
                    </IconButton>
                  </DialogContent>
                  <DialogContent>
                    <DialogContentText>
                      Deleting this rule will not add "
                      {rule.rule_action_value?.length > 0 ? rule.rule_action_value : "the component"}" to the Bill of
                      Materials.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button disabled={templateIsPublished} onClick={handleCloseDeleteDialog} autoFocus={true}>
                      Cancel
                    </Button>
                    <Button disabled={templateIsPublished} onClick={() => handleCloseDeleteDialogAccept(rule)}>
                      I Understand
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Divider sx={{ bgcolor: "black" }} />
          {rule.rule_action_value ? <Box>{rule.rule_action_value}</Box> : <></>}

          {rule.rule_action_value && (
            <Box marginTop="3%" display="flex" alignItems="center">
              <Box>Multiplier:</Box>
              <Box
                width="35%"
                marginLeft="5%"
                justifyContent="center"
                sx={{
                  height: "2em",
                  textAlign: "center",
                }}
              >
                <TextField
                  id="filled-basic"
                  hiddenLabel
                  size="small"
                  autoComplete="off"
                  value={rule.rule_actions[0]?.multiplier}
                  placeholder="1/1"
                  disabled={templateIsPublished}
                  onChange={(e) => {
                    handleActionChange({ multiplier: e.target.value });
                  }}
                  onBlur={(e) => {
                    createOrUpdateAction({ multiplier: e.target.value });
                  }}
                  sx={{ textAlign: "center" }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (e.key === "Enter") {
                      (e.target as HTMLImageElement).blur();
                    }
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
  if (rule.rule_type === "addition") {
    return (
      <Box p={1} marginBottom="2%">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!error}
          onClose={handleErrorClose}
          autoHideDuration={6000}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%", backgroundColor: ALERT_BACKGROUND_COLOR }}
          >
            {error}
          </Alert>
        </Snackbar>
        <Box p={0.2}>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              Rule {(count as number) + 1} - Addition
            </Box>
            {!disableDelete ? (
              <Box display="flex" marginLeft="auto">
                <IconButton
                  color={"primary"}
                  aria-label={"delete"}
                  component={"label"}
                  disabled={templateIsPublished}
                  onClick={handleOpenDeleteDialog}
                >
                  <Delete></Delete>
                </IconButton>
                <Dialog open={open} onClose={handleCloseDeleteDialog}>
                  <DialogContent dividers>
                    <DialogTitle>Are you sure you want to delete this rule?</DialogTitle>
                    <IconButton disabled={templateIsPublished} onClick={handleCloseDeleteDialog}>
                      <Close />
                    </IconButton>
                  </DialogContent>
                  <DialogContent>
                    <DialogContentText>
                      Deleting this rule will not add "
                      {rule.rule_action_value?.length > 0 ? rule.rule_action_value : "the component"}" to the Bill of
                      Materials.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button disabled={templateIsPublished} onClick={handleCloseDeleteDialog} autoFocus={true}>
                      Cancel
                    </Button>
                    <Button disabled={templateIsPublished} onClick={() => handleCloseDeleteDialogAccept(rule)}>
                      I Understand
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Divider sx={{ bgcolor: "black" }} />
          <Box display="flex" paddingTop={2}>
            <Box paddingRight={2}>Add Value to:</Box>
            <RuleVariableSelect
              templateId={templateId}
              fieldCollectionId={fieldCollectionId}
              value={rule.rule_actions[0]?.variable_id}
              onSelect={(event) => {
                updateRuleAction({
                  ruleId: rule.id,
                  actionId: rule.rule_actions[0].id,
                  data: {
                    variable_id: event.target.value,
                  },
                });
              }}
            />
          </Box>
          <Box marginTop="3%" display="flex" alignItems="center">
            <Box>Multiplier:</Box>
            <Box width="35%" marginLeft="5%" justifyContent="center" sx={{ height: "2em" }}>
              <TextField
                id="filled-basic"
                hiddenLabel
                size="small"
                autoComplete="off"
                value={rule.rule_actions[0]?.multiplier}
                placeholder="1/1"
                disabled={templateIsPublished}
                onChange={(e) => {
                  handleActionChange({ multiplier: e.target.value });
                }}
                onBlur={(e) => {
                  createOrUpdateAction({ multiplier: e.target.value });
                }}
                sx={{ textAlign: "center" }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

export default Rule;
