import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "utils/MuiWrapper/components";
import { StandardDate } from "utils/dates";
import { Close } from "utils/MuiWrapper/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BomByProjectIDType, BomUIType, createProjectBom, fetchBomByProjectId, ProjectBomType } from "api/bom";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { ProjectTemplate } from "types/ProjectTemplate";
import { Project } from "types/Project";
import _ from "lodash";
import { DATACENTER_TEMPLATE, DC_TEMPLATE, IN_PROGRESS_BOM } from "utils/constants";

const MAX_STR_LEN = 40;

interface dropDownDataType {
  id: string;
  name: string;
  options: BomUIType[];
}

interface GenerateOverallBomModalType {
  project: Project;
}

export const GenerateOverallBomModal: React.FC<GenerateOverallBomModalType> = ({ project }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectData, setSelectData] = useState<dropDownDataType[] | null>(null);
  const [parentBom, setParentBom] = useState<BomUIType | undefined>(undefined);
  const [templateBoms, setTemplateBoms] = useState<string[]>([]);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };

  const { mutate: generateProjectBom } = useMutation<ProjectBomType, Error, { projectId: string; body: object }>({
    mutationFn: ({ projectId, body }) => {
      if (!projectId) {
        throw new Error("no project specified");
      }
      return createProjectBom({ projectId: projectId, body: body });
    },
    onSuccess: () => {
      window.open(`/bom-view/bom_${project?.id}/project/${project.id}/added/0`, "_blank");
      toggleModal();
    },
    onError: () => {
      setAlert({
        type: "error",
        message: "Error Generating Project BOMs",
      });
    },
  });

  const { refetch } = useQuery<BomByProjectIDType, Error>(
    ["bom-by-project-id", project.id],
    async () => {
      const res = await fetchBomByProjectId(project.id);
      return res;
    },
    {
      onSuccess: (data) => {
        //Get Saved Boms
        const savedBoms = data.saved_boms.results;
        //Check for is there are no saved boms
        if (savedBoms.length === 0) {
          setAlert({
            type: "error",
            message: "You have no saved BOMS.",
          });
          return;
        } else {
          //Find Parent Boms
          const filteredParentBoms = savedBoms.filter(
            (bom) => bom.is_parent && bom.status === "in_progress" && bom.children.length > 0
          );
          //Check for if no Parent Boms are returned
          if (filteredParentBoms.length === 0) {
            setAlert({
              type: "error",
              message:
                "You need an in-progress BOM to generate an Overall BOM. Generating and saving a template BOM will create an in-progress BOM.",
              timeout: 10,
            });
            return;
          } else {
            const firstParentBom = filteredParentBoms[0];
            setParentBom(firstParentBom);

            //The Select dropdown needs Project Template ID/Name and options which are the Children Boms from the Parent Bom
            // First Sort to get most recently modified
            let sortedProjectTemplates: ProjectTemplate[] = _.orderBy(
              project.project_templates,
              "last_modified",
              "desc"
            );

            // If there are in-progress data center template BOMs, use only those templates
            const hasDCBOMInProgress = savedBoms.some(
              (bom) =>
                bom.status === IN_PROGRESS_BOM &&
                bom.children.some((child) => child.type === DC_TEMPLATE && child.status === IN_PROGRESS_BOM)
            );

            if (hasDCBOMInProgress) {
              sortedProjectTemplates = sortedProjectTemplates.filter(
                (template) => template.type === DATACENTER_TEMPLATE
              );
            }

            const firstOptions: string[] = [];
            const dropDownData: dropDownDataType[] = [];
            sortedProjectTemplates.forEach((pt) => {
              let options: BomUIType[] = firstParentBom?.children.filter(
                (childBom) => childBom.project_template_id === pt.id
              );
              //If there are no options, it is not a option
              if (options.length === 0) {
                return;
              }
              //Sort by updated date
              options = _.orderBy(options, "last_updated", "desc");

              //Adds first template as default in view
              firstOptions.push(options[0].id);
              //add to drop down list
              dropDownData.push({
                id: pt.id,
                name: pt.name,
                options: options,
              });
            });
            setTemplateBoms(firstOptions);

            if (dropDownData.length === 0) {
              setAlert({
                type: "error",
                message: "Could not generate options.",
              });
              return;
            } else {
              // Template BOM options
              setSelectData(dropDownData);
              toggleModal();
            }
          }
        }
      },
      onError: () => {
        setAlert({
          type: "error",
          message: "You need to generate and save a template BOM before generating an Overall BOM",
        });
      },
      enabled: false,
      retry: false,
      retryOnMount: false,
    }
  );
  return (
    <Box>
      <Button
        sx={{
          padding: "2em",
          fontSize: "14px",
        }}
        onClick={async () => {
          await refetch();
        }}
      >
        Generate Overall BOM
      </Button>
      <Dialog open={isOpen} fullWidth>
        <DialogContent dividers>
          <IconButton
            onClick={() => {
              toggleModal();
            }}
          >
            <Close />
          </IconButton>
        </DialogContent>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Tooltip title={parentBom !== undefined ? parentBom.name : null}>
              <TextField
                inputProps={{ min: 0, style: { textAlign: "center", fontSize: "3ch" } }}
                disabled
                value={
                  parentBom !== undefined
                    ? parentBom.name.length > MAX_STR_LEN
                      ? `${parentBom.name.slice(0, MAX_STR_LEN)}... ${parentBom.version || ""} Updated: ${StandardDate(
                          parentBom.last_updated
                        )}`
                      : `${parentBom.name} ${parentBom.version || ""} Updated: ${StandardDate(parentBom.last_updated)}`
                    : ""
                }
              />
            </Tooltip>

            {selectData === null
              ? null
              : Object.keys(selectData).map((index) => {
                  return (
                    <Box
                      key={`${index}-select-data`}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {selectData[index].options && selectData[index].options.length > 0 ? (
                        <SelectComponent
                          index={index}
                          selectedData={selectData[index]}
                          setTemplateBoms={setTemplateBoms}
                        />
                      ) : null}
                    </Box>
                  );
                })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              generateProjectBom({ projectId: project.id, body: { template_boms: templateBoms } });
            }}
            sx={{
              marginLeft: "auto",
            }}
            color="primary"
          >
            Generate BOM
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const SelectComponent = ({ index, selectedData, setTemplateBoms }) => {
  const options = selectedData.options;
  const [selected, setSelected] = useState(options[0].id);
  return (
    <Select
      labelId={`${selectedData.name}-label-id`}
      id={`template-bom-id`}
      label={`${selectedData.name}-label`}
      sx={{ width: "75%" }}
      value={selected}
      onChange={(event) => {
        setSelected(event.target.value);
        setTemplateBoms((prev) => {
          prev[index] = event.target.value;
          return prev;
        });
      }}
    >
      {options.length === 0
        ? null
        : options.map((bom) => {
            return (
              <MenuItem key={bom.id} value={bom.id}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography variant="body1" sx={{}}>
                    {selectedData.name}
                  </Typography>
                  <Typography variant="body1" sx={{}}>
                    {bom.version}
                  </Typography>
                  <Typography variant="body1" sx={{ paddingRight: "5px" }}>
                    Updated: {StandardDate(bom.last_updated)}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
    </Select>
  );
};
