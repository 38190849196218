import { Box, Stack, useTheme } from "utils/MuiWrapper/components";
import React, { useEffect, useState } from "react";
import ColumnFour from "./Columns/ColumnFour";
import ColumnOne from "./Columns/ColumnOne";
import ColumnTwo from "./Columns/ColumnTwo";
import RuleSets from "./Rules/RuleSets";
import { fetchFields } from "api/template";
import {
  fetchRulesByTemplateId,
  deleteRuleActionById,
  deleteRuleConditionsById,
  deleteRuleById,
  createRule,
  createRuleConditions,
  useFetchRulesByTemplateId,
} from "api/rule";
import { useQueryClient } from "@tanstack/react-query";

const containerBaseHeight = 20;

const Iteration = ({ templateId, templateIsPublished, iterationId, selectOptions, sequenceId }) => {
  const queryClient = useQueryClient();
  const [isColumnOneSelected, setIsColumnOneSelected] = useState(false);
  const [_isColumnTwoSelected, setIsColumnTwoSelected] = useState(false);
  const [columnOneFieldId, setColumnOneFieldId] = useState("");
  const [columnTwoFieldId, setColumnTwoFieldId] = useState("");
  const [height, setHeight] = useState(containerBaseHeight);
  const [colOneLength, setColOneLength] = useState(0);
  const [sectionFields, setSectionFields] = useState([]);
  const [columnOneVariableId, setColumnOneVariableId] = useState("");

  useEffect(() => {
    if (iterationId != "") {
      getFields(templateId, iterationId);
    }
  }, [templateId, iterationId]);

  const { data: rules } = useFetchRulesByTemplateId(templateId, {
    key: "template_section_id",
    value: iterationId,
  });

  const columnOneSelected = (field, isSelected) => {
    setIsColumnOneSelected(isSelected);
    setColumnOneFieldId(field.id);
    setColumnOneVariableId(field.variable_id);
    columnTwoSelected("", false);
  };

  const columnTwoSelected = (fieldId, isSelected) => {
    setIsColumnTwoSelected(isSelected);
    setColumnTwoFieldId(fieldId);
  };

  const clearColumnOne = () => {
    setIsColumnOneSelected(false);
    setColumnOneFieldId("");
    setColumnOneVariableId("");
    columnTwoSelected("", false);
  };

  const deleteRules = async (templateId, fieldCollectionId, columnOneFieldId, columnTwoFieldId) => {
    const data = await fetchRulesByTemplateId(templateId, {
      key: "template_section_id",
      value: fieldCollectionId,
    });
    const filteredRules = data.results.filter((rule) => {
      if (!columnTwoFieldId) {
        return rule.template_section_field_options.col1 == columnOneFieldId;
      } else {
        return (
          rule.template_section_field_options.col1 == columnOneFieldId &&
          rule.template_section_field_options.col2 == columnTwoFieldId
        );
      }
    });
    for (const rule of filteredRules) {
      const rule_actions = rule.rule_actions;
      const rule_conditions = rule.rule_conditions;
      for (const rule_action of rule_actions) {
        await deleteRuleActionById(rule.id, rule_action.id);
      }
      for (const rule_condition of rule_conditions) {
        await deleteRuleConditionsById(rule.id, rule_condition.id);
      }
      await deleteRuleById(rule.id);
    }
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const createDefaultRule = async (columnOneFieldId, columnTwoFieldId, fieldCollectionId) => {
    const ruleData = {
      salience: 1,
      template_section_field_options: {
        col1: columnOneFieldId,
        col2: columnTwoFieldId,
        variable_id: columnOneVariableId,
      },
      rule_type: "component",
      template_section_id: fieldCollectionId,
      sequence_id: sequenceId,
    };
    const rule = await createRule(templateId, ruleData);
    const ruleConditionData = {
      operator: "and",
      rule_conditions: [
        {
          operator: "eq",
          template_section_field_option_id: columnOneFieldId,
          value: columnOneFieldId,
          variable_id: columnOneVariableId,
        },
        {
          operator: "eq",
          template_section_field_option_id: columnTwoFieldId,
          value: columnTwoFieldId,
          variable_id: columnOneVariableId,
        },
      ],
    };
    await createRuleConditions(rule.id, ruleConditionData);
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const calculateHeight = (column, length) => {
    const lineHeight = 2.8;
    if (column === 1) {
      setColOneLength(length);
      setHeight(containerBaseHeight + (length - 1) * lineHeight);
    } else if (column === 2) {
      const largerLength = length > colOneLength ? length : colOneLength;
      setHeight(containerBaseHeight + (largerLength - 1) * lineHeight);
    }
  };

  const getFields = async (templateId, fieldCollectionId) => {
    const data = await fetchFields(templateId, fieldCollectionId);
    setSectionFields(data.results);
  };

  const colOne = (
    <Box flex={1}>
      <ColumnOne
        columnOneSelected={columnOneSelected}
        columnOneFieldId={columnOneFieldId}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={iterationId}
        clearColumnOne={clearColumnOne}
        deleteRules={deleteRules}
        calculateHeight={calculateHeight}
        sectionFields={sectionFields}
        getFields={getFields}
        selectOptions={selectOptions}
        type="iteration"
        collectionCount={0}
      />
    </Box>
  );

  const colTwo = (
    <Box flex={2}>
      <ColumnTwo
        columnTwoSelected={columnTwoSelected}
        columnOneFieldId={columnOneFieldId}
        columnTwoFieldId={columnTwoFieldId}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={iterationId}
        deleteRules={deleteRules}
        createDefaultRule={createDefaultRule}
        calculateHeight={calculateHeight}
        collectionCount={0}
      />
    </Box>
  );
  const colFour = (
    <Box flex={2}>
      <ColumnFour
        columnOneFieldId={columnOneFieldId}
        templateId={templateId}
        templateIsPublished={templateIsPublished}
        fieldCollectionId={iterationId}
        columnOneSelected={columnOneSelected}
        sectionFields={sectionFields}
        collectionCount={0}
      />
    </Box>
  );
  const theme = useTheme();
  const backgroundColor = theme.palette.grey["300"];
  const rulesBackgroundColor = theme.palette.grey["600"];
  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{
        marginBlock: "2%",
        backgroundColor,
        height: `${height}em`,
        overflow: "auto",
      }}
    >
      <Box width="90%" p={3}>
        <Box>
          <Stack direction="row" spacing={0} justifyContent="space-between">
            {colOne}
            {isColumnOneSelected && columnOneFieldId ? <>{colTwo}</> : <Box flex={1} p={2}></Box>}
            {colFour}
          </Stack>
        </Box>
      </Box>
      <Box
        width="20%"
        marginLeft="1%"
        sx={{
          overflow: "auto",
          height: `${height}em`,
          backgroundColor: rulesBackgroundColor,
        }}
      >
        {rules && (
          <RuleSets
            templateId={templateId}
            templateIsPublished={templateIsPublished}
            fieldCollectionId={iterationId}
            columnOneFieldId={columnOneFieldId}
            columnTwoFieldId={columnTwoFieldId}
            rules={rules}
            columnOneSelected={columnOneSelected}
            columnTwoSelected={columnTwoSelected}
            sequenceId={sequenceId}
          />
        )}
      </Box>
    </Stack>
  );
};

export default Iteration;
