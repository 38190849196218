import { styled } from "@mui/material/styles";
import {
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Toolbar,
  Tooltip,
  Typography,
} from "utils/MuiWrapper/components";
import { FilterList as FilterListIcon } from "utils/MuiWrapper/icons";
import { CARD_BG_COLOR } from "utils/constants";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: CARD_BG_COLOR,
    color: theme.palette.common.white,
    fontWeight: "bold",
    borderBottom: "3px solid #b20a67",
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#13172c",
    fontSize: 12,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const favoritesContainerStyles = {
  minHeight: 175,
  maxHeight: 175,
};

export const projectsContainerStyles = {
  mt: "15px",
};

export function EnhancedTableToolbar({ label }) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h3" id="tableTitle" component="div">
        {label}
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export function EnhancedTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox"></StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function StandardTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {standardHeadCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type AlignType = "inherit" | "left" | "center" | "right" | "justify";

const headCells = [
  {
    id: "name",
    align: "left" as AlignType,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "last_modified",
    align: "right" as AlignType,
    disablePadding: false,
    label: "Last Modified",
  },
  {
    id: "Actions",
    align: "center" as AlignType,
    disablePadding: false,
    label: "Actions",
  },
];

const standardHeadCells = [
  {
    id: "name",
    align: "left" as AlignType,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "last_modified",
    align: "right" as AlignType,
    disablePadding: false,
    label: "Last Modified",
  },
];
