import React, { useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
  Stack,
  Link,
  Button,
  Box,
  Tooltip,
} from "utils/MuiWrapper/components";
import { sortBy } from "lodash";
import { Link as RouterLink, useParams } from "react-router-dom";
import { BaseSidenav } from "./BaseSidenav";
import { useProject } from "api/project";
import { useBOMProject } from "api/bom";
import {
  AssignTemplateToProjectModal,
  useAssignTemplateToProjectModalState,
  useSelectTemplateModalState,
} from "components/shared/AssignTemplateToProjectModal";
import { GenerateOverallBomModal } from "./GenerateOverallBomModal";
import { APPROVED_PROJECT, IN_PROGRESS_BOM, PARENT_IN_PROGRESS_MSG } from "utils/constants";
import { filterChildBOMs } from "utils/FilterChildBOMs";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { Action, Roles } from "utils/enums";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { useGetUser } from "api/auth";

const listItemStyles = { marginLeft: "15px", overflow: "hidden", textOverflow: "ellipsis" };

export const ProjectSidenav = ({ ...rest }) => {
  const { projectId } = useParams();
  const { userId } = useAuthStore();
  const { data: user } = useGetUser(userId);
  const { data: project, isSuccess, isLoading } = useProject(projectId || "");
  const { data: projectBOMData } = useBOMProject(projectId);
  const { isProjectManager, isProjectMember } = useProjectTeamManagement(project, userId);

  const openModal = useAssignTemplateToProjectModalState((state) => state.open);
  const setType = useSelectTemplateModalState((state) => state.setTemplateType);
  const openTemplateModal = (templateType: string) => {
    setType(templateType);
    openModal();
  };
  const projectTemplates = useMemo(() => {
    if (!project || !project.project_templates) {
      return undefined;
    }

    // TODO: remove this once the server implements sorting
    return sortBy(project.project_templates, (x) => x.last_modified);
  }, [project]);

  if (isLoading) {
    return (
      <BaseSidenav width={"auto"} {...rest}>
        <Typography>Loading...</Typography>
      </BaseSidenav>
    );
  }

  if (!isSuccess) {
    return (
      <BaseSidenav width={"auto"} {...rest}>
        <Typography>Error</Typography>
      </BaseSidenav>
    );
  }

  const canViewTemplate = (isProjectManager || isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.template_user, Action.View);

  return (
    <>
    {
    !(project.status === "hidden") &&
    <BaseSidenav width={"auto"} {...rest}>
      <Stack direction="column" spacing={0} p={2} flexGrow={1}>
        <Box mb={2} textAlign="center">
          <Link variant="h6" to="/" component={RouterLink}>
            Back to Library
          </Link>
        </Box>
        <Link to={`/projects/${projectId}`} component={RouterLink}>
          <Typography textAlign="center" variant="h5">
            {project.name}
          </Typography>
        </Link>
        {project.status !== APPROVED_PROJECT && (
          <>
            <Accordion>
              <AccordionSummary>
                <Typography>Templates</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ maxHeight: "20vh", overflow: "auto" }}>
                  <List>
                    {projectTemplates
                      ?.filter((x) => x.type == "standard")
                      .map((template) => (
                        <ListItem key={template.id}>
                          {canViewTemplate ? <Link
                            to={`/user-view/project/${projectId}/template/${template.template_id}/project-template/${template.id}`}
                            component={RouterLink}
                          >
                            {template.name}
                          </Link> :
                          <div>{template.name}</div>}
                        </ListItem>
                      ))}
                    {(isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.template_user, Action.Create) && (
                      <ListItem key="add-new-template">
                        <Button onClick={() => openTemplateModal("standard")}>Add new</Button>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                <Typography>Data Center Templates</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ maxHeight: "20vh", overflow: "auto" }}>
                  <List>
                    {projectTemplates
                      ?.filter((x) => x.type == "dc")
                      .map((template) => (
                        <ListItem key={template.id}>
                          {canViewTemplate ? <Link
                            to={`/user-view/project/${projectId}/template/${template.template_id}/project-template/${template.id}`}
                            component={RouterLink}
                          >
                            {template.name}
                          </Link> :
                          <div>{template.name}</div>}
                        </ListItem>
                      ))}
                    {(isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.template_user, Action.Create) && (
                      <ListItem key="add-new-dc">
                        <Button onClick={() => openTemplateModal("dc")}>Add new</Button>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <Accordion>
          <AccordionSummary>
            <Typography>BOMs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ maxHeight: "20vh", overflow: "auto" }}>
              {projectBOMData?.saved_boms?.results
                ?.filter((result) => project?.status !== APPROVED_PROJECT || result?.approved_at)
                .map((parentBOM, parentIndex) => (
                  <List key={parentIndex}>
                    <Tooltip title={parentBOM.status === IN_PROGRESS_BOM && PARENT_IN_PROGRESS_MSG}>
                      <Typography sx={listItemStyles}>
                        <Link
                          to={
                            parentBOM.status === IN_PROGRESS_BOM
                              ? ""
                              : `/bom-view/${parentBOM?.id}/project/${parentBOM?.project_id}/added/0`
                          }
                          component={RouterLink}
                          sx={{ pointerEvents: parentBOM.status === IN_PROGRESS_BOM ? "none" : "inherit" }}
                        >
                          {parentBOM?.name}
                          <Box component="span" sx={{ marginLeft: 4 }}>
                            {parentBOM.version}
                          </Box>
                        </Link>
                      </Typography>
                    </Tooltip>

                    <List>
                      {filterChildBOMs(parentBOM?.children)?.map((childBOM, childIndex) => (
                        <ListItem key={childIndex}>
                          <Typography sx={listItemStyles}>
                            <Link
                              to={`/bom-view/${childBOM?.id}/project/${childBOM?.project_id}/project-template/${childBOM?.project_template_id}`}
                              component={RouterLink}
                            >
                              {childBOM?.name}
                              <Box component="span" sx={{ marginLeft: 4 }}>
                                {childBOM.version}
                              </Box>
                            </Link>
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </List>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center" }}>
          {window.location.href.includes("projects") && (isProjectManager || user?._embedded.role.name === Roles.Architect) &&
            hasPermission(user?.permissions?.project, Action.Create) && project?.status !== APPROVED_PROJECT && (
            <GenerateOverallBomModal project={project} />
          )}
        </Box>
      </Stack>
      <AssignTemplateToProjectModal />
    </BaseSidenav>
    }
    </>
  );
};
