import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { ITokenPayload } from "types/Token";
import { Action } from "utils/enums";
import { Project, TeamMember } from "types/Project";

export const decodeToken = (token: string): ITokenPayload | undefined => {
  try {
    return jwt_decode<ITokenPayload>(token);
  } catch {
    return undefined;
  }
}

export const hasPermission = (subject: number | undefined, action: Action): boolean => {
  if(!subject) return false;

  return (subject & action) > 0;
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const useProjectTeamManagement = (project: Project, userId?: string) => {
  const [state, setState] = useState({
    isProjectManager: false,
    isProjectMember: false,
  });

  useEffect(() => {
    if(userId && project?.project_teams?.length > 0) {
      let found = false;
      for(let i = 0; i < project.project_teams.length; i++) {
        const teamMember = project.project_teams[i];
  
        if(teamMember.user_id === userId && teamMember.role === "project_manager") {
          setState({
            isProjectManager: true,
            isProjectMember: false
          })
          found = true;
          break;
        }
  
        if(teamMember.user_id === userId && teamMember.role === "project_member") {
          setState({
            isProjectManager: false,
            isProjectMember: true
          })
          found = true;
          break;
        }
      }

      if(!found) {
        setState({
          isProjectManager: false,
          isProjectMember: false
        })
      }
    } else {
      setState({
        isProjectManager: false,
        isProjectMember: false
      })
    }
  }, [project, userId]);

  return state;
}