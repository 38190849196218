import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TextField,
  Autocomplete,
  Chip,
  Paper,
} from "utils/MuiWrapper/components";
import { DesktopDatePicker } from "utils/MuiWrapper/datepicker";
import { styled } from '@mui/material/styles';

import { StyledTableCell } from "./ProjectInfo";
import { useGetUsers } from "api/auth";
import { User } from "types/User";
import { TeamMember } from "types/Project";
import { useProjectInfoState } from "./ProjectInfoState";

export const EditTable = ({ project, editProjectTeam, deleteProjectTeamMember }) => {
  const { projectInfo, setProjectInfo } = useProjectInfoState((state) => state);

  const [selectedProjectManager, setSelectedProjectManager] = useState<User>();
  const [selectedProjectMember, setSelectedProjectMember] = useState<User>();

  const [projectManagerValue, setProjectManagerValue] = useState("");
  const [projectMemberValue, setProjectMemberValue] = useState("");

  const { data: users } = useGetUsers();

  useEffect(() => {
    const name = project.name === undefined ? "" : project.name;
    const sf_id = project.sf_id === undefined ? "" : project.sf_id;
    const start_date = project.start_date === undefined ? null : project.start_date;
    const end_date = project.end_date === undefined ? null : project.end_date;
    const capex_code = project.capex_code === undefined ? "" : project.capex_code;

    setProjectInfo({ key: "name", value: name });
    setProjectInfo({ key: "sf_id", value: sf_id });
    setProjectInfo({ key: "start_date", value: start_date });
    setProjectInfo({ key: "end_date", value: end_date });
    setProjectInfo({ key: "capex_code", value: capex_code });
  }, [project, setProjectInfo]);

  const handleChange = (e) => {
    setProjectInfo({ key: e.target.name, value: e.target.value });
  };

  const handleProjectManagerSelect = (_, user: User | null) => {
    if (!user) return;

    setSelectedProjectManager(undefined);
    setProjectManagerValue("");
    editProjectTeam({ user_id: [user.id], role: "project_manager" });
  };

  const handleProjectMemberSelect = (_, user: User | null) => {
    if (!user) return;

    setSelectedProjectMember(undefined);
    setProjectMemberValue("");
    editProjectTeam({ user_id: [user.id], role: "project_member" });
  };

  const handleProjectManagerValue = (_, value: string, reason) => {
    if(reason !== "reset") {
      setProjectManagerValue(value);
    }
  }

  const handleProjectMemberValue = (_, value: string, reason) => {
    if(reason !== "reset") {
      setProjectMemberValue(value);
    }
  }

  const handleDelete = (teamMember?: TeamMember) => () => {
    if(!teamMember) return null;

    deleteProjectTeamMember(teamMember);
  };

  const projectManagerMap = new Map<string, TeamMember>();
  const projectMemberMap = new Map<string, TeamMember>();
  project?.project_teams?.map((teamMember: TeamMember) =>
    teamMember.role === "project_manager" ? projectManagerMap.set(teamMember.user_id, teamMember) : projectMemberMap.set(teamMember.user_id, teamMember));

  const usersMap = new Map<string, User>();
  const teamOptions: Array<User> = [];
  users?.results?.map((user) => {
    usersMap.set(user.id, user);

    if(!projectManagerMap.has(user.id) && !projectMemberMap.has(user.id)) {
      teamOptions.push(user);
    }
  });

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
 
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <StyledTableCell>Name</StyledTableCell>
          <StyledTableCell>
            <TextField fullWidth name="name" value={projectInfo.name} onChange={handleChange} />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>SF ID</StyledTableCell>
          <StyledTableCell>
            <TextField fullWidth name="sf_id" value={projectInfo?.sf_id} onChange={handleChange} />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Start Date</StyledTableCell>
          <StyledTableCell>
            <DesktopDatePicker
              onChange={(value) => setProjectInfo({ key: "start_date", value })}
              value={projectInfo?.start_date}
              renderInput={(params) => <TextField name="start_date" fullWidth {...params} />}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>End Date</StyledTableCell>
          <StyledTableCell>
            <DesktopDatePicker
              onChange={(value) => setProjectInfo({ key: "end_date", value })}
              value={projectInfo?.end_date}
              renderInput={(params) => <TextField name="end_date" fullWidth {...params} />}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Capex Code</StyledTableCell>
          <StyledTableCell>
            <TextField name="capex_code" onChange={handleChange} fullWidth value={projectInfo?.capex_code} />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Region</StyledTableCell>
          <StyledTableCell>{project?.region}</StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Project Manager</StyledTableCell>
          <StyledTableCell>
            <Paper
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {Array.from(projectManagerMap.values())?.map((teamMember) => {
                return (
                  <ListItem key={teamMember.user_id}>
                    <Chip
                      label={usersMap.get(teamMember.user_id)?.name}
                      onDelete={handleDelete(teamMember)}
                    />
                  </ListItem>
                );
              })}
            </Paper>
            <Autocomplete
              options={teamOptions}
              getOptionLabel={(user: User) => user.name}
              noOptionsText="No users found"
              blurOnSelect={true}
              value={selectedProjectManager}
              onChange={handleProjectManagerSelect}
              inputValue={projectManagerValue}
              onInputChange={handleProjectManagerValue}
              renderInput={(params) => <TextField {...params} fullWidth />}
              sx={{ input: { height: 8 } }}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Project Team</StyledTableCell>
          <StyledTableCell>
            <Paper
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {Array.from(projectMemberMap.values())?.map((teamMember) => {
                return (
                  <ListItem key={teamMember.user_id}>
                    <Chip
                      label={usersMap.get(teamMember.user_id)?.name}
                      onDelete={handleDelete(teamMember)}
                    />
                  </ListItem>
                );
              })}
            </Paper>
            <Autocomplete
              options={teamOptions}
              getOptionLabel={(user: User) => user.name}
              noOptionsText="No users found"
              blurOnSelect={true}
              value={selectedProjectMember}
              onChange={handleProjectMemberSelect}
              inputValue={projectMemberValue}
              onInputChange={handleProjectMemberValue}
              renderInput={(params) => <TextField {...params} fullWidth />}
              sx={{ input: { height: 8 } }}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Status</StyledTableCell>
          <StyledTableCell>{project?.status}</StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
