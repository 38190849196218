import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Templates from "components/Templates/Templates";
import ProjectsPage from "components/ProjectsPage";
import FavoritesTable from "components/ProjectsPage/FavoritesTable";
import { TabPanel, a11yProps } from "components/shared/TabPanel";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";
import { Roles } from "utils/enums";

export default function TemplateProjectTabView() {
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Favorites" {...a11yProps(0)} />
          <Tab label="Projects" {...a11yProps(1)} />
          {user?._embedded.role.name === Roles.Architect && (
            <Tab label="Templates" {...a11yProps(2)} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FavoritesTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProjectsPage />
      </TabPanel>
      {user?._embedded.role.name === Roles.Architect && (
        <TabPanel value={value} index={2}>
          <Templates showActions={true} />
        </TabPanel>
      )}
    </Box>
  );
}
