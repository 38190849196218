import { Box, FormControl, FormHelperText, TextField } from "utils/MuiWrapper/components";
import React, { useEffect, useRef, useState } from "react";
import { updateField } from "api/template";
import { RANGE_PLACEHOLDER } from "utils/constants";
import { useAlertSnackbarState } from "../../../AlertSnackbar/AlertSnackbar";
import { TemplateSectionField } from "types/TemplateSectionField";
import { getFormInputStyles } from "components/shared/controls";

const ColumnThree = ({
  templateId,
  templateIsPublished,
  fieldCollectionId,
  columnOneFieldId,
  sectionFields,
  columnOneSelected,
  collectionCount,
}: {
  templateId: string;
  templateIsPublished: boolean;
  fieldCollectionId: string;
  columnOneFieldId: string;
  sectionFields: TemplateSectionField[];
  columnOneSelected: (x: TemplateSectionField, value: boolean) => void;
  collectionCount: number
}) => {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [fields, setFields] = useState<TemplateSectionField[]>([]);
  const [errorId, setErrorId] = useState("");
  const parentNode = useRef<Element>();

  useEffect(() => {
    setFields(sectionFields);
  }, [sectionFields]);

  const handleFieldChange = (value: string, id: string) => {
    const updatedFields = fields.map((field) => {
      if (field.id == id) {
        field.range_value = value;
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleStartChange = (value: string, id: string) => {
    const updatedFields = fields.map((field) => {
      if (field.id == id) {
        field.start_value = value;
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleEndChange = (value: string, id: string) => {
    const updatedFields = fields.map((field) => {
      if (field.id == id) {
        field.end_value = value;
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleStepChange = (value: string, id: string) => {
    const updatedFields = fields.map((field) => {
      if (field.id == id) {
        field.step_value = value;
      }
      return field;
    });
    setFields(updatedFields);
  };

  const validRange = /^(\d+):(\d+):(\d+)$/;

  const fieldSelected = (field: TemplateSectionField) => {
    columnOneSelected(field, true);
  };

  const updateRange = async (data: Pick<TemplateSectionField, 
    "range_value" | "start_value" | "end_value" | "step_value">, fieldId: string) => {
    let value: string | undefined = undefined;
    if(!data.start_value || !data.end_value || !data.step_value)
      return;
    if(data.start_value != "" && data.end_value != "" && data.step_value != "") {
      value = data.range_value
      value = `${data.start_value}:${data.end_value}:${data.step_value}`
    }
    if (value && validRange.test(value)) {
      const start =  Number.parseInt(data.start_value);
      const end = Number.parseInt(data.end_value);
      const step = Number.parseInt(data.step_value);
      if (start > end || step > (end - start)) {
        setErrorId(fieldId);
          setAlert({
            type: "error",
            message: "Invalid Range Value Format. Example format is 1, 8, 2",
          });
      }

      const justRange: Pick<TemplateSectionField, "range_value"> = {
        range_value: value
      }
      
      await updateField(templateId, fieldCollectionId, fieldId, justRange).catch((error) => {
        setAlert({
          type: "error",
          message: error,
        });
      });

      handleFieldChange(value, fieldId);
    } else {
        if (value) {
          setErrorId(fieldId);
          setAlert({
            type: "error",
            message: "Invalid Range Value Format. Example format is 1, 8, 2",
          });
        }
    }
  };

  function handleArrowKey({
    key,
    isFocusPresent,
    availableElements,
    activeElement
  }) {
    const currentIndex = Array.from(availableElements).findIndex(
      (availableElement) => availableElement === activeElement
    );
  
    if (key === "ArrowDown" && availableElements.length > currentIndex + 3) {
      availableElements[currentIndex + 3]?.focus();
    }
  
    if (key === "ArrowUp" && currentIndex - 3 >= 0) {
      availableElements[currentIndex - 3]?.focus();
    }
  }

  function handleArrowKeyEvent({ e }) {
    const key = e.key;
    const selectors = "input#filled-basic"
  
    const activeElement = document.activeElement;
    const availableElements = parentNode.current?.querySelectorAll(selectors);
  
    if (!availableElements?.length) return;
    const isFocusPresent = parentNode.current?.contains(activeElement);
  
    handleArrowKey({ key, isFocusPresent, activeElement, availableElements });
  }

  return (
    <>
      <Box flex={1} ref={parentNode}>
        {fields?.map((field, i) => {
          let range = field.range_value;
          let start = field.start_value;
          let end = field.end_value;
          let step = field.step_value;
          if (range === RANGE_PLACEHOLDER) {
            range = "";
          }
          const divided = range?.split(":");
          if(start == undefined) {
            start = divided && divided.length > 1 ? divided[0] : "";
          }
          if(end == undefined) {
            end = divided && divided.length > 1 ? divided[1] : "";
          }
          if(step == undefined) {
            step = divided && divided.length > 1 ? divided[2] : "";
          }
          return (
            <Box key={i}  marginBottom="0.5em" height={25}  display="flex" alignItems="center">
              <FormControl sx={{ mx: "0.125em", width: "4em" }}>
                <TextField
                  error={field.id === errorId}
                  id="filled-basic"
                  hiddenLabel
                  size="small"
                  fullWidth
                  inputProps={{ tabIndex: (collectionCount * 5000) + (3 + (6 * i)) }}
                  autoComplete="off"
                  sx={getFormInputStyles(columnOneFieldId, field, start, errorId)}
                  placeholder={!start ? "Start" : ""}
                  value={start}
                  disabled={templateIsPublished}
                  onChange={(e) => {
                    handleStartChange(e.target.value, field.id);
                  }}
                  onFocus={() => {
                    setErrorId("");
                    fieldSelected(field);
                  }}
                  onBlur={(e) => {
                    updateRange({ range_value: range, start_value: start,
                       end_value: end, step_value: step }, field.id);
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (e.key === "Enter") {
                      (e.target as HTMLImageElement).blur();
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                      handleArrowKeyEvent({ e });
                    }
                  }}
                />
              </FormControl>
              <FormControl sx={{ mx: "0.125em", width: "4em" }}>
                <TextField
                  error={field.id === errorId}
                  id="filled-basic"
                  hiddenLabel
                  size="medium"
                  fullWidth
                  inputProps={{ tabIndex: (collectionCount * 5000) + (4 + (6 * i)) }}
                  autoComplete="off"
                  sx={getFormInputStyles(columnOneFieldId, field, end, errorId)}
                  placeholder={!end ? "End" : ""}
                  value={end}
                  disabled={templateIsPublished}
                  onChange={(e) => {
                    handleEndChange(e.target.value, field.id);
                  }}
                  onFocus={() => {
                    setErrorId("");
                    fieldSelected(field);
                  }}
                  onBlur={(e) => {
                    updateRange({ range_value: range, start_value: start,
                      end_value: end, step_value: step }, field.id);
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (e.key === "Enter") {
                      (e.target as HTMLImageElement).blur();
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) => {
                    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                      handleArrowKeyEvent({ e });
                    }
                  }}
                />
              </FormControl>
              <FormControl sx={{ mx: "0.125em", width: "4em" }}>
                <TextField
                    error={field.id === errorId}
                    id="filled-basic"
                    hiddenLabel
                    size="small"
                    fullWidth
                    inputProps={{ tabIndex: (collectionCount * 5000) + (5 + (6 * i)) }}
                    autoComplete="off"
                    sx={getFormInputStyles(columnOneFieldId, field, step, errorId)}
                    placeholder={!step ? "Step" : ""}
                    value={step}
                    disabled={templateIsPublished}
                    onChange={(e) => {
                      handleStepChange(e.target.value, field.id);
                    }}
                    onFocus={() => {
                      setErrorId("");
                      fieldSelected(field);
                    }}
                    onBlur={(e) => {
                      updateRange({ range_value: range, start_value: start,
                        end_value: end, step_value: step }, field.id);
                    }}
                    onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                      if (e.key === "Enter") {
                        (e.target as HTMLImageElement).blur();
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) => {
                      if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                        handleArrowKeyEvent({ e });
                      }
                    }}
                  />
              </FormControl>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default ColumnThree;
