import React, { useEffect, useState, useCallback } from "react";
import { fetchFieldCollections, createFieldCollection, useVariablesForTemplate } from "api/template";
import Iteration from "./iteration";

const IterationTwo = ({ templateId, templateIsPublished }) => {
  const [iterationId, setIterationId] = useState("");
  const templateVariables = useVariablesForTemplate(templateId, { type: "standard" });

  const createIteration = useCallback(async () => {
    const data = {
      name: "Iteration 2",
      ordinal: 2,
      clonable: false,
      multipliable: false,
      type: "iteration",
    };
    const fieldCollection = await createFieldCollection(templateId, data);
    setIterationId(fieldCollection.id);
  }, [templateId]);

  const getIteration = useCallback(
    async (templateId) => {
      const data = await fetchFieldCollections(templateId);
      const iteration = data.results.find((item) => item.type === "iteration" && item.ordinal === 2);
      if (iteration) {
        setIterationId(iteration.id);
      } else {
        createIteration();
      }
    },
    [createIteration]
  );

  useEffect(() => {
    if (templateId) {
      getIteration(templateId);
    }
  }, [getIteration, templateId]);

  return (
    <Iteration
      templateId={templateId}
      templateIsPublished={templateIsPublished}
      iterationId={iterationId}
      sequenceId={2}
      selectOptions={templateVariables}
    />
  );
};

export default IterationTwo;
