import axios from "axios";
import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SearchBar from "../shared/SearchBar";
import ContactsTable from "./ContactsTable";

const Contacts = () => {
  const [cookies] = useCookies();
  const [contacts, setContacts] = useState([]);

  const [orderByProperty, setOrderByProperty] = useState("first_name");
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0); // total amount of contacts in set

  const navigate = useNavigate();

  // Fetch contacts
  const fetchContacts = async (searchString = "", _orderByProperty = undefined, _limit = undefined, _offset = 0) => {
    // Hardcoded token and Dev URL to be removed, its for testing purpose only
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${cookies.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/api/contacts?quick_search=${searchString}`;
    if (_orderByProperty) {
      url += `&order_by=${_orderByProperty}`;
    }
    if (_limit) {
      _offset = _offset * limit;
      url += `&limit=${_limit}&offset=${_offset}`;
    }
    const res = await fetch(url, requestOptions);
    const data = await res.json();
    return data;
  };

  const getContacts = async (_orderByProperty = undefined, _limit = undefined, _offset = undefined) => {
    const args: any = [""];

    args.push(_orderByProperty ?? orderByProperty);
    args.push(_limit ?? limit);
    args.push(_offset ?? offset);

    const data = await fetchContacts(...args);
    setContacts(data.results);
    setTotalResults(data.total_results);
  };

  // Create contact
  const _createContact = async (payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
    };
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/v1/api/contacts`, payload, config);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createContact = async (payload) => {
    const contact = await _createContact(payload);
    getContacts();
    return contact;
  };

  const handleShow = async () => {
    const payload = {
      first_name: "First_Name",
      last_name: "Last_Name",
    };
    const contact = await createContact(payload);
    navigate(`/contacts/${contact.id}`, { state: { contact } });
  };

  const searchContacts = async (searchString) => {
    const data = await fetchContacts(searchString);
    setContacts(data.results);
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <>
      <SearchBar delegateSearch={searchContacts} handleShow={handleShow} typography={"Contacts"} />
      <ContactsTable
        contacts={contacts}
        getContacts={getContacts}
        orderByProperty={orderByProperty}
        setOrderByProperty={setOrderByProperty}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        totalResults={totalResults}
      />
    </>
  );
};

export default Contacts;
