import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "utils/MuiWrapper/components";

export const ConfirmDialog = ({ showConfirmDialog, handleConfirmDialogClose, title, handleConfirmDelete, text }) => {
  return (
    <Dialog open={showConfirmDialog} onClose={handleConfirmDialogClose}>
      <DialogContent dividers>
        <DialogTitle>{title}</DialogTitle>
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmDialogClose}>Cancel</Button>
        <Button color="error" onClick={handleConfirmDelete}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
