import { useQuery } from "@tanstack/react-query";
import { getRequest, deleteRequest, createRequest, updateRequest } from "http/axiosClient";
import { QueryParamsType } from "types/Queries";

/**
 * Returns BOM items
 * @param {uuid} bomId
 * @param {number} offset
 * @returns see https://api.ft-test1.tfclabs.com/v1/api/docs/#tag/BomItems/operation/get_get_bom_items
 */
export const useBOMItems = (bomId, limit, offset) => {
  return useQuery({
    queryKey: ["bom-items", bomId, limit, offset],
    queryFn: () => fetchBOMItemsById(bomId, limit, offset),
  });
};

// Fetch BOM items by ID
export async function fetchBOMItemsById(bomId, limit, offset) {
  try {
    const res = await getRequest(`/v1/api/bom/${bomId}/item`, {
      limit,
      offset,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error(`BOM items could not be fetched.`));
  }
}

// Save BOM
export async function createBOMById(
  project_id: string | undefined,
  project_template_id: string | undefined,
  name: string
) {
  try {
    const res = await createRequest(`/v1/api/bom`, {
      project_template_id,
      project_id,
      name,
    });
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`The BOM could not be saved. ${error?.response?.data?.detail || ""}`));
  }
}

// Delete BOM
export async function deleteBOMById(bomId) {
  try {
    const res = await deleteRequest(`/v1/api/bom/${bomId}`);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(
      new Error(`The BOM could not be deleted. ${error?.response?.data?.detail || error?.response?.data || ""}`)
    );
  }
}

export type BomItemType = { bomId: string; bomItemId: string; body: object };

// Update BOM item
export async function updateBOMItemById(data: BomItemType) {
  try {
    const res = await updateRequest(`/v1/api/bom/${data.bomId}/item/${data.bomItemId}`, data.body);
    return res.data;
  } catch (error: any) {
    console.error(error);
    const errorMessage = error?.response?.data?.errors?.join(" ");
    return Promise.reject(
      new Error(`The BOM item could not be updated. ${errorMessage || error?.response?.data?.error || ""}`)
    );
  }
}

// Fetch bom by project id
export async function fetchBomByProjectId(projectId) {
  try {
    const res = await getRequest(`/v1/api/bom/project/${projectId}?output=ui`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(new Error(`Could not get BOM data. ${error?.response?.data?.detail || ""}`));
  }
}

export type ProjectBomType = { projectId: string; body: object };

// Update BOM item
export async function createProjectBom(data: ProjectBomType) {
  try {
    const res = await updateRequest(`/v1/api/bom/project/${data.projectId}`, data.body);
    return res.data;
  } catch (error: any) {
    console.log(error);
    const errorMessage = error?.response?.data?.errors?.join(" ");
    return Promise.reject(new Error(`The Project BOM could not be created. ${errorMessage || ""}`));
  }
}

export type BomByProjectIDType = {
  saved_boms: { results: BomUIType[]; total_results: number };
  cached_child_boms: { results: BomUIType[]; total_results: number };
};

export type BomUIType = {
  id: string;
  is_parent: boolean;
  parent_bom: string;
  project_id: string;
  project_template_id: string;
  name: string;
  status: string;
  children: BomUIType[];
  updated_at: string;
  version: string;
  last_updated: string;
  type: string;
};

export const useBOMProject = (projectId, enabled = true) => {
  return useQuery({
    queryKey: ["bom-project", projectId],
    queryFn: async () => {
      try {
        const res = await getRequest(`/v1/api/bom/project/${projectId}?output=ui`);
        return res.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not fetch BOMs for project."));
      }
    },
    retry: false,
    enabled: enabled,
  });
};

export type AddBOMItemPayloadType = {
  componentId: string;
  quantity: number | "";
};

export async function addBOMItemById(bomId: string, payload: AddBOMItemPayloadType) {
  try {
    const res = await createRequest(`/v1/api/bom/${bomId}/item`, {
      component_id: payload.componentId,
      qty: payload.quantity,
    });
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`The component could not be added to the BOM.`));
  }
}

export type UpdateBomItemsByIdType = {
  bom_item_ids: string[];
  values: {
    status?: string;
    qty?: number;
    quote_ref?: string;
    date_required?: string;
    rfp_number?: string;
    po_date?: string;
    location?: string;
    comments?: string;
  };
};

export async function updateBOMItemsById(bomId: string, payload: UpdateBomItemsByIdType) {
  try {
    const res = await updateRequest(`/v1/api/bom/${bomId}/item`, payload);
    return res.data;
  } catch (error: any) {
    console.error(error?.response?.data?.error);
    return Promise.reject(new Error(error?.response?.data?.error || "Could not update BOM items."));
  }
}

// Approve BOM for project
export async function approveBOMbyId(bomId: string, projectId) {
  try {
    const res = await updateRequest(`/v1/api/bom/${bomId}/project/${projectId}/approve`, {});
    return res.data;
  } catch (error: any) {
    console.error(error);
    return Promise.reject(new Error(error?.response?.data?.message || "Could not approve BOM for project."));
  }
}

export const getBOMLogs = (bomId, bomItemId, enabled = true) => {
  return useQuery({
    queryKey: ["bom-logs", bomItemId],
    queryFn: async () => {
      try {
        if (bomId == "" || bomItemId == "") {
          return [];
        }
        const res = await getRequest(`/v1/api/bom/${bomId}/item/${bomItemId}/log`);

        return res.data.results;
      } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not fetch logs for BOM item."));
      }
    },
    retry: false,
    enabled: enabled,
  });
};

export async function fetchPONumbers(bomPONumber: string) {
  try {
    const res = await getRequest(`/v1/api/bom/items/po/${bomPONumber}`);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`Could not get BOM PO numbers. ${error?.response?.data?.detail || ""}`));
  }
}

export async function bulkPatchReceivingBomItems(data) {
  try {
    const res = await updateRequest(`/v1/api/bom/item`, data);
    return res.data;
  } catch (error: any) {
    console.log(error);
    return Promise.reject(new Error(`Could not update BOM Items. ${error?.response?.data?.detail || ""}`));
  }
}