import axios from "axios";
import { getBearerToken } from "./client";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 2000,
});

client.interceptors.request.use(function (config) {
  if (config.headers) config.headers.Authorization = getBearerToken() || "";
  return config;
});

export const handleTokenError = (error) => {
  if (error.response.status === 401) {
    document.cookie = "token=";
    if (error.request.responseURL !== "https://api.ft-test1.tfclabs.com/v1/api/login_check") {
      window.location.href = "/";
    }
  }
  return Promise.reject(error);
};

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return handleTokenError(error);
  }
);

export function getRequest(url, queryParams = {}) {
  return client.get(url, {
    params: queryParams,
  });
}

export function createRequest(url, data) {
  return client.post(url, data);
}

export function updateRequest(url, data) {
  return client.patch(url, data);
}

export function deleteRequest(url) {
  return client.delete(url);
}
