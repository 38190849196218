import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { cloneProject } from "api/template";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";

interface cloneProjectProps {
  projectId: string;
  projectName: string;
  isCloneModalOpen: boolean;
  setCloneModal: (state: boolean) => void;
  refreshTable: () => void;
}

export function CloneProjectModal(props: cloneProjectProps) {
  const { isCloneModalOpen, setCloneModal, projectId, projectName } = props;

  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [name, setName] = React.useState("");

  const toggleModal = () => {
    setCloneModal(!isCloneModalOpen);
  };

  const { mutate: clone } = useMutation({
    mutationFn: () => cloneProject(projectId, name),
    onSuccess: () => {
      toggleModal();
      props.refreshTable();
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  return (
    <Box>
      <Dialog open={isCloneModalOpen}>
        <DialogContent dividers>
          <DialogTitle marginRight="10px">Enter new name to clone project "{projectName}":</DialogTitle>
          <IconButton
            onClick={() => {
              toggleModal();
            }}
          >
            <Close />
          </IconButton>
        </DialogContent>
        <DialogContent>
          <Stack direction="column" spacing={4}>
            <FormControl
              sx={{
                width: "100%",
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              ></TextField>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              clone();
            }}
            sx={{
              marginLeft: "auto",
            }}
            color="primary"
            disabled={name.length > 0 ? false : true}
          >
            Clone Project
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
