import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Menu } from "utils/MuiWrapper/components";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";
import { useQueryClient } from "@tanstack/react-query";

function getAvatarInitials(name: string) {
  if (name == null) return "";
  return name[0] + (name.indexOf(" ") > 0 ? name[name.indexOf(" ") + 1] : "");
}

const UserAvatar = () => {
  const queryClient = useQueryClient();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const { userId, setAccessToken } = useAuthStore();
  const { data: user } = useGetUser(userId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeCookie("token", {path: "/"});
    setAccessToken(undefined);
    queryClient.invalidateQueries({ queryKey: ["get_user"] });

    // Will redirect to auth page but also reset application state if user is changing
    window.location.reload();
  };

  return (
    <Stack direction="row" sx={{ position: "absolute", bottom: 20, left: 40 }}>
      <Avatar sx={{cursor: "pointer"}} onClick={handleClick}>{getAvatarInitials(user?.name ?? "")}</Avatar>
      <FormControl sx={{ ml: "13px" }} size="small" fullWidth>
        <InputLabel sx={{ marginTop: 3 }}>{user?.name ?? ""}</InputLabel>
      </FormControl>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setShowConfirmLogout(true)}>Logout</MenuItem>
      </Menu>
      <Dialog
        open={showConfirmLogout}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout of Northstar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmLogout(false)}>Cancel</Button>
          <Button onClick={handleLogout} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default UserAvatar;
