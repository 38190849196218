import React, { useState, useCallback } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  CssBaseline,
} from "utils/MuiWrapper/components";
import { useCookies } from "react-cookie";
import { createRequest } from "http/axiosClient";
import logo from "../assets/images/northstar-logo.svg";
import { useAlertSnackbarState } from "./AlertSnackbar/AlertSnackbar";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { decodeToken } from "utils/helpers";
import { getUser } from "api/auth";
import { useQueryClient } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";

const LOGIN_ENDPOINT = "/v1/api/login_check";

export const SignInPage = ({ authorize }) => {
  const queryClient = useQueryClient();
  const { setAccessToken } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cookies, setCookie] = useCookies();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const handleSubmit = useCallback(
    async (event) => {
      if (password === "") {
        setPasswordError("Required");
        setAlert({type: "error", message: passwordError});
        return;
      }

      setIsLoading(true);

      try {
        const response = await createRequest(LOGIN_ENDPOINT, {
          username: username,
          password: password,
        });

        const token = response?.data?.token;

        if (token) {
          setAccessToken(token);
          //NOTE: must set token before authorize so other components can access
          setCookie("token", response?.data?.token, {path: "/"});

          const decodedToken = decodeToken(token);
          const user = await getUser(decodedToken?.user_id ?? "");
          queryClient.setQueryData(["get_user"], user);

          authorize();
        }
      } catch (error: any) {
        setPasswordError(error?.response?.data?.detail || "Invalid password");
        setAlert({type: "error", message: passwordError});
      } finally {
        setIsLoading(false);
      }

      event.preventDefault();
    },
    [authorize, password, username]
  );

  return (
    <Container component="main" maxWidth="xs" sx={{height: "100%", backgroundColor: "#15192e", borderRadius: "16px"}}>
      <CssBaseline />
      <Box
      padding={4}
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", paddingTop: 2}}>
          <img src={logo} alt="logo"></img>
        </Box>
        {isLoading ?
          <CircularProgress /> :
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
            />
            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit}>
              Sign In
            </Button>
          </Box>
        }
      </Box>
    </Container>
  );
};
