import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { useMutation } from "@tanstack/react-query";

import { ComponentPartList } from "./ComponentPartList";
import { useGetComponents } from "api/component";
import { AddBOMItemPayloadType, BomItemType, addBOMItemById, updateBOMItemById } from "api/bom";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { QueryParamsType } from "types/Queries";
import CommentConfirmation from "components/BOMView/CommentConfirmation";
import { BOM_COMMENT_REQUIRED } from "utils/constants";

type AddComponentTypes = {
  action: "add" | "swap";
  itemId?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemStatus?: string;
};

export const AddComponent = ({ action, itemId, isOpen, setIsOpen, itemStatus }: AddComponentTypes) => {
  const { bomId, projectId, projectTemplateId, numComponentsAdded } = useParams();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState<number | "">(1);
  const [componentData, setComponentData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [options, setOptions] = useState({});
  const handleClose = () => {
    setQuantity(1);
    setIsOpen(false);
  };
  const [params, setParams] = useState<QueryParamsType>({ limit: 10, offset: 0 });
  const { data: components } = useGetComponents(params);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [commentOpen, setCommentOpen] = useState(false);
  const [stashedParams, setStashedParams] = useState<any>();

  useEffect(() => {
    // reset search and pagination
    setParams({ limit: 10, offset: 0 });
  }, [isOpen]);

  useEffect(() => {
    if (components?.results) {
      setComponentData(
        components?.results?.map((component) => {
          return {
            id: component.id,
            partNumber: component.part_number,
            partDescription: component.part_description,
            manufacturer: component.manufacturer.name,
            price: component.price,
            discount: component.discount,
            acConnections: component.ac_connections,
            powerUsage: component.power_usage,
            rackUnits: component.rack_units,
          };
        })
      );

      setOptions({
        search: true,
        searchText: params.quick_search,
        searchProps: {
          autoComplete: "off",
        },
        download: false,
        print: false,
        filter: false,
        selectableRows: "none",
        onRowClick: (rowData) => {
          if (typeof quantity === "number" && quantity < 0) {
            setAlert({
              type: "warning",
              message: "Please enter a positive number for quantity.",
            });
          } else if (quantity === "") {
            setAlert({
              type: "warning",
              message: "Please enter a number for quantity.",
            });
          } else {
            if (action === "add") {
              addItem({ componentId: rowData[0], quantity });
            } else if (action === "swap") {
              if (itemStatus && BOM_COMMENT_REQUIRED.includes(itemStatus)) {
                setStashedParams({
                  bomId: bomId || "",
                  bomItemId: itemId || "",
                  body: { component_id: rowData[0], qty: quantity },
                });
                setCommentOpen(true);
              } else {
                updateBOMItem({
                  bomId: bomId || "",
                  bomItemId: itemId || "",
                  body: { component_id: rowData[0], qty: quantity },
                });
              }
            }
            handleClose();
          }
        },
        serverSide: true,
        count: components?.total_results,
        pagination: true,
        searchAlwaysOpen: true,
        viewColumns: false,
        onTableChange: debouncedOnTableChange,
        rowsPerPage,
        rowsPerPageOptions: [10, 50, 100],
        onChangeRowsPerPage: (tableState) => setRowsPerPage(tableState.rowsPerPage),
      });
    }
  }, [components, quantity]);

  const updateNumComponents = useCallback(() => {
    const numComponents = !numComponentsAdded ? 0 : parseInt(numComponentsAdded);
    navigate(`/bom-view/${bomId}/project/${projectId}/added/${numComponents + 1}`);
  }, [numComponentsAdded, bomId, projectId, projectTemplateId]);

  const { mutate: addItem } = useMutation({
    mutationFn: (payload: AddBOMItemPayloadType) => {
      return addBOMItemById(bomId || "", payload);
    },
    onSuccess: () => {
      updateNumComponents();
      setAlert({
        type: "success",
        message: "Component has been added to the BOM.",
      });
    },
    onError: (error: Error) => {
      console.error(error);
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const { mutate: updateBOMItem } = useMutation({
    mutationFn: (data: BomItemType) => updateBOMItemById(data),
    onSuccess: () => {
      updateNumComponents();
      setAlert({
        type: "success",
        message: "Component has been updated.",
      });
      setStashedParams(undefined);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const debouncedOnTableChange = debounce((action, tableState) => {
    if (action === "search" || action === "changePage" || action === "changeRowsPerPage") {
      const newParams: QueryParamsType = {
        limit: tableState.rowsPerPage,
        offset: tableState.page * tableState.rowsPerPage,
      };

      if (tableState.activeColumn) {
        newParams.order_by = tableState.sortOrder.name;
        newParams.order_direction = tableState.sortOrder.direction;
      }
      if (tableState.searchText) {
        newParams.quick_search = tableState.searchText;
      }
      setParams(newParams);
    }
  }, 200);

  const handleCommentClose = (comment?: string) => {
    setCommentOpen(false);
    if (comment && comment.length > 0 && stashedParams && itemId) {
      const body = stashedParams.body;
      body["comments"] = comment;
      updateBOMItem(stashedParams);
    } else if (stashedParams) {
      setAlert({
        type: "error",
        message: `The BOM item could not be updated. A comment must be provided for these changes.`,
      });
      setStashedParams(undefined);
    }
  };

  return (
    <>
      {componentData && (
        <ComponentPartList
          isOpen={isOpen}
          handleClose={handleClose}
          tableData={{ data: componentData, options }}
          showQuantity={true}
          quantity={quantity}
          setQuantity={setQuantity}
        />
      )}

      <CommentConfirmation id="comment-dialog" keepMounted open={commentOpen} onClose={handleCommentClose} />
    </>
  );
};
