import { Box, Divider, useTheme } from "utils/MuiWrapper/components";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useCallback, useEffect, useState } from "react";
import RuleSet from "./RuleSet";
import { ACTIVE_BORDER } from "utils/constants";

const RuleSets = ({
  templateId,
  templateIsPublished,
  fieldCollectionId,
  columnOneFieldId,
  columnTwoFieldId,
  rules,
  columnOneSelected,
  columnTwoSelected,
  sequenceId,
}) => {
  const [ruleSets, setRuleSets] = useState([]);
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    createAndSetRuleSets(rules);
  }, [columnOneFieldId, columnTwoFieldId, rules, checked]);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  const addRuleActionValue = (rule) => {
    rule.rule_action_value = "";
    if (rule.rule_actions.length > 0) {
      rule.rule_action_value = rule.rule_actions[0].value;
    }
    return rule;
  };

  ruleSets.map((ruleSet: object, i : number) => {
    return ruleSet['ruleSetNumber'] = i + 1
  })

  const createAndSetRuleSets = (rules) => {
    const ruleSets: any = [];
    const visitedColumnOneIds: any = [];
    const visitedColumnTwoIds: any = [];
    rules.forEach((rule) => {
      const columnOne = rule.template_section_field_options.col1;
      const columnTwo = rule.template_section_field_options.col2;
      const variableId = rule.template_section_field_options.variable_id;
      const ruleSet: any = {
        rules: [],
      };
      if (!visitedColumnOneIds.includes(columnOne) || !visitedColumnTwoIds.includes(columnTwo)) {
        ruleSet.columnOne = columnOne;
        ruleSet.columnTwo = columnTwo;
        ruleSet.variableId = variableId;
        rules.forEach((rule) => {
          const currentCol1 = rule.template_section_field_options.col1;
          const currentCol2 = rule.template_section_field_options.col2;
          if (currentCol1 == columnOne && currentCol2 == columnTwo) {
            const _rule = addRuleActionValue(rule);
            ruleSet.rules = [...ruleSet.rules, _rule];
          }
        });
        ruleSets.push(ruleSet);
        visitedColumnOneIds.push(columnOne);
        visitedColumnTwoIds.push(columnTwo);
      }
    });
    setRuleSets(ruleSets);
  };

  const ruleSetSelected = (columnOne, columnTwo) => {
    if (templateIsPublished) return;
    columnOneSelected(columnOne, true);
    columnTwoSelected(columnTwo, true);
  };

  const filteredRuleSets = useCallback(() => {
    return !checked
      ? ruleSets
      : ruleSets
          ?.filter((ruleSet: { columnOne: string }) => ruleSet.columnOne === columnOneFieldId)
          ?.filter((ruleSet: { columnTwo: string }) => ruleSet.columnTwo === columnTwoFieldId);
  }, [checked, ruleSets, columnOneFieldId, columnTwoFieldId]);

  return (
    <Box p={1} sx={{ backgroundColor: theme.palette.grey["600"] }}>
      <Box display="flex" justifyContent="center">
        Rules
      </Box>
      <Box alignItems="center" sx={{ display: "flex", justifyContent: "center" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch size="small" checked={checked} onChange={switchHandler} style={{ color: ACTIVE_BORDER }} />
            }
            label="Show Active Only"
          />
        </FormGroup>
      </Box>
      <Box>
        <Divider />
      </Box>
      {filteredRuleSets()?.map((ruleSet: { columnOne: string; columnTwo: string; variableId: string, ruleSetNumber: number }, i: number) => {
        return (
          <Box
            key={i}
            onClick={() => {
              ruleSetSelected({ id: ruleSet.columnOne, variable_id: ruleSet.variableId }, ruleSet.columnTwo);
            }}
          >
            <RuleSet
              key={i}
              ruleSet={ruleSet}
              templateId={templateId}
              templateIsPublished={templateIsPublished}
              fieldCollectionId={fieldCollectionId}
              columnOneFieldId={columnOneFieldId}
              columnTwoFieldId={columnTwoFieldId}
              addRuleActionValue={addRuleActionValue}
              count={ruleSet?.ruleSetNumber}
              sequenceId={sequenceId}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default RuleSets;
