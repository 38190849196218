import { useProject } from "api/project";
import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { sortBy } from "lodash";
import { formatDistance } from "date-fns";
import { Box, Stack, Typography, Link, Button, Card, CardContent } from "utils/MuiWrapper/components";
import { useAssignTemplateToProjectModalState, useSelectTemplateModalState } from "components/shared/AssignTemplateToProjectModal";
import { TemplateSectionMenuOptions } from "./TemplateSectionMenuOptions";
import { CARD_BG_COLOR } from "utils/constants";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { Action, Roles } from "utils/enums";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";

export const Templates = ({ projectId }) => {
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const { data: project, isSuccess, isLoading, refetch } = useProject(projectId);
  const { isProjectManager, isProjectMember } = useProjectTeamManagement(project, userId);

  const projectTemplates = useMemo(() => {
    if (!project || !project.project_templates) {
      return undefined;
    }

    // TODO: remove this once the server implements sorting
    return sortBy(project.project_templates, (x) => x.last_modified);
  }, [project]);
  const openModal = useAssignTemplateToProjectModalState((state) => state.open);
  const setType = useSelectTemplateModalState((state) => state.setTemplateType);
  const openTemplateModal = (templateType: string) => {
    setType(templateType);
    openModal();
  };
  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess) {
    return <Typography>Error</Typography>;
  }

  const canViewTemplate = (isProjectManager || isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.template_user, Action.View);

  return (
    <Card
      variant="elevation"
      sx={{ height: "38.5vh", overflow: "auto", borderRadius: "16px", backgroundColor: CARD_BG_COLOR }}
    >
      <CardContent>
        <Stack direction="column">
          <Typography textAlign={"center"} variant="h1" gutterBottom>
            Templates
          </Typography>
          {projectTemplates?.map((template) => {
            return (
              <Box key={`box-${template.id}`} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {canViewTemplate ? <Link
                  py={1}
                  to={`/user-view/project/${projectId}/template/${template.template_id}/project-template/${template.id}`}
                  component={RouterLink}
                  variant="body2"
                  key={`link-${template.id}`}
                  sx={{ justifyContent: "center", minWidth: "90%" }}
                >
                  <Stack
                    key={`stack1-${template.id}`}
                    p={3}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ backgroundColor: "#13172c;", borderRadius: "16px 0 0 16px" }}
                  >
                    <Typography key={`typ-${template.id}`}>{template.name}</Typography>
                    <Typography key={`typ2-${template.id}`}>
                      Last updated:{" "}
                      {formatDistance(Date.parse(template.last_modified), new Date(), { addSuffix: true })}
                    </Typography>
                  </Stack>
                </Link> :
                <Stack
                  key={`stack1-${template.id}`}
                  p={3}
                  direction="row"
                  justifyContent="space-between"
                  sx={{ backgroundColor: "#13172c;", borderRadius: "16px 0 0 16px", minWidth: "90%" }}
                >
                  <Typography key={`typ-${template.id}`}>{template.name}</Typography>
                  <Typography key={`typ2-${template.id}`}>
                    Last updated:{" "}
                    {formatDistance(Date.parse(template.last_modified), new Date(), { addSuffix: true })}
                  </Typography>
                </Stack>
                }
                <Stack key={`stack2-${template.id}`} py={1}>
                  <TemplateSectionMenuOptions
                    projectTemplate={template}
                    parentProject={project}
                    refetchProjectQuery={refetch}
                  />
                </Stack>
              </Box>
            );
          })}
          {(isProjectMember || user?._embedded.role.name === Roles.Architect) && hasPermission(user?.permissions?.template_user, Action.Create) && (
            <Stack pt={2} pl={9} direction="row" spacing={3}>
              <Button onClick={() => openTemplateModal("standard")}>Add New</Button>
              <Button onClick={() => openTemplateModal("dc")}>Add New Data Center</Button>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
