import { useQuery } from "@tanstack/react-query";
import { getRequest } from "http/axiosClient";
import { QueryParamsType } from "types/Queries";
import { User } from "types/User";

export async function getUser(id: string) {
  try {
    const res = await getRequest("/v1/api/users/" + id);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error(`Could not fetch user details.`));
  }
}

export const useGetUser = (id?: string) => {
  return useQuery<User>(
    ["get_user", id],
    () => getUser(id ?? ""),
    {
      enabled: !!id
    }
  );
};

export async function getUsers(params?: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/users`, params);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error(`Could not fetch users.`));
  }
}

export const useGetUsers = (params?: QueryParamsType) => {
  return useQuery({
    queryKey: ["get_users"],
    queryFn: () => getUsers(params),
    retry: false,
  });
};
