import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { DesktopDatePicker } from "utils/MuiWrapper/datepicker";
import { TextField } from "utils/MuiWrapper/components";
import { useImperativeHandle } from "react";
import { formatISO } from "date-fns";

export const DateEditor = memo(
  forwardRef((props: any, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef<any>(null);

    useEffect(() => {
      refInput?.current?.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return typeof value === "object" ? formatISO(value) : value;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        // isCancelAfterEnd() {
        //   return value;
        // },
      };
    });

    const handleChange = (value) => {
      setValue(value);
    };

    return (
      <DesktopDatePicker
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            sx={{
              display: "block",
            }}
            name="date_required"
            fullWidth
            {...params}
          />
        )}
      />
    );
  })
);
