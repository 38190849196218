import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import React, { useState, useEffect } from "react";
import Rule from "./Rule";
import debounce from "lodash.debounce";
import { createRule, createRuleAction, createRuleConditions, updateRuleAction } from "api/rule";
import { RuleVariableSelect } from "components/TemplateBuilder/Iterations/Rules/RuleVariableSelect";
import { ComponentPartList, ComponentTableData } from "../../../ComponentPartList/ComponentPartList";
import { getComponents } from "api/component";
import { useQueryClient } from "@tanstack/react-query";
import { QueryParamsType } from "types/Queries";
import { ACTIVE_BORDER } from "utils/constants";

const RuleSet = ({
  ruleSet,
  templateId,
  templateIsPublished,
  fieldCollectionId,
  columnOneFieldId,
  columnTwoFieldId,
  addRuleActionValue,
  count,
  sequenceId,
}) => {
  const queryClient = useQueryClient();
  const [ruleActionVariableId, setRuleActionVariableId] = useState(null);
  const [rules, setRules] = useState(ruleSet.rules);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [newRuleType, setNewRuleType] = useState("");
  const [component, setComponent] = useState("");
  const [componentId, setComponentId] = useState();
  const [isRuleSetActive, setIsRuleSetActive] = useState(false);
  const [componentsTableData, setComponentsTableData] = useState<ComponentTableData>({ options: {}, data: [] });
  const [disableDelete, setDisableDelete] = useState(false);
  const [numRowsPerPage, setNumRowsPerPage] = useState(10);

  useEffect(() => {
    if (
      columnOneFieldId &&
      columnTwoFieldId &&
      ruleSet.columnOne === columnOneFieldId &&
      ruleSet.columnTwo === columnTwoFieldId
    ) {
      setIsRuleSetActive(true);
    } else {
      setIsRuleSetActive(false);
    }
    setRules(ruleSet.rules);
  }, [ruleSet, columnOneFieldId, columnTwoFieldId]);

  useEffect(() => {
    if (rules.length <= 1) {
      setDisableDelete(true);
    } else {
      setDisableDelete(false);
    }
  }, [rules]);

  const parseAndSetComponentsTableData = (components, totalComponents = 0, onRowClick, rowsPerPage) => {
    const data = components.map((component) => {
      return {
        id: component.id,
        partNumber: component.part_number,
        partDescription: component.part_description,
        manufacturer: component.manufacturer.name,
        price: component.price,
        discount: component.discount,
        acConnections: component.ac_connections,
        powerUsage: component.power_usage,
        rackUnits: component.rack_units,
      };
    });

    const options = {
      search: true,
      searchProps: {
        autoComplete: "off",
      },
      download: false,
      print: false,
      filter: false,
      selectableRows: "none",
      onRowClick: onRowClick,
      serverSide: true,
      count: totalComponents,
      pagination: true,
      searchAlwaysOpen: true,
      viewColumns: false,
      onTableChange: debouncedOnTableChange,
      rowsPerPage: rowsPerPage || numRowsPerPage,
      rowsPerPageOptions: [10, 50, 100],
      onChangeRowsPerPage: (numRows: number) => setNumRowsPerPage(numRows),
    };

    setComponentsTableData((prev) => ({ ...prev, data, options }));
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  const handleRuleTypeChange = (e) => {
    setNewRuleType(e.target.value);
  };

  const handleCreateRule = async () => {
    const ruleData = {
      salience: 1,
      template_section_field_options: {
        col1: ruleSet.columnOne,
        col2: ruleSet.columnTwo,
        variable_id: ruleSet.variableId,
      },
      rule_type: newRuleType,
      template_section_id: fieldCollectionId,
      sequence_id: sequenceId,
    };
    const rule = await createRule(templateId, ruleData);
    const ruleConditionData = {
      operator: "and",
      rule_conditions: [
        {
          operator: "eq",
          template_section_field_option_id: ruleSet.columnOne,
          value: ruleSet.columnOne,
          variable_id: ruleSet.variableId,
        },
        {
          operator: "eq",
          template_section_field_option_id: ruleSet.columnTwo,
          value: ruleSet.columnTwo,
          variable_id: ruleSet.variableId,
        },
      ],
    };
    const ruleCondition = await createRuleConditions(rule.id, ruleConditionData);
    rule.rule_conditions.push(ruleCondition);
    if (newRuleType === "component") {
      const ruleActionData = {
        ordinal: 0,
        value: component,
        component_id: componentId,
      };
      const rule_action = await createRuleAction(rule.id, ruleActionData);
      rule.rule_actions.push(rule_action);
    } else if (newRuleType === "addition") {
      const ruleActionData = {
        ordinal: 0,
        value: component,
        variable_id: ruleActionVariableId,
      };
      const rule_action = await createRuleAction(rule.id, ruleActionData);
      rule.rule_actions.push(rule_action);
    }
    const _rule = addRuleActionValue(rule);
    setRules([...rules, _rule]);
    handleClose();
    setNewRuleType("");
    setComponent("");
    setRuleActionVariableId(null);
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const onRowClick = (rowData) => {
    setComponent(`${rowData[1]} - ${rowData[2]}`);
    setComponentId(rowData[0]);
    handleClose2();
  };

  const handleAddRule = async () => {
    await getComponents({ limit: numRowsPerPage }).then((res) => {
      parseAndSetComponentsTableData(res.results, res.total_results, onRowClick, null);
      handleShow();
    });
  };

  const debouncedOnTableChange = debounce((action, tableState) => {
    handleOnTableChange(action, tableState);
  }, 200);

  const handleOnTableChange = async (action, tableState) => {
    if (action === "search" || action === "changePage" || action === "changeRowsPerPage") {
      const params: QueryParamsType = {
        limit: tableState.rowsPerPage,
        offset: tableState.page * tableState.rowsPerPage,
      };
      if (tableState.activeColumn) {
        params.order_by = tableState.sortOrder.name;
        params.order_direction = tableState.sortOrder.direction;
      }

      if (tableState.searchText) {
        params.quick_search = tableState.searchText;
      }

      await getComponents(params).then((res) => {
        parseAndSetComponentsTableData(
          res.results,
          res.total_results,
          localStorage.getItem("componentAction") === "update"
            ? onUpdateComponentRowClick
            : localStorage.getItem("componentAction")
            ? onAddComponentRowClick
            : onRowClick,
          tableState?.rowsPerPage
        );
      });
    }
  };

  const onAddComponentRowClick = async (rowData) => {
    const rule = JSON.parse(localStorage.getItem("rule") || "");
    const ruleActionData = {
      ordinal: 0,
      value: `${rowData[1]} - ${rowData[2]}`,
      component_id: rowData[0],
    };
    const rule_action = await createRuleAction(rule.id, ruleActionData);
    rule.rule_actions.push(rule_action);
    const _rule = addRuleActionValue(rule);
    setRules(
      rules.map((r) => {
        if (r.id === _rule.id) {
          return _rule;
        }
        return r;
      })
    );
    localStorage.removeItem("rule");
    localStorage.removeItem("componentAction");
    handleClose2();
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const addRuleComponent = async (rule) => {
    localStorage.setItem("componentAction", "add");
    localStorage.setItem("rule", JSON.stringify(rule));
    await getComponents({ limit: numRowsPerPage }).then((res) => {
      parseAndSetComponentsTableData(res.results, res.total_results, onAddComponentRowClick, null);
      handleShow2();
    });
  };

  const onUpdateComponentRowClick = async (rowData) => {
    const rule = JSON.parse(localStorage.getItem("rule") || "");
    const ruleActionData = {
      ordinal: 0,
      value: `${rowData[1]} - ${rowData[2]}`,
      component_id: rowData[0],
    };
    if (rule) {
      const rule_action = await updateRuleAction(rule.id, rule.rule_actions[0].id, ruleActionData);
      rule.rule_actions[0] = rule_action;
      const _rule = addRuleActionValue(rule);
      setRules(
        rules.map((r) => {
          if (r.id === _rule.id) {
            return _rule;
          }
          return r;
        })
      );
    }
    localStorage.removeItem("rule");
    localStorage.removeItem("componentAction");
    handleClose2();
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const updateRuleComponent = async (rule) => {
    localStorage.setItem("componentAction", "update");
    localStorage.setItem("rule", JSON.stringify(rule));
    await getComponents({ limit: numRowsPerPage }).then((res) => {
      parseAndSetComponentsTableData(res.results, res.total_results, onUpdateComponentRowClick, null);
      handleShow2();
    });
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const removeRule = (ruleId) => {
    setRules(
      rules.filter((rule) => {
        return rule.id != ruleId;
      })
    );
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const editRule = (rule) => {
    setRules(
      rules.map((r) => {
        if (r.id === rule.id) {
          return rule;
        }
        return r;
      })
    );
  };

  return (
    <Box
      p={0.2}
      marginTop="3%"
      marginBottom="3%"
      sx={
        isRuleSetActive
          ? { border: `2px solid ${ACTIVE_BORDER}`, borderRadius: "5px" }
          : { border: "2px solid #555", borderRadius: "5px" }
      }
    >
      <Box display="flex" justifyContent="center">
        Rule Set {count}
      </Box>
      <Box>
        {rules?.map((rule, i) => {
          return (
            <Rule
              fieldCollectionId={fieldCollectionId}
              key={i}
              count={i}
              rule={rule}
              addRuleComponent={addRuleComponent}
              updateRuleComponent={updateRuleComponent}
              editRule={editRule}
              removeRule={removeRule}
              disableDelete={disableDelete}
              templateId={templateId}
              templateIsPublished={templateIsPublished}
            />
          );
        })}
      </Box>
      {isRuleSetActive ? (
        <Box display="flex" justifyContent="center">
          <Button disabled={templateIsPublished} variant="outlined" onClick={handleAddRule}>
            Add Rule
          </Button>
        </Box>
      ) : (
        <></>
      )}

      <Dialog open={show} onClose={handleClose} PaperProps={{ sx: { overflowY: "hidden" } }}>
        <DialogContent dividers sx={{ overflowY: "hidden" }}>
          <DialogTitle>Add Rule Details</DialogTitle>
          <IconButton disabled={templateIsPublished} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogContent>
        <DialogContent>
          <Stack direction="column" spacing={4}>
            <FormControl disabled={templateIsPublished}>
              <InputLabel id="rule-type-select-label">Rule Type</InputLabel>
              <Select
                labelId="rule-type-select-label"
                id="demo-simple-select"
                value={newRuleType}
                label="Rule Type"
                onChange={handleRuleTypeChange}
              >
                <MenuItem value={"component"}>Component</MenuItem>
                <MenuItem value={"addition"}>Addition</MenuItem>
              </Select>
            </FormControl>

            {newRuleType === "component" && (
              <Box>
                <Button disabled={templateIsPublished} variant="contained" onClick={handleShow2}>
                  Select Component
                </Button>
              </Box>
            )}
            {newRuleType === "component" && !!component && (
              <Box>
                <TextField
                  label="Component"
                  autoComplete="off"
                  value={component}
                  fullWidth={true}
                  disabled={templateIsPublished}
                />
              </Box>
            )}
            {newRuleType === "addition" && (
              <FormControl disabled={templateIsPublished}>
                <InputLabel id="rule-variable-select-label">Variable</InputLabel>
                <RuleVariableSelect
                  fieldCollectionId={fieldCollectionId}
                  value={ruleActionVariableId}
                  templateId={templateId}
                  onSelect={(event) => setRuleActionVariableId(event.target.value)}
                />
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={templateIsPublished} onClick={handleClose}>
            Close
          </Button>

          <Button
            disabled={
              templateIsPublished ||
              !newRuleType ||
              (newRuleType === "component" && !component) ||
              (newRuleType === "addition" && !ruleActionVariableId)
            }
            color="primary"
            onClick={handleCreateRule}
          >
            Create Rule
          </Button>
        </DialogActions>
      </Dialog>
      <ComponentPartList isOpen={show2} handleClose={handleClose2} tableData={componentsTableData} />
    </Box>
  );
};

export default RuleSet;
